import { post, get, put } from './api'
import {
    authEndpoints,
    subjectsEndpoints,
    countiresEndpoints,
    expEndpoints,
    hobbiesEndpoints,
    statesEndpoints,
    citiesEndpoints,
    gradesEndpoints,
    schoolsEndpoints,
    partnerEndpoints,
    mediaEndpoints,
    childEndpoints,
    collectionsEndpoints,
    requestCallbackEndpoints,
    notifyEndpoints,
    recommendationsEndpoints,
    experienceTypesEndpoints,
    destinationTypesEndpoints,
    schoolTripEndpoints,
    bannerEdnpoints
} from './endpoints'
import axios from 'axios'

// Auth API
export const authApi = {
    sendLoginOtp: async (payload) => {
        const response = await post(authEndpoints.sendLoginOtp, { mobile: payload })
        return response
    },

    verifyLoginOtp: async (payload) => {
        const response = await post(authEndpoints.verifyLoginOtp, payload)
        return response
    },

    sendSignupOtp: async (payload) => {
        const response = await post(authEndpoints.sendSignupOtp, { mobile: payload })
        return response
    },

    verifySignupOtp: async (payload) => {
        const response = await post(authEndpoints.verifySignupOtp, payload)
        return response
    },

    resendOtp: async (payload) => {
        const response = await post(authEndpoints.resendOtp, { mobile: payload })
        return response
    },

    getUser: async () => {
        const response = await get(authEndpoints.getUser)
        return response
    },

    bookingHistory: async () => {
        const response = await get(authEndpoints.bookingHistory)
        return response
    }

}

// Subjects API
export const subjectsApi = {
    getSubjects: async () => {
        const response = await get(subjectsEndpoints.getAllSubjects)
        return response
    },

    getSubject: async (payload) => {
        const response = await get(subjectsEndpoints.getSubject, payload)
        return response
    },

}

// countries 
export const countriesApi = {
    getCountries: async () => {
        const response = await get(countiresEndpoints.getAllCountries)
        return response
    },

    getCountry: async (payload) => {
        const response = await get(countiresEndpoints.getCountry, payload)
        return response
    },

}

// Experiences API
export const expApi = {
    getAllExperiences: async () => {
        const response = await get(expEndpoints.getAllExperiences)
        return response
    },

    getExperience: async (payload) => {
        const response = await get(`${expEndpoints.getExperience}${payload}`)
        return response
    },

    getFilteredExperiences: async (payload) => {
        const response = await get(`${expEndpoints.getFilteredExperiences}${payload}`)
        return response
    },

    getUpcomingExperiences: async () => {
        const response = await get(expEndpoints.getUpcomingExperiences)
        return response
    }

}

//hobbies API
export const hobbiesApi = {
    getAllHobbies: async () => {
        const response = await get(hobbiesEndpoints.getAllHobbies)
        return response
    },

    getHobby: async (payload) => {
        const response = await get(`${hobbiesEndpoints.getHobby}${payload}`)
        return response
    },

}

//states API
export const statesApi = {
    getAllStates: async () => {
        const response = await get(statesEndpoints.getAllStates)
        return response
    },

    getState: async (payload) => {
        const response = await get(`${statesEndpoints.getState}${payload}`)
        return response
    },

}

//cities API
export const citiesApi = {
    getAllCities: async () => {
        const response = await get(citiesEndpoints.getAllCities)
        return response
    },

    getCity: async (payload) => {
        const response = await get(`${citiesEndpoints.getCity}${payload}`)
        return response
    },

}

//grades API
export const gradesApi = {
    getAllGrades: async () => {
        const response = await get(gradesEndpoints.getAllGrades)
        return response
    },

    getGrade: async (payload) => {
        const response = await get(`${gradesEndpoints.getGrade}${payload}`)
        return response
    },

}

// Schools API
export const schoolsApi = {
    getAllSchools: async () => {
        const response = await get(schoolsEndpoints.getAllSchools)
        return response
    },

    getSchool: async (payload) => {
        const response = await get(`${schoolsEndpoints.getSchool}${payload}`)
        return response
    },

}

// Partners API
export const partnersApi = {
    getAllPartners: async () => {
        const response = await get(partnerEndpoints.getAllPartners)
        return response
    },

    getPartner: async (payload) => {
        const response = await get(`${partnerEndpoints.getPartner}${payload}`)
        return response
    },

}

//Media API
export const mediaApi = {
    uploadImage: async (payload) => {
        const response = await axios.post(mediaEndpoints.uploadMedia, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
        })
        return response
    }
}

// Tour API
export const tourApi = {
    getAllTours: async (payload) => {
        const response = await get(`${expEndpoints.getExperience}${payload}/tour`)
        return response
    },

    getTour: async (payload) => {
        const response = await get(`${expEndpoints.getExperience}${payload.expId}/${payload.tourId}`)
        return response
    },

}

// Payment Api 
export const paymentApi = {
    createPayment: async (payload) => {
        const response = await post(`${expEndpoints.getExperience}payment`, payload)
        return response
    },

    getPaymentStatus: async (payload) => {
        const response = await get(`${expEndpoints.getExperience}payment/${payload}`)
        return response
    },
}


// Child API
export const childApi = {
    getChild: async (payload) => {
        const response = await get(`${childEndpoints.getChild}${payload.id}`)
        return response
    },

    createChild: async (payload) => {
        const response = await post(`${childEndpoints.createChild}`, payload)
        return response
    },

    updateChild: async (payload) => {
        const response = await put(`${childEndpoints.updateChild}`, payload)
        return response
    }
}

// Collections API
export const collectionsApi = {
    getAllCollections: async () => {
        const response = await get(collectionsEndpoints.getAllCollections)
        return response
    },

    getCollection: async (payload) => {
        const response = await get(`${collectionsEndpoints.getCollection}${payload}`)
        return response
    },

}

// Request Callback API
export const requestCallbackApi = {
    createRequest: async (payload) => {
        const response = await post(`${requestCallbackEndpoints.requestCallback}`, payload)
        return response
    }
}

// Request Notify API
export const notifyApi = {
    notifyMe: async (payload) => {
        const response = await post(`${notifyEndpoints.notifyMe}`, payload)
        return response
    }
}

// Recommendation API
export const recommendationsApi = {
    getRecommendations: async (payload) => {
        const response = await get(`${recommendationsEndpoints.getRecommendations}${payload}`)
        return response
    }
}

// Experience and Destination Types API     
export const exp_destination_typesApi = {
    getAllExperienceTypes: async () => {
        const response = await get(experienceTypesEndpoints.getAllExperienceTypes)
        return response
    },

    getAllDestinationTypes: async () => {
        const response = await get(destinationTypesEndpoints.getAllDestinationTypes)
        return response
    },
}

// School Trip API
export const schoolTripApi = {
    createRequest: async (payload) => {
        const response = await post(`${schoolTripEndpoints.createRequest}`, payload)
        return response
    }
}


// Banner API
export const bannersApi = {
    getAllBanners: async () => {
        const response = await get(bannerEdnpoints.getAllBanners)
        return response
    },

}