import {
    Box,
} from "@mui/material";
import styles from "./BannerSlider.module.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { LazyImage } from "../../components";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const CustomPrevArrow = () => (
    <div className="swiper-button-prev">
        <ArrowBackIosIcon
            fontSize="large"
            sx={{ color: "#fff" }}
        />
    </div >
);

const CustomNextArrow = () => (
    <div className="swiper-button-next">
        <ArrowForwardIosIcon
            fontSize="large"
            sx={{ color: "#fff" }}
        />
    </div>
);


const BannerSlider = ({ data }) => {
    const navigate = useNavigate()

    const handleBannerClick = useCallback((item) => {
        if (item.experience) {
            navigate(`/experience/${item.experience.id}`)
        } else if (item.external_url) {
            window.open(item.external_url, "_blank")
        } else return
    }, [navigate])

    return (
        <Swiper
            modules={[Navigation, Autoplay]}
            slidesPerView={1}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,

            }}
            navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            }}
        >
            {
                data && data.length > 1 && (
                    <>
                        <CustomPrevArrow />
                        <CustomNextArrow />
                    </>
                )
            }
            {
                data && (
                    data.map((item) => {
                        return (
                            <SwiperSlide key={item.id}>
                                <Box
                                    className={styles.imageSlider}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => handleBannerClick(item)}
                                >
                                    <LazyImage
                                        src={item.image_url}
                                        alt="EdOutdoors"
                                        width="100%"
                                        height="100%"
                                    />
                                </Box>
                            </SwiperSlide>
                        )
                    })
                )
            }
        </Swiper>
    )
}

export default BannerSlider