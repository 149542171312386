import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Calendar as ReactCalendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Calendar = ({ selectedDate, setSelectedDate, handleClose, toursData, setSelectedTime }) => {

  const minDate = useMemo(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  }, [])

  const getNearestDateToSelect = useCallback(() => {
    if (toursData.length === 0) return undefined;
    const today = new Date();
    const upcomingDates = toursData
      .map(tour => new Date(tour.start_date))
      .filter(date => date >= today);
    return upcomingDates.length > 0 ? new Date(Math.min(...upcomingDates)) : undefined;
  }, [toursData]);

  const [date, setDate] = useState(() => selectedDate ? new Date(selectedDate) : getNearestDateToSelect());

  const handleDayClick = useCallback(date => {
    setSelectedDate(date);
    handleClose();
    setSelectedTime("");
  }, [handleClose, setSelectedTime, setSelectedDate]);

  useEffect(() => {
    setSelectedDate(date);
  }, [date, setSelectedDate]);

  return (
    <ReactCalendar
      onChange={setDate}
      value={date}
      minDate={minDate}
      onClickDay={handleDayClick}
      tileDisabled={({ date }) => {
        return !toursData.some(tour => new Date(tour.start_date).toDateString() === date.toDateString());
      }}
    />
  );
};

export default Calendar;
