import styles from './styles.module.css';
import { Typography, Box } from '@mui/material';
import { LazyImage } from "../"

const Card = ({ item }) => {
    return (
        <a
            className={styles.card}
            href={item.url}
            target='_black'
            rel="noopener noreferrer"
        >
            <Box className={styles.cardImg}>
                <LazyImage
                    src={item.img ? item.img :"./images/noBlog.png"}
                    alt={item?.title}
                    height="100%"
                    width="100%"
                />
            </Box>
            <Box className={styles.cardContent}>
                <Typography variant="h6" gutterBottom>
                    {item?.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {item?.desc}
                </Typography>
            </Box>
        </a>
    )
}

export default Card