import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const RecommendationCard = ({ item }) => {
  return (
    <Card
      component={Link}
      to={`/experience/${item.id}`}
      sx={{
        maxWidth: 320,
        minHeight: 385,
        borderRadius: "1.5rem",
        borderColor: "rgb(232, 232, 232)",
        boxShadow: { xs: "rgba(28, 28, 28, 0.2) 0 0 5px", sm: "rgba(28, 28, 28, 0.12) 0px 0.2rem 1.2rem" },
        display: "block",
        mx: { xs: 0.5 },
        "&:hover": {
          "& .MuiCardMedia-root": {
            transition: "all 0.3s",
            transform: "scale(1.05)",
          },
        },
      }}
    >
      <CardMedia
        component="img"
        alt={item.title}
        height="180"
        image={item.cover_photo}
        sx={{transition: "all 0.3s" }}
      />
      <CardContent>
        <Typography variant="body1" color="text.secondary">
          {
            item?.experience_type
          }
        </Typography>
        <Typography gutterBottom variant="h6" component="div">
          {
            item.title.length > 35 ?
              item.title.slice(0, 35) + "..." : item.title
          }
        </Typography>

        <Typography gutterBottom variant="body2" color="text.secondary">
          {
            item.highlight.length > 70 ?
              item.highlight.slice(0, 70) + "..." : item.highlight
          }
        </Typography>

        <Typography variant="body2" color="text.primary" mt={1}>
          ₹{item.fee}
        </Typography>

      </CardContent>
    </Card>
  )
}

export default RecommendationCard