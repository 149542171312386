import {
    Alert as MuiAlert,
    Stack
} from '@mui/material'

const Alert = ({ type, message }) => {
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            {
                !type || !message ? (
                    <MuiAlert severity="error">
                        Something went wrong!
                    </MuiAlert>
                ) : (
                    <MuiAlert severity={type}>
                        {message}
                    </MuiAlert>
                )
            }
        </Stack>
    )
}

export default Alert