import { useEffect, useState } from 'react'
import {
    Box,
    Container,
    Typography,
    TextField,
    Tabs,
    Tab,
    Button,
    Skeleton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import styles from './Profile.module.css'
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getGrades } from '../../services/actions/grades'
import { getSchools } from '../../services/actions/schools'
import { ProfileModal, SuccessAlert } from '../../components'

const SkeletonLoading = () => {
    return (
        <Box className={styles.profileBox}>
            <Container className={styles.profileContainer}>
                <Box className={styles.profileTop}>
                    <Typography variant="h4">
                        <Skeleton variant="text" width={100} />
                    </Typography>
                    <Button
                        variant="outlined"
                        endIcon={<EditIcon />}
                        sx={{
                            color: "#1D9929",
                            borderColor: "#1D9929",
                            '&:hover': {
                                borderColor: "#1D9929",
                                backgroundColor: "#1D9929",
                                color: "#fff"
                            }

                        }}
                    >
                        <Skeleton variant="text" width={150} />
                    </Button>

                </Box>
                <Box>
                    <Skeleton variant="rectangular" width="100%" height={80} />
                    <Skeleton variant="rectangular" width="100%" height={80} />
                    <Skeleton variant="rectangular" width="100%" height={80} />
                    <Skeleton variant="rectangular" width="100%" height={80} />
                    <Skeleton variant="rectangular" width="100%" height={80} />
                    <Skeleton variant="rectangular" width="100%" height={80} />
                </Box>

            </Container>
        </Box>
    )
}

// For Tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const ProfilePage = () => {
    const [tabValue, setTabValue] = useState(0);
    const [details, setDetails] = useState({
        first_name: "",
        last_name: "",
        dob: "",
        grade_id: "",
        school_id: "",
        email: "",
        father_full_name: "",
        father_profession: "",
        mother_full_name: "",
        mother_profession: "",
        parent_email: "",
        country_visited: [],
        hobby: [],
        subject: []

    })
    const [openEditModal, setOpenEditModal] = useState(false)

    const dispatch = useDispatch()
    const { loading: gradesLoading } = useSelector((state) => state.grades)
    const { loading: schoolsLoading } = useSelector((state) => state.schools)
    const { child, loading: childLoading, status } = useSelector((state) => state.child)
    const { user } = useSelector((state) => state.auth)
    const [showSuccess, setShowSuccess] = useState(false)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleOpenNextTab = () => {
        setTabValue(1)
    }

    const handleOpenPreviousTab = () => {
        setTabValue(0)
    }

    useEffect(() => {
        dispatch(getGrades())
        dispatch(getSchools())
        document.title = "Profile | EdOutdoors"
    }, [dispatch])

    useEffect(() => {
        if (!childLoading) {
            if (child.length === 0) {
                setOpenEditModal(true)
            } else {
                setDetails({
                    ...child,
                    user_id: child.user?.id,
                    grade_id: child.grade?.id,
                    school_id: child.school?.id,
                    passport_expiry_date: "2017-02-09T00:00:00",
                    country_visited: [],
                    hobby: [],
                    subject: []
                })
            }
        }
        // eslint-disable-next-line 
    }, [child])

    if (gradesLoading || schoolsLoading || childLoading || status === "creating" || status === "updating") {
        return (
            <SkeletonLoading />
        )
    }

    return (
        <>
            <Box className={styles.profileBox}>
                <Container className={styles.profileContainer}>
                    <Box className={styles.profileTop}>
                        <Typography variant="h4">
                            Profile
                        </Typography>
                    </Box>
                    <Box>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="profile tabs">
                            <Tab label="CHILD DETAILS" />
                            <Tab label="PARENT DETAILS" />
                        </Tabs>
                    </Box>

                    {/* Child Details */}
                    <TabPanel value={tabValue} index={0}>
                        <Box className={styles.form} >
                            <Box className={styles.inputField}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="first_name"
                                    value={details.first_name}
                                    inputProps={
                                        {
                                            readOnly: true,
                                        }
                                    }
                                />
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="last_name"
                                    value={details.last_name}
                                    inputProps={
                                        {
                                            readOnly: true,
                                        }
                                    }
                                />
                            </Box>

                            <Box className={styles.inputField}>
                                <TextField
                                    fullWidth
                                    label="Date of Birth (YYYY-MM-DD)"
                                    name="dob"
                                    value={
                                        details.dob ? moment(details.dob).format("YYYY-MM-DD") : ""
                                    }
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    label="Class"
                                    name="grade_id"
                                    value={
                                        details?.grade?.grade || ""
                                    }
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                />

                            </Box>

                            <Box className={styles.inputField}>
                                {/* <TextField
                                    fullWidth
                                    label="School Name"
                                    name="school_id"
                                    value={
                                        details?.school?.name || ""
                                    }
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                /> */}

                                {/* <TextField
                                    fullWidth
                                    required
                                    label="Email"
                                    name="email"
                                    value={details.email}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                /> */}
                            </Box>

                            <Box className={styles.formButton}>
                                <button
                                    type="button"
                                    onClick={handleOpenNextTab}
                                >
                                    Next
                                </button>
                                <button
                                    type='button'
                                    onClick={() => setOpenEditModal(true)}
                                    className={styles.editButton}
                                >
                                    Edit <EditIcon fontSize='small' />
                                </button>
                            </Box>
                        </Box>
                    </TabPanel>

                    {/* Parent Details */}
                    <TabPanel value={tabValue} index={1}>
                        <Box className={styles.form} >
                            <Box className={styles.inputField}>
                                <TextField
                                    fullWidth
                                    label="Father Full Name"
                                    name="father_full_name"
                                    value={details.father_full_name}
                                    inputProps={
                                        {
                                            readOnly: true,
                                        }
                                    }
                                />

                                <TextField
                                    fullWidth
                                    label="Mother Full Name"
                                    name="mother_full_name"
                                    value={details.mother_full_name}
                                    inputProps={
                                        {
                                            readOnly: true,
                                        }
                                    }
                                />

                                {/* <TextField
                                    fullWidth
                                    label="Father Profession"
                                    name="father_profession"
                                    value={details.father_profession}
                                    inputProps={
                                        {
                                            readOnly: true,
                                        }
                                    }
                                /> */}
                            </Box>

                            {/* <Box className={styles.inputField}>
                                

                                <TextField
                                    fullWidth
                                    label="Mother Profession"
                                    name="mother_profession"
                                    value={details.mother_profession}
                                    inputProps={
                                        {
                                            readOnly: true,
                                        }
                                    }
                                />
                            </Box> */}

                            <Box className={styles.inputField}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Parent Email"
                                    name="parent_email"
                                    value={details.parent_email}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    label="Mobile Number"
                                    name="mobile_number"
                                    value={user.user.user.mobile_number}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Box>

                            <Box className={styles.formButton}>
                                <button
                                    type="button"
                                    onClick={handleOpenPreviousTab}
                                >
                                    Previous
                                </button>
                                <button
                                    type='button'
                                    onClick={() => setOpenEditModal(true)}
                                    className={styles.editButton}
                                >
                                    Edit <EditIcon fontSize='small' />
                                </button>
                            </Box>
                        </Box>
                    </TabPanel>

                </Container>
            </Box>

            {
                openEditModal ? (
                    <ProfileModal
                        open={openEditModal || !child.length}
                        handleClose={() => { setOpenEditModal(false) }}
                        action="edit"
                        editData={details}
                        setShowSuccess={setShowSuccess}
                    />
                ) : null
            }

            {
                showSuccess && (
                    <SuccessAlert
                        open={showSuccess}
                        title="Success"
                        message={`Your profile has been updated successfully.`}
                        handleClose={() => setShowSuccess(false)}
                    />
                )
            }
        </>
    )
}

export default ProfilePage