import styles from './ContactUs.module.css'
import { Box, Container, Grid, Typography } from "@mui/material"
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import EmailIcon from '@mui/icons-material/Email';
import { Helmet } from "react-helmet";

const ContactUs = () => {

    return (
        <>
            <Helmet>
                <title>Contact Us | EdOutdoors</title>
                <meta
                    name="description"
                    content="Reach out to us anytime. You need assistance figuring out the right outdoor experience for your kid or your school, or facing an issue with any existing reservation, we’re here. Reach out to us via email or phone."
                />
                <meta property="og:title" content="Contact Us | EdOutdoors" />
                <meta property="og:description" content="Reach out to us anytime. You need assistance figuring out the right outdoor experience for your kid or your school, or facing an issue with any existing reservation, we’re here. Reach out to us via email or phone." />
                <link rel="canonical" href="https://www.edoutdoors.in/contact-us" />
            </Helmet>
            <Container className={styles.contactContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box className={styles.contactbox}>
                            <Typography variant="h3">
                                Reach out to us. Anytime.
                            </Typography>
                            <Typography variant="body1" component="p">
                                You need assistance figuring out the right outdoor experience for your kid or your school, or facing an issue with any existing reservation, we’re here. Reach out to us via email or phone.
                            </Typography>
                        </Box>
                        <Box className={styles.boxes}>
                            <Box className={styles.box}>
                                <AddIcCallIcon fontSize='large' />
                                <Typography variant="h6">
                                    Call Us
                                </Typography>
                                <Typography variant="p" component="p">
                                    +91 88840 45670
                                </Typography>
                            </Box>

                            <Box className={styles.box}>
                                <EmailIcon fontSize='large' />
                                <Typography variant="h6">
                                    Send Us An Email
                                </Typography>
                                <Typography variant="p" component="p">
                                    hello@edoutdoors.in
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={styles.contactImage}>
                            <img src="/images/contact.webp" alt="" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default ContactUs