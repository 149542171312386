import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Box, Typography } from '@mui/material';
import styles from "./style.module.css";
import LazyImage from '../lazyImage/LazyImage';
import { useLocation } from 'react-router-dom';


const CustomPrevArrow = () => (
    <div className="swiper-button-prev hide-arrow"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/prevIcon.png"
            alt="Previous"
            className='arrows-sm'
            style={{ width: "100%" }}
        />
    </div>
);

const CustomNextArrow = () => (
    <div
        className="swiper-button-next hide-arrow"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/nextIcon.png"
            alt="Next"
            className='arrows-sm'
            style={{ width: "100%" }}

        />
    </div>
);

const FilterSlider = ({ options, setAppliedFilters, appliedFilters, resetFilters, initialIndex }) => {
    const location = useLocation();

    const getIcon = (name) => {
        switch (name) {
            case 'Trek':
                return '/images/trekking.png';
            case 'Leisure':
                return '/images/leisure.png';
            case 'Wildlife':
                return '/images/wildlife.png';
            case 'Industrial Visit':
                return '/images/industry.png';
            case 'Water Adventure':
                return '/images/water-adventure.png';
            case 'Campus Visit':
                return '/images/campus-visit.png';
            case 'Volunteering':
                return '/images/volunteer.png';
            case 'Workshop':
                return '/images/workshop.png';
            case 'Walking Tour':
                return '/images/walking-tour.png';
            case 'Wilderness':
                return '/images/wildlife.png';
            default:
                return '/images/Trekking.png';
        }
    }

    const selectFilter = (name) => {
        if (appliedFilters.experienceTypes.length === 1 && appliedFilters.experienceTypes.includes(name)) return resetFilters();
        setAppliedFilters(prevFilters => {
            if (prevFilters.experienceTypes.includes(name)) {
                return { ...prevFilters, experienceTypes: prevFilters.experienceTypes.filter(type => type !== name) };
            } else {
                return { ...prevFilters, experienceTypes: [...prevFilters.experienceTypes, name] };
            }
        });
    };
    
    return (
        <Swiper
            modules={[Navigation]}
            spaceBetween={window.innerWidth > 768 ? 50 : 10}
            slidesPerView={window.innerWidth > 1000 ? 6 : window.innerWidth > 700 ? 4 : window.innerWidth > 600 ? 3 : 2}
            slidesPerGroup={2}
            navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            }}
            loop={false}
            style={
                window.innerWidth > 768 ? {
                    padding: '5px 15px 5px 25px'
                } : {
                    padding: '5px 50px 5px 0'
                }
            }
            initialSlide={initialIndex}
        >
            <CustomPrevArrow />
            <CustomNextArrow />
            {options?.map((item, index) => (
                <SwiperSlide key={index}>
                    <Box
                        className={appliedFilters.experienceTypes.includes(item.name) ||
                            location?.search?.split("=")[1]?.replace("%20", "") === item?.name?.replace(" ", "")
                            ? styles.filterItemActive : styles.filterItem}
                        onClick={() => selectFilter(item.name)}
                    >
                        <Box className={styles.filterIcon}>
                            <LazyImage
                                src={getIcon(item.name)}
                                alt={item.name}
                                width="100%"
                                height="100%"
                            />
                        </Box>
                        <Typography
                            variant="h6"
                            component="h6"
                            sx={{
                                fontSize: '0.9rem',
                                fontWeight: '500',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {item.name}
                        </Typography>
                    </Box>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default FilterSlider