import styles from "./SingleExperince.module.css"
import {
    Box,
    Container,
    Grid,
    Skeleton,
    Typography,
    Avatar,
    Button,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Accordion from "./Accordion";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import {
    CalendarModal,
    ImageSlider,
    Alert,
    TimePicker,
    AuthModal,
    ProfileModal,
    HideOnScroll,
    SuccessAlert,
    ConfirmBox,
    Recommendations
} from "../../components";
import moment from "moment"
import { expApi, tourApi, notifyApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { changeNotifyExp } from "../../services/actions/notify";
import Faqs from "./Faqs";
import DateTimeModal from "./DateTimeModal";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import SchoolTripModal from "./SchoolTripModal"
import { Helmet } from "react-helmet";

const SkeletonLoading = () => {
    return (
        <Box className={styles.singleListing}>
            <Container className={styles.container}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={8}>
                        <Box className={styles.topImages}>
                            <Skeleton variant="rectangular" width="100%" height={400} />
                        </Box>

                        <Box className={styles.details}>
                            <Skeleton variant="text" width={200} />

                            <Box className={styles.features}>
                                <Box className={styles.feature}>
                                    <Skeleton variant="rectangular" width={100} height={50} />
                                </Box>
                                <Box className={styles.feature}>
                                    <Skeleton variant="rectangular" width={100} height={50} />
                                </Box>
                                <Box className={styles.feature}>
                                    <Skeleton variant="rectangular" width={100} height={50} />
                                </Box>
                            </Box>

                            <Box className={styles.description}>
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="100%" />
                            </Box>

                            <Box className={styles.accordion}>
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="100%" />
                            </Box>
                        </Box>


                    </Grid>

                    <Grid item xs={12} md={4} >
                        <Box className={styles.stickyPriceBox}>
                            <Box className={styles.priceBox}>
                                <Box className={styles.priceBottom}>
                                    <Box className={styles.head}>
                                        <Skeleton variant="text" width={200} />
                                    </Box>
                                    <Box className={styles.body}>
                                        <Box className={styles.priceFeatures}>
                                            <Skeleton variant="text" width={200} />
                                        </Box>
                                        <Box className={styles.priceFeatures}>
                                            <Skeleton variant="text" width={200} />
                                        </Box>
                                        <Box className={styles.priceFeatures}>
                                            <Skeleton variant="text" width={200} />
                                        </Box>
                                        <Box className={styles.priceFeatures}>
                                            <Skeleton variant="text" width={200} />
                                        </Box>
                                        <Box className={styles.priceFeatures}>
                                            <Skeleton variant="text" width={200} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>

                    </Grid>

                </Grid>
            </Container>

        </Box>
    )
}

const SingleExperince = () => {
    const { user } = useSelector(state => state.auth)
    const { child } = useSelector(state => state.child)
    const { notifiedExps } = useSelector(state => state.notify)
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = useParams();
    const [showCalendar, setShowCalendar] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState("")
    const [showAlert, setShowAlert] = useState({
        show: false,
        message: ""
    })
    const [images, setImages] = useState([])
    const [exp, setExp] = useState(null)
    const [itineary, setItineary] = useState([])
    const [grades, setGrades] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [policy, setPolicy] = useState(null)
    const [toursData, setToursData] = useState([])
    const [loggedIn, setLoggedIn] = useState(false)
    const [tourId, setTourId] = useState(null)
    const [showAuth, setShowAuth] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [notifyLoading, setNotifyLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [openMobileCalendar, setOpenMobileCalendar] = useState(false) // mobile calendar and time picker
    const [openSchoolTripModal, setOpenSchoolTripModal] = useState(false)
    const [places, setPlaces] = useState([])

    // Date time picker modal for mobile
    const handleOpenMobileCalendar = () => {
        setOpenMobileCalendar(true)
    }

    const handleCloseMobileCalendar = () => {
        setSelectedDate(null)
        setSelectedTime("")
        setOpenMobileCalendar(false)
    }

    const handleOpenSchoolTripModal = () => {
        setOpenSchoolTripModal(true)
    }

    const handleShowAlert = (message) => {
        setShowAlert({
            show: true,
            message
        })
        setTimeout(() => {
            setShowAlert({
                show: false,
                message: ""
            })
        }, 3000)
    }

    const handleProceed = () => {
        if (!selectedDate || !selectedTime) {
            handleShowAlert("Please select date and time")
            return
        }

        navigate(`/checkout/${id}?date=${moment(selectedDate).format("DD MM YYYY")}&time=${selectedTime}&tour_id=${tourId}`, {
            state: {
                selectedDate,
                selectedTime,
                location,
                exp,
                tourId,
                policy: policy?.cancellation_policy
            }
        })
    }

    const handleNotifyMe = async () => {
        if (!loggedIn) {
            setShowAuth(true)
            return
        }

        if (child.length === 0) {
            setShowProfile(true)
            return
        }

        try {
            setShowConfirm(false)
            setNotifyLoading(true)
            const response = await notifyApi.notifyMe({
                experience_id: Number(id),
                child_id: child.id
            })
            if (response.data.success) {
                dispatch(changeNotifyExp(id))
                setShowSuccess(true)
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setNotifyLoading(false)
        }
    }

    useEffect(() => {
        if (user) {
            setLoggedIn(true)
        }
    }, [user])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [expResponse, toursResponse] = await Promise.all([
                    expApi.getExperience(id),
                    tourApi.getAllTours(id),
                ]);
                if (expResponse.data.success) {
                    setExp(expResponse?.data?.data?.experience);
                    setImages(() => {
                        return [
                            {
                                id: 1,
                                image_url: expResponse?.data?.data?.experience?.cover_photo
                            },
                            ...expResponse?.data?.data?.images
                        ]
                    });
                    setPolicy(expResponse?.data?.data?.policy[0]);
                    setItineary(expResponse?.data?.data?.itineary);
                    setGrades(expResponse?.data?.data?.grade);
                    setPlaces(expResponse?.data?.data?.places?.map((item) => item?.place))
                }
                if (toursResponse.data.success) {
                    const upcomingTours = toursResponse.data.data.filter((item) => new Date(item.start_date) > new Date());
                    setToursData(upcomingTours);
                }
            } catch (error) {
                console.error(error);
                navigate('/experiences');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        setTourId(null);
        //eslint-disable-next-line
    }, [id]);

    // for edit experience opens calendar modal on load
    useEffect(() => {
        if (location?.state?.edit) {
            handleOpenMobileCalendar()
            setShowCalendar(true)
        }
    }, [location.state])

    return (
        <>
            <Helmet>
                <title>{`${exp?.title} | School Trip | EdOutdoors`}</title>
                <meta name="description" content={`${exp?.highlight}`} />
                <meta property="og:title" content={`${exp?.title}`} />
                <meta property="og:description" content={`${exp?.highlight}`} />
                <link rel="canonical" href={`https://www.edoutdoors.in/experience/${exp?.id}`} />
            </Helmet>
            {
                loading ? (
                    <SkeletonLoading />
                ) : (
                    <Box className={styles.singleListing}>
                        <Container className={styles.container}>
                            <Grid container spacing={0} >

                                <Grid item xs={12} md={8} >
                                    <Box className={styles.topImages}>
                                        <ImageSlider images={images} />
                                    </Box>
                                    <Box className={styles.details}>
                                        <h1>{exp?.title}</h1>

                                        <Box className={styles.partner}>
                                            <Typography
                                                className={styles.partnerTitle}
                                                component="div"
                                                onClick={() => navigate(`/partner/${exp?.partner?.id}`)}
                                            >
                                                <Typography variant="h6">
                                                    {places?.map(place => place)?.join(', ')}
                                                </Typography>
                                                <Typography variant="subtitle1" component="span" />
                                                <Typography component="h6">
                                                    By
                                                </Typography>
                                                <Typography variant="h6">
                                                    {exp?.partner?.name}
                                                </Typography>
                                            </Typography>
                                            <Avatar
                                                alt={exp?.partner?.name}
                                                src={exp?.partner?.profile_photo}
                                                sx={{ width: 30, height: 30, mx: 1, cursor: "pointer" }}
                                                onClick={() => navigate(`/partner/${exp?.partner?.id}`)}
                                            />

                                        </Box>

                                        {
                                            window.innerWidth < 768 && (
                                                <Typography variant="h6">
                                                    ₹{exp?.fee} <Typography variant="subtitle1" component="span">/ Child</Typography>
                                                </Typography>
                                            )
                                        }

                                        <Box className={styles.highlights}>
                                            <Box className={styles.features}>
                                                <Box className={styles.feature}>
                                                    <AvTimerIcon /> {exp?.duration === "1 Days" ? exp?.duration?.replace("Days", "Day") : exp?.duration} trip
                                                </Box>
                                                <Box className={styles.feature}>
                                                    <img src="/images/books.png" alt="EdOutdoors" /> {exp?.primary_subject?.name}, {exp?.secondary_subject?.name}
                                                </Box>

                                                <Box className={styles.feature}>
                                                    <img src="/images/age-group.png" alt="EdOutdoors" /> Grades {grades[0]?.grade?.grade} - {grades[grades.length - 1]?.grade?.grade}
                                                </Box>
                                            </Box>

                                            <Box className={styles.highlight}>
                                                <Typography variant="body">
                                                    {exp?.highlight}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box className={styles.accordion}>
                                            <Accordion
                                                details={exp?.description}
                                                inclusions={exp?.inclusions}
                                                exclusions={exp?.exclusions}
                                                cancellationPolicy={policy}
                                                outcomes={exp?.outcome}
                                                itineary={itineary}
                                                requirements={exp?.requirement}
                                            />
                                        </Box>

                                    </Box>

                                    <Box className={styles.host}>
                                        <Box className={styles.hostHead}>
                                            <Avatar
                                                alt={exp?.partner?.name}
                                                src={exp?.partner?.profile_photo}
                                                sx={{ width: 45, height: 45, cursor: "pointer" }}
                                                onClick={() => navigate(`/partner/${exp?.partner?.id}`)}
                                            />
                                            <Typography variant="h6">
                                                Meet your host, {exp?.partner?.name}
                                            </Typography>
                                        </Box>
                                        <Box className={styles.hostBody}>
                                            <Typography variant="body" sx={{ color: "#0F0F43" }}>
                                                {exp?.partner?.highlight}
                                            </Typography>
                                            <Button
                                                variant="link"
                                                size="small"
                                                onClick={() => navigate(`/partner/${exp?.partner?.id}`)}
                                            >
                                                Read more about {exp?.partner?.name}
                                            </Button>
                                        </Box>
                                    </Box>

                                </Grid>

                                <Grid item xs={12} md={4} className={styles.paddingContainer}>
                                    <Box className={styles.stickyPriceBox}>
                                        {
                                            window.innerWidth > 768 ? (

                                                // Desktop Price

                                                <Box className={styles.priceBox}>
                                                    {
                                                        toursData.length > 0 ? (
                                                            <>
                                                                <Box className={styles.fee}>
                                                                    <Typography variant="h5">
                                                                        ₹{exp?.fee} <Typography variant="subtitle1" component="span">/ Child</Typography>
                                                                    </Typography>
                                                                </Box>
                                                                <Box className={styles.date}>

                                                                    <Box className={styles.dateSelectButton}>
                                                                        <button type="button" onClick={() => setShowCalendar(true)}>
                                                                            {
                                                                                selectedDate ? (
                                                                                    <>
                                                                                        <Typography variant="body" color="text.secondary" >
                                                                                            {moment(selectedDate).format('DD/MM/YYYY')}
                                                                                        </Typography>
                                                                                        <span className={styles.dateButtonIcon}>
                                                                                            <ArrowDropDownIcon />
                                                                                        </span>
                                                                                    </>
                                                                                ) : (

                                                                                    <>
                                                                                        <Typography variant="body" color="text.secondary">
                                                                                            Select an upcoming slot
                                                                                        </Typography>
                                                                                        <span className={styles.dateButtonIcon}>
                                                                                            <ArrowDropDownIcon />
                                                                                        </span>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </button>
                                                                    </Box>

                                                                    {
                                                                        selectedDate && (
                                                                            <Box className={styles.timeSlot}>
                                                                                <TimePicker
                                                                                    selectedTime={selectedTime}
                                                                                    setSelectedTime={setSelectedTime}
                                                                                    toursData={toursData}
                                                                                    selectedDate={selectedDate}
                                                                                    setTourId={setTourId}
                                                                                />
                                                                            </Box>
                                                                        )
                                                                    }

                                                                    {
                                                                        selectedTime && (
                                                                            <Box className={styles.timeAndLocation}>
                                                                                <Typography variant="caption" component="p">
                                                                                    Exact time+location shared on confirmed booking.
                                                                                </Typography>
                                                                            </Box>
                                                                        )
                                                                    }

                                                                    <Box className={styles.availabilityButton}>
                                                                        <button
                                                                            type="button"
                                                                            onClick={handleProceed}
                                                                        >
                                                                            Check Availability
                                                                        </button>
                                                                        {
                                                                            exp?.school_trips && (
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={handleOpenSchoolTripModal}
                                                                                    className={styles.schoolTripBtn}
                                                                                >
                                                                                    Inquire for a school trip
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </Box>

                                                                    {
                                                                        showAlert.show && (
                                                                            <Box sx={{ width: "90%", mt: 2 }}>
                                                                                <Alert
                                                                                    type="error"
                                                                                    message={showAlert.message}
                                                                                />
                                                                            </Box>
                                                                        )
                                                                    }

                                                                    <Box className={styles.calendarBox}>
                                                                        {
                                                                            showCalendar &&
                                                                            <CalendarModal
                                                                                open={showCalendar}
                                                                                handleClose={() => setShowCalendar(false)}
                                                                                selectedDate={selectedDate}
                                                                                setSelectedDate={setSelectedDate}
                                                                                toursData={toursData}
                                                                                setSelectedTime={setSelectedTime}
                                                                            />
                                                                        }
                                                                    </Box>

                                                                </Box>
                                                            </>
                                                        ) : (
                                                            <Box className={styles.noToursAvailable}>
                                                                {
                                                                    loading ? (
                                                                        <Skeleton
                                                                            variant="rectangular"
                                                                            width="100%"
                                                                            height={200}
                                                                        />
                                                                    ) : (
                                                                        <Box className={styles.notAvailable}>
                                                                            {
                                                                                notifiedExps.includes(id) ? (
                                                                                    <Typography variant="body" component="p">
                                                                                        Done! We’ll notify you on the next available date of this experience
                                                                                    </Typography>
                                                                                ) : (
                                                                                    <>
                                                                                        <Typography variant="body1" component="p">
                                                                                            No upcoming tours! 😞
                                                                                        </Typography>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() => {
                                                                                                if (!loggedIn) {
                                                                                                    setShowAuth(true)
                                                                                                    return
                                                                                                }

                                                                                                if (child.length === 0) {
                                                                                                    setShowProfile(true)
                                                                                                    return
                                                                                                }

                                                                                                setShowConfirm(true)
                                                                                            }}
                                                                                            disabled={notifyLoading}
                                                                                        >
                                                                                            Notify Me
                                                                                        </button>
                                                                                        {
                                                                                            exp?.school_trips && (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={handleOpenSchoolTripModal}
                                                                                                    className={styles.schoolTripBtn}
                                                                                                >
                                                                                                    Inquire for a school trip
                                                                                                </button>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }

                                                                        </Box>
                                                                    )
                                                                }
                                                            </Box>
                                                        )
                                                    }

                                                </Box>
                                            ) : (

                                                // Mobile Price

                                                <HideOnScroll direction="up">
                                                    <Box className={styles.priceBox}>
                                                        {
                                                            toursData.length > 0 ? (
                                                                <>
                                                                    <Box className={styles.mobileCalendarBtn}>
                                                                        <button
                                                                            type="button"
                                                                            onClick={handleOpenMobileCalendar}
                                                                        >
                                                                            Check Availability
                                                                        </button>
                                                                        {
                                                                            exp?.school_trips && (
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={handleOpenSchoolTripModal}
                                                                                    className={styles.schoolTripBtn}
                                                                                >
                                                                                    Inquire for a school trip
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </Box>

                                                                    <DateTimeModal
                                                                        open={openMobileCalendar}
                                                                        handleClose={handleCloseMobileCalendar}
                                                                        selectedDate={selectedDate}
                                                                        setSelectedDate={setSelectedDate}
                                                                        toursData={toursData}
                                                                        setSelectedTime={setSelectedTime}
                                                                        selectedTime={selectedTime}
                                                                        setTourId={setTourId}
                                                                        setShowCalendar={setShowCalendar}
                                                                        showCalendar={showCalendar}
                                                                        handleProceed={handleProceed}
                                                                        showAlert={showAlert}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <Box className={styles.noToursAvailable}>
                                                                    {
                                                                        loading ? (
                                                                            <Skeleton
                                                                                variant="rectangular"
                                                                                width="100%"
                                                                                height={200}
                                                                            />
                                                                        ) : (
                                                                            <Box className={styles.notAvailable}>
                                                                                {
                                                                                    notifiedExps.includes(id) ? (
                                                                                        <Typography variant="body" component="p">
                                                                                            Done! We’ll notify you on the next available date of this experience
                                                                                        </Typography>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Typography variant="body1" component="p">
                                                                                                No upcoming tours! 😞
                                                                                            </Typography>
                                                                                            <button
                                                                                                type="button"
                                                                                                onClick={() => {
                                                                                                    if (!loggedIn) {
                                                                                                        setShowAuth(true)
                                                                                                        return
                                                                                                    }

                                                                                                    if (child.length === 0) {
                                                                                                        setShowProfile(true)
                                                                                                        return
                                                                                                    }

                                                                                                    setShowConfirm(true)
                                                                                                }}
                                                                                                disabled={notifyLoading}
                                                                                            >
                                                                                                Notify Me
                                                                                            </button>
                                                                                            {
                                                                                                exp?.school_trips && (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        onClick={handleOpenSchoolTripModal}
                                                                                                        className={styles.schoolTripBtn}
                                                                                                    >
                                                                                                        Inquire for a school trip
                                                                                                    </button>
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </Box>
                                                                        )
                                                                    }
                                                                </Box>
                                                            )
                                                        }

                                                    </Box>
                                                </HideOnScroll>
                                            )
                                        }

                                        <Box className={styles.priceBottom}>
                                            <Box className={styles.head}>
                                                <h4>Why book with us</h4>
                                            </Box>
                                            <Box className={styles.body}>
                                                <Box className={styles.priceFeatures}>
                                                    <p className={styles.icon}><DownloadDoneIcon /></p>
                                                    <p>Find curriculum and skill mapped field trips</p>
                                                </Box>
                                                <Box className={styles.priceFeatures}>
                                                    <p className={styles.icon}><DownloadDoneIcon /></p>
                                                    <p>We onboard only vetted service providers</p>
                                                </Box>
                                                <Box className={styles.priceFeatures}>
                                                    <p className={styles.icon}><DownloadDoneIcon /></p>
                                                    <p>Safety is given top priority in all experiences</p>
                                                </Box>
                                                <Box className={styles.priceFeatures}>
                                                    <p className={styles.icon}><DownloadDoneIcon /></p>
                                                    <p>Secure payments and refund policies</p>
                                                </Box>
                                                <Box className={styles.priceFeatures}>
                                                    <p className={styles.icon}><DownloadDoneIcon /></p>
                                                    <p>Have a question? Live chat with us</p>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Container>

                        <Container sx={{ my: { xs: 0, sm: 4 } }}>

                        </Container>

                        <Box sx={{ my: { xs: 0, sm: 7 } }}>
                            {exp && <Recommendations expId={exp.id} />}
                        </Box>

                        <Box sx={{ mt: { xs: "40px", sm: 7 } }}>
                            <Faqs />
                        </Box>

                        {
                            showAuth && (
                                <AuthModal show={showAuth} closeAuth={() => setShowAuth(false)} />
                            )
                        }

                        {
                            showProfile && (
                                <ProfileModal
                                    open={showProfile}
                                    action="create"
                                    handleClose={() => setShowProfile(false)}
                                />
                            )
                        }

                        {
                            showSuccess && (
                                <SuccessAlert
                                    open={showSuccess}
                                    title="We'll notify you"
                                    message="You'll receive a notification from us the moment a new tour is added to this experience."
                                    handleClose={() => setShowSuccess(false)}
                                />
                            )
                        }

                        {
                            showConfirm && (
                                <ConfirmBox
                                    open={showConfirm}
                                    handleClose={() => setShowConfirm(false)}
                                    onProceedClick={handleNotifyMe}
                                    title="Confirm Email Notification"
                                    message="Are you sure you want to be notified when the next tour is available ?"
                                />
                            )
                        }

                        {
                            openSchoolTripModal && (
                                <SchoolTripModal
                                    open={openSchoolTripModal}
                                    handleClose={() => setOpenSchoolTripModal(false)}
                                    expId={id}
                                    partnerName={exp?.partner?.name}
                                />
                            )
                        }

                    </Box>
                )
            }
        </>
    )
}

export default SingleExperince