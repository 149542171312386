import {
    GET_CHILD,
    GET_CHILD_SUCCESS,
    GET_CHILD_FAILURE,
} from '../actions/child';

import { call, put, takeEvery } from 'redux-saga/effects';
import { childApi } from '../api';

function* getChild(payload) {
    try {
        const response = yield call(childApi.getChild, payload);
        yield put({ type: GET_CHILD_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_CHILD_FAILURE, payload: error });
    }
}

export default function* childSaga() {
    yield takeEvery(GET_CHILD, getChild);
}