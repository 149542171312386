export const filters = [
    {
        name: "Water Adventure",
        image: "/images/home/filters/1.webp",
    },
    {
        name: "Trek",
        image: "/images/home/filters/2.webp",
    },
    {
        name: "Industrial Visit",
        image: "/images/home/filters/3.webp",
    },
    {
        name: "Heritage",
        image: "/images/home/filters/4.webp",
    },
    {
        name: "Leisure",
        image: "/images/home/filters/5.webp",
    },
    {
        name: "Wildlife",
        image: "/images/home/filters/6.webp",
    },
]
