import {
    GET_EXPERIENCES,
    GET_EXPERIENCES_SUCCESS,
    GET_EXPERIENCES_FAILURE,
    GET_UPCOMING_EXPS,
    GET_UPCOMING_EXPS_SUCCESS,
    GET_UPCOMING_EXPS_FAILURE,
} from '../actions/experiences';

const initialState = {
    experiences: null,
    upcomingExps: null,
    loading: false,
    error: null,
};

export const experiences = (data = initialState, action) => {
    switch (action.type) {
        case GET_EXPERIENCES:
        case GET_UPCOMING_EXPS:
            return {
                ...data,
                loading: true,
                error: null,
            };

        case GET_EXPERIENCES_SUCCESS:
            return {
                ...data,
                loading: false,
                experiences: action.payload.filter((experience) => experience.status),
            };

        case GET_UPCOMING_EXPS_SUCCESS:
            return {
                ...data,
                loading: false,
                upcomingExps: action.payload.filter((experience) => experience.status),
            };

        case GET_EXPERIENCES_FAILURE:
        case GET_UPCOMING_EXPS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
                experiences: [],
                upcomingExps: [],
            };

        default:
            return data;
    }
};
