import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ExpCard from './ExpCard';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const CustomPrevArrow = () => (
    <div className="swiper-button-prev"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/prevIcon.png"
            alt="Previous"
            className='arrows-sm'
            style={{ width: "100%" }}
        />
    </div>
);

const CustomNextArrow = () => (
    <div
        className="swiper-button-next"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/nextIcon.png"
            alt="Next"
            className='arrows-sm'
            style={{ width: "100%" }}

        />
    </div>
);

const Collections = ({ collection }) => {
    const { experiences } = useSelector((state) => state.experiences);
    const [expData, setExpData] = useState([])

    useEffect(() => {
        const filteredData = experiences?.filter((item) =>
            collection?.experiences?.includes(item?.id)
        );
        setExpData(filteredData);
    }, [experiences, collection]);

    return (
        expData?.length > 0 && (
            <Box>
                <Box className={styles.collectionsHead}>
                    <Typography variant="h2" gutterBottom sx={{ textTransform: "capitalize" }}>
                        {collection.name}
                    </Typography>
                    <Box className={styles.seeAllBtn}>
                        <Link to={`/collections/${collection.id}`} >
                            See All
                        </Link>
                        <ArrowForwardIosIcon fontSize="small" />
                    </Box>
                </Box>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={0}
                    slidesPerView={window.innerWidth > 1000 ? 3 : window.innerWidth > 600 ? 2 : 1}
                    navigation={
                        {
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }
                    }
                    style={
                        window.innerWidth > 600 ? { padding: '20px 25px 20px 10px' } : { padding: '15px 40px 15px 0' }
                    }

                >
                    <CustomPrevArrow />
                    <CustomNextArrow />
                    {

                        expData?.map((item) => (
                            <SwiperSlide key={item.id}>
                                <ExpCard item={item} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Box>
        )
    )
}

export default Collections