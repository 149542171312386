import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Stack,
  CircularProgress,
  Alert as MuiAlert,
} from "@mui/material";
import styles from "./Signup.module.css";
import { useState, useRef, useEffect } from "react";
import { Alert } from "../../components";
import { authApi } from "../../services/api";
import OTPInput from "otp-input-react";
import { Helmet } from "react-helmet";

const Signup = () => {
  const [action, setAction] = useState("signup");
  const [showOtp, setShowOtp] = useState(false);
  const [number, setNumber] = useState("");
  const [numError, setNumError] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [resendCount, setResendCount] = useState(0);

  const inputRef = useRef(null);

  const handleShowOtpAlert = () => {
    setOtpError(true);
    setTimeout(() => {
      setOtpError(false);
    }, 6000);
  };

  const handleSignUpChange = () => {
    action === "signup" ? setAction("signin") : setAction("signup");
    setError("");
    setOtpError(false);
    setNumError(false);
  };

  // Login
  const handleSendLoginOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResendCount(0);
    try {
      const response = await authApi.sendLoginOtp(number);
      if (response.data.success) {
        setShowOtp(true);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setNumError(true);
    } finally {
      setLoading(false);
    }
  };

  // Signup
  const handleSendSignupOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await authApi.sendSignupOtp(number);
      if (response.data.success) {
        setShowOtp(true);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setNumError(true);
    } finally {
      setLoading(false);
    }
  };

  // setting the max length of the input field to 10
  const handleNumberChange = (object) => {
    if (object.target.value.length > 10) {
      object.target.value = object.target.value.slice(0, 10);
      setNumber(object.target.value);
    } else {
      setNumber(object.target.value);
    }
  };

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  // Login verify
  const handleVerifyLoginOpt = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) return;
    setLoading(true);
    try {
      const response = await authApi.verifyLoginOtp({
        mobile: number,
        otp,
      });
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        window.location.replace("/");
      } else {
        handleShowOtpAlert();
      }
    } catch (error) {
      handleShowOtpAlert();
    } finally {
      setLoading(false);
    }
  };

  // Signup verify
  const handleVerifySignupOpt = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) return;
    setLoading(true);
    try {
      const response = await authApi.verifySignupOtp({
        mobile: number,
        otp,
      });
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        window.location.replace("/");
      } else {
        handleShowOtpAlert();
      }
    } catch (error) {
      handleShowOtpAlert();
    } finally {
      setLoading(false);
    }
  };

  const handleChangeNumber = () => {
    setOtp("");
    setAction("signup");
    setShowOtp(false);
  };

  const handleSendAgain = async () => {
    if (resendCount >= 2) return;
    setLoading(true);
    try {
      const response = await authApi.resendOtp(number);
      if (response.data.success) {
        setShowOtp(true);
      } else {
        setError(response.data.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } catch (error) {
      setNumError(true);
    } finally {
      setResendCount(resendCount + 1);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [action]);

  return (
    <>
      <Helmet>
        <title>Sign Up | EdOutdoors</title>
        <meta
          name="description"
          content="Get started with your mobile number. Making outdoor learning a lifestyle for K12. Get the best outdoor learning providers under one roof."
        />
        <meta property="og:title" content="Sign Up | EdOutdoors" />
        <meta
          property="og:description"
          content="Get started with your mobile number. Making outdoor learning a lifestyle for K12. Get the best outdoor learning providers under one roof."
        />
        <link rel="canonical" href="https://www.edoutdoors.in/signup" />
      </Helmet>
      <Container className={styles.signupContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className={styles.signupbox}>
              {showOtp ? (
                <Box className={styles.otpForm}>
                  <Typography variant="h1">Enter OTP</Typography>
                  <Typography variant="body1" mt={0.5}>
                    We have sent a 6 digit OTP to {number}
                  </Typography>

                  <form
                    onSubmit={
                      action === "signup"
                        ? handleVerifySignupOpt
                        : handleVerifyLoginOpt
                    }
                  >
                    <OTPInput
                      value={otp}
                      onChange={handleOtpChange}
                      otpType="number"
                      autoFocus
                      OTPLength={6}
                      inputStyles={{
                        width: "100%",
                        height: window.innerWidth < 600 ? "2.5rem" : "5rem",
                        marginRight: "0.5rem",
                        fontSize: "2rem",
                        border: "1px solid #ccc",
                        outline: "none",
                        textAlign: "center",
                        backgroundColor: "#f8f7f7",
                        borderRadius: "5px",
                      }}
                    />

                    {otpError && (
                      <Box className={styles.errorBox} sx={{ mt: 2 }}>
                        <Alert type="error" message="Invalid OTP" />
                      </Box>
                    )}

                    <Box className={styles.otpFormFooter}>
                      <button
                        className={styles.verifyBtn}
                        onClick={
                          action === "signup"
                            ? handleVerifySignupOpt
                            : handleVerifyLoginOpt
                        }
                        disabled={otp.length !== 6 || loading}
                      >
                        {loading ? (
                          <CircularProgress size={18} sx={{ color: "#fff" }} />
                        ) : (
                          "Verify"
                        )}
                      </button>

                      <Box className={styles.resendOtp}>
                        <Typography
                          variant="body2"
                          component="p"
                          onClick={handleSendAgain}
                          color={resendCount >= 2 ? "text.disabled" : ""}
                          sx={{
                            cursor: resendCount >= 2 ? "not-allowed" : "pointer",
                          }}
                        >
                          Resend OTP
                        </Typography>

                        <Typography
                          variant="body2"
                          component="p"
                          onClick={handleChangeNumber}
                          sx={{ cursor: "pointer" }}
                        >
                          Change phone number
                        </Typography>
                      </Box>

                      <Box className={styles.terms}>
                        <Typography variant="caption" component="p">
                          By signing up or logging in, you acknowledge and agree
                          to Edoutdoor's{" "}
                          <a
                            href={`${window.location.origin}/terms-of-use`}
                            target="_black"
                          >
                            General Terms
                          </a>{" "}
                          of Use and{" "}
                          <a
                            href={`${window.location.origin}/privacy-policy`}
                            target="_black"
                          >
                            Privacy Policy
                          </a>
                          .
                        </Typography>
                      </Box>
                    </Box>
                  </form>
                </Box>
              ) : (
                <Box className={styles.loginForm}>
                  <Typography variant="h1">
                    {action === "signup"
                      ? "Create an account"
                      : "Login to your account"}
                  </Typography>
                  <Typography variant="body1" mt={0.5}>
                    {action === "signup"
                      ? "Let's get started with your mobile number"
                      : "Enter your mobile number to login"}
                  </Typography>

                  <form
                    onSubmit={
                      action === "signup"
                        ? handleSendSignupOtp
                        : handleSendLoginOtp
                    }
                  >
                    <TextField
                      label="Mobile Number"
                      variant="standard"
                      placeholder="Enter your mobile number"
                      fullWidth
                      value={number}
                      onChange={handleNumberChange}
                      error={numError}
                      helperText={numError ? "Please enter a valid number" : ""}
                      type="number"
                      sx={{ mt: 3 }}
                      inputRef={inputRef}
                    />

                    {numError && (
                      <Box className={styles.errorBox} sx={{ mt: 2 }}>
                        <Alert
                          type="error"
                          message="Please enter a valid number"
                        />
                      </Box>
                    )}
                    {error && (
                      <Box className={styles.errorBox} sx={{ mt: 2 }}>
                        <Stack sx={{ width: "100%" }} spacing={2}>
                          <MuiAlert severity="error">
                            {error === "User already exist."
                              ? "User already exists."
                              : error === "Try Login on portal."
                                ? "Account already exists."
                                : error}{" "}
                            <span
                              className={styles.alreadyHaveAccount}
                              onClick={handleSignUpChange}
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              {action === "signup" ? "Sign In" : "Sign Up"}
                            </span>
                          </MuiAlert>
                        </Stack>
                      </Box>
                    )}
                    <button
                      className={styles.otpBtn}
                      onClick={
                        action === "signup"
                          ? handleSendSignupOtp
                          : handleSendLoginOtp
                      }
                      disabled={number.length !== 10 || loading}
                    >
                      {loading ? (
                        <CircularProgress size={18} sx={{ color: "#fff" }} />
                      ) : (
                        "Get OTP"
                      )}
                    </button>
                    {action === "signup" ? (
                      <Typography
                        variant="body1"
                        sx={{ my: 2, textAlign: "center" }}
                      >
                        Already have an account ?{" "}
                        <span
                          className={styles.alreadyHaveAccount}
                          onClick={handleSignUpChange}
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Sign In
                        </span>
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{ my: 2, textAlign: "center" }}
                      >
                        Don't have an account ?{" "}
                        <span
                          className={styles.alreadyHaveAccount}
                          onClick={handleSignUpChange}
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Sign Up
                        </span>
                      </Typography>
                    )}
                  </form>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={styles.signupImage}>
              <img src="/images/signup.webp" alt="edoutdoors signup" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Signup;
