import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getCollections } from "../../services/actions/collections";
import { BackdropLoading } from "../../components";
import { Container, Box, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const Sitemap = () => {
    const dispatch = useDispatch();
    const { collections, loading: collectionsLoading } = useSelector((state) => state.collections)
    const { experienceTypes, loading: expLoading } = useSelector((state) => state.exp_destination_types)

    const collectionLinks = useMemo(() => {
        return collections?.map((collection) => {
            return {
                name: collection?.name,
                link: `/collections/${collection?.id}`,
            }
        })
    }, [collections])

    const expLinks = useMemo(() => {
        return experienceTypes?.map((exp) => {
            return {
                name: exp?.name,
                link: `/experiences?experience_type=${exp?.name}`,
            }
        })
    }, [experienceTypes])

    const companyLinks = useMemo(() => {
        return [
            {
                name: "Home",
                link: "/",
            },
            {
                name: "Experiences",
                link: "/experiences",
            },
            {
                name: "About Us",
                link: "/about-us",
            },
            {
                name: "Contact Us",
                link: "/contact-us",
            },
            {
                name: "Privacy Policy",
                link: "/privacy-policy",
            },
            {
                name: "Terms of Use",
                link: "/terms-of-use",
            },
            {
                name: "Refunds & Cancellations",
                link: "/refund-policy",
            },
            {
                name: "Sign Up",
                link: "/signup",
            },
        ]
    }, [])

    useEffect(() => {
        if (!collections) dispatch(getCollections())
    }, [dispatch, experienceTypes, collections])


    if (collectionsLoading || expLoading) return (
        <Box sx={{ height: "80vh" }}>
            <BackdropLoading open={true} />
        </Box>
    )

    return (
        <Container px={0}>
            <Typography variant="h1" mt={3} sx={{ fontWeight: 700, fontSize: "40px" }}>
                Sitemap
            </Typography>
            <Grid container spacing={2} mt={3}>
                <Grid item xs={12} md={4}>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Company
                    </Typography>
                    <Box pl={4} mt={2}>
                        <ul>
                            {
                                companyLinks?.map((link, index) => (
                                    <Typography variant="subtitle1" mb={1} key={index} sx={{ textDecoration: "underline" }}>
                                        <li >
                                            <Link to={link?.link}>
                                                {link?.name}
                                            </Link>
                                        </li>
                                    </Typography>
                                ))
                            }
                        </ul>
                    </Box>

                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Collections
                    </Typography>
                    <Box pl={4} mt={2}>
                        <ul>
                            {
                                collectionLinks?.map((link, index) => (
                                    <Typography variant="subtitle1" mb={1} key={index} sx={{ textDecoration: "underline" }}>
                                        <li >
                                            <Link to={link?.link}>
                                                {link?.name}
                                            </Link>
                                        </li>
                                    </Typography>
                                ))
                            }
                        </ul>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Experiences
                    </Typography>
                    <Box pl={4} mt={2}>
                        <ul>
                            {
                                expLinks?.map((link, index) => (
                                    <Typography variant="subtitle1" mb={1} key={index} sx={{ textDecoration: "underline" }}>
                                        <li >
                                            <Link to={link?.link}>
                                                {link?.name}
                                            </Link>
                                        </li>
                                    </Typography>
                                ))
                            }
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Sitemap;