import styles from './ExpCard.module.css'
import { Link } from "react-router-dom"
import {
    Box,
    Skeleton
} from "@mui/material";
import { LazyImage } from "../../components"

export const ExpCardSkeleton = () => {
    return (
        <Box className={styles.card}>
            <Box className={styles.cardImage}>
                <Skeleton variant="rectangular" width={300} height={200} />
            </Box>
            <Box className={styles.cardDetails}>
                <Box className={styles.cardDetailsBottom}>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={100} />
                </Box>
            </Box>
        </Box>
    )

}

const ExpCard = ({ item }) => {

    return (
        <Link to={`/experience/${item?.id}`} className={styles.card}>
            <Box className={styles.cardImage}>
                <LazyImage
                    src={item?.cover_photo}
                    alt={item?.title}
                    width="100%"
                    height="100%"
                />
                <Box className={styles.cardHover}>
                    <p className={styles.cardHoverText}>
                        Duration | {item?.duration === "1 Days" ? item?.duration?.replace("Days", "Day") : item?.duration} <br />
                    </p>
                </Box>
            </Box>
            <Box className={styles.cardDetails}>
                <Box className={styles.cardDetailsBottom}>
                    <h3>
                        {
                            item?.title.length > 40 ?
                                item?.title.slice(0, 40) + "..." : item?.title
                        }
                    </h3>
                    <p className={styles.price}>
                        <span>From</span> <br />
                        ₹{item?.fee}
                    </p>
                </Box>
            </Box>
        </Link>
    )
}

export default ExpCard