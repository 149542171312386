import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'authorization': 'Bearer ' + localStorage.getItem('token')
};

const api = axios.create({
    baseURL: BASE_URL,
    headers: headers
});

export const get = (url) => {
    return api.get(url);
};

export const post = (url, data) => {
    return api.post(url, data);
}

export const put = (url, data) => {
    return api.put(url, data);
}

export const del = (url, data) => {
    return api.delete(url, data);
}