import React, { useState, useCallback, useMemo } from 'react';
import {
    Box,
    Typography,
    Modal,
    Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './CalendarModal.module.css';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const RangeCalendar = ({
    open,
    handleClose,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}) => {
    const minDate = useMemo(() => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        return currentDate;
    }, []);

    const [state, setState] = useState(() => {
        const initialEndDate = new Date(minDate);
        initialEndDate.setDate(initialEndDate.getDate() + 3);
        return [
            {
                startDate: startDate || minDate,
                endDate: endDate || initialEndDate,
                key: 'selection',
            },
        ];
    });

    const handleDateChange = useCallback((item) => {
        setState([item.selection]);
        setStartDate(item?.selection?.startDate);
        setEndDate(item?.selection?.endDate)
    }, [setStartDate, setEndDate]);

    const handleDone = useCallback(() => {
        if (state[0]?.startDate !== null && state[0]?.endDate !== null) {
            setStartDate(state[0]?.startDate);
            setEndDate(state[0]?.endDate);
        }
        handleClose();
    }, [handleClose, state, setStartDate, setEndDate]);

    return (
        <Modal hideBackdrop open={open}>
            <Box className={styles.modalBox}>
                <Box className={styles.modalHeader}>
                    <Typography variant="h5" component="h5">
                        Date range of trip
                    </Typography>
                    <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                </Box>
                <Box className={styles.modalBody}>
                    <DateRange
                        editableDateInputs={true}
                        onChange={handleDateChange}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        minDate={minDate}
                        style={{ width: "95%" }}
                    />
                </Box>
                <Box className={styles.modalButton}>
                    <Button
                        variant="outlined"
                        onClick={handleDone}
                        disabled={state[0]?.startDate === null || state[0]?.endDate === null}
                        sx={{
                            color: "#1D9929",
                            borderColor: "#1D9929",
                            '&:hover': {
                                borderColor: "#1D9929",
                                backgroundColor: "#1D9929",
                                color: "#fff"
                            }
                        }}
                    >
                        Done
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RangeCalendar;
