import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { LazyImage } from "../";

const ExpCard = ({ item }) => {
  return (
    <Card
      component={Link}
      to={`/experience/${item.id}`}
      sx={{
        maxWidth: { xs: 280, sm: 320 },
        minHeight: 385,
        borderRadius: "1.5rem",
        borderColor: "rgb(232, 232, 232)",
        boxShadow: { xs: "rgba(28, 28, 28, 0.2) 0 0 5px", sm: "rgba(28, 28, 28, 0.12) 0px 0.2rem 1.2rem" },
        display: "block",
        mx: { xs: 0.5 },
        '&:hover': {
          '& .MuiCardMedia-root img': {
            transition: "all 0.3s",
            transform: 'scale(1.05)',
          },
        },
      }}
    >
      <CardMedia component="div">
        <LazyImage
          src={item.cover_photo}
          alt={item.title}
          height={window.innerWidth > 600 ? "180" : "160"}
          width="100%"
        />
      </CardMedia>
      <CardContent sx={{ padding: "8px 16px" }}>
        <Typography variant="body1" color="text.secondary">
          {
            item?.experience_type
          }
        </Typography>
        <Typography gutterBottom variant="h6" component="div">
          {
            item.title.length > 35 ?
              item.title.slice(0, 35) + "..." : item.title
          }
        </Typography>

        <Typography gutterBottom variant="body2" color="text.secondary">
          {
            item.highlight.length > 70 ?
              item.highlight.slice(0, 70) + "..." : item.highlight
          }
        </Typography>

        <Typography variant="subtitle1" color="text.secondary" mt={1}>
          {item?.duration === "1 Days" ? item?.duration?.replace("Days", "Day") : item?.duration}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default ExpCard