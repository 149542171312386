import styles from './Footer.module.css'
import {
  Container,
  Box,
  Grid,
  Typography,
  SwipeableDrawer,
} from "@mui/material";

import {
  Facebook,
  Instagram,
  LinkedIn,
} from "@mui/icons-material";

import { useState } from 'react';
import { Link } from 'react-router-dom'
import { LazyImage } from '../';

const Footer = () => {

  const [openHelp, setOpenHelp] = useState(false)
  const handleOpenHelp = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenHelp(open);
  };

  const HelpSlider = () => {
    return (
      <SwipeableDrawer
        anchor="right"
        open={openHelp}
        onClose={handleOpenHelp(false)}
        onOpen={handleOpenHelp(true)}
        sx={{
          '& .MuiDrawer-paper':
          {
            height: "30vh",
            width: "100%",
            maxWidth: { sm: 380 },
            position: "absolute",
            top: { sm: "1%" },
            right: { sm: "1%" },
            boxShadow: "0 0 10px",
            borderRadius: { sm: "10px" }
          },
        }}
      >
        <Box className={styles.helpSlider}>
          <Box className={styles.helpSliderBox}>
            <LazyImage
              src="/mobile-logo.png"
              alt="EdOutdoors"
            />

            <Box className={styles.helpSliderContent}>
              <Typography variant="h5" component="h5">
                Need Help?
              </Typography>
              <Typography variant="h6" align='center'>
                Reach out to help centre at +91 88840 45670 <br />
                or email us at <a href="mailto:hello@edoutdoors.in"> hello@edoutdoors.in</a>.
              </Typography>
            </Box>
            <button type="button" onClick={handleOpenHelp(false)}>
              Okay
            </button>
          </Box>
        </Box>
      </SwipeableDrawer>
    )
  }

  return (
    <footer className={styles.footer}>
      <Container className={styles.footerContainer}>
        <Box className={styles.footerTop}>
          <Grid container spacing={2}>

            <Grid item lg={7} md={7} xs={12}>
              <Box className={styles.footerLogoBox}>
                <Box className={styles.footerLogo}>
                  <LazyImage
                    src="/logo.webp"
                    alt="EdOutdoors"
                  />
                </Box>
                <Box className={styles.footerLogoText}>
                  <Box className={styles.footerLogoTextTop}>
                    <Typography variant="p" component="p">
                      Helping children embrace learning in the outdoors.
                    </Typography>
                    {/* <Typography variant="p" component="p">
                      Join forces with us and together, let's make the world a child's classroom.
                    </Typography> */}
                  </Box>
                  <Box className={styles.footerLogoTextBottom}>
                    <Typography variant="body1" component="p">
                      GlobalShaala Travel
                    </Typography>
                    <Typography variant="p" component="p">
                      19, Santosh Vihar Phase 1, Jakkur, Bangalore, KA 560092
                    </Typography>
                    <Typography variant="p" component="p">
                      Contact: +91 88840 45670
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item lg={5} md={5} xs={12}>
              <Box className={styles.footerLinksWrapper}>
                <Box className={styles.footerLinks}>
                  <Box>
                    <Typography variant="h6" component="h6">
                      EdOutdoors
                    </Typography>

                    <Typography variant="p" component="p">
                      <Link to="/about-us">About Us</Link>
                    </Typography>

                    <Typography variant="p" component="p">
                      <a
                        href="https://blog.edoutdoors.in"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Blog
                      </a>
                    </Typography>

                    <Typography variant="p" component="p">
                      <Link to="/contact-us">Contact Us</Link>
                    </Typography>

                    <Typography
                      variant="p"
                      component="p"
                      onClick={handleOpenHelp(true)}
                      sx={{ cursor: "pointer" }}
                    >
                      Help
                    </Typography>
                    <Typography variant="p" component="p">
                      <a
                        href="https://www.linkedin.com/company/edoutdoors/jobs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Careers
                      </a>
                    </Typography>
                    <Typography variant="p" component="p">
                      <Link to="/sitemap">Sitemap</Link>
                    </Typography>
                  </Box>
                </Box>
                <Box className={styles.footerLinks}>
                  <Box>
                    <Typography variant="h6" component="h6">
                      Partners
                    </Typography>

                    <Typography variant="p" component="p">
                      <a href=" https://forms.gle/sbVPkx4iXTDC5WdT8" target="_blank" rel="noopener noreferrer">List with EdOutdoors</a>
                    </Typography>
                    <Typography variant="p" component="p">
                      <a href="https://admin.edoutdoors.in/" target='_black' rel="noopener noreferrer">Outdoor Partner Admin</a>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className={styles.footerBottom}>
          <Box className={styles.footerBottomLeft}>
            <Typography variant="p" component="p">
              © EdOutdoors
            </Typography>
            <Typography variant="p" component="p">
              <Link to="/privacy-policy">
                Privacy Policy
              </Link>
            </Typography>
            <Typography variant="p" component="p">
              <Link to="/terms-of-use">
                Terms of Use
              </Link>
            </Typography>
            <Typography variant="p" component="p">
              <Link to="/refund-policy">
                Refunds & Cancellations
              </Link>
            </Typography>
          </Box>

          <Box className={styles.footerBottomRight}>
            <Typography href='https://www.facebook.com/edoutdoorsin' target="_blank" variant="a" component="a">
              <Facebook />
            </Typography>
            <Typography href='https://www.linkedin.com/company/edoutdoors' target="_blank" variant="a" component="a">
              <LinkedIn />
            </Typography>
            <Typography href='https://www.instagram.com/edoutdoors_official' target="_blank" variant="a" component="a">
              <Instagram />
            </Typography>
          </Box>
        </Box>
      </Container>
      <HelpSlider />
    </footer>

  )
}

export default Footer