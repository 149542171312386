export const GET_EXPERIENCE_TYPES = 'GET_EXPERIENCE_TYPES';
export const GET_EXPERIENCE_TYPES_SUCCESS = 'GET_EXPERIENCE_TYPES_SUCCESS';
export const GET_EXPERIENCE_TYPES_FAILURE = 'GET_EXPERIENCE_TYPES_FAILURE';

export const getExperienceTypes = () => ({
    type: GET_EXPERIENCE_TYPES,
});

export const GET_DESTINATION_TYPES = 'GET_DESTINATION_TYPES';
export const GET_DESTINATION_TYPES_SUCCESS = 'GET_DESTINATION_TYPES_SUCCESS';
export const GET_DESTINATION_TYPES_FAILURE = 'GET_DESTINATION_TYPES_FAILURE';

export const getDestinationTypes = () => ({
    type: GET_DESTINATION_TYPES,
});