import Card from "./Card";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Box, Container, Skeleton, Grid } from "@mui/material";
import styles from "./styles.module.css";
import { useMemo, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Banner } from "../../components";
import {
    getExperiences,
    getUpcomingExps,
} from "../../services/actions/experiences";

const Loader = () => {
    return (
        <Box>
            <Container>
                <Typography
                    variant="h1"
                    sx={{
                        mt: 5,
                        textTransform: "capitalize",
                        fontWeight: 700,
                        fontSize: { xs: "22px", md: "24px" },
                    }}
                >
                    <Skeleton variant="text" width={200} />
                </Typography>
                <Typography variant="subtitle2" mb={5} mt={1} ml={0.5}>
                    <Skeleton variant="text" width={150} />
                </Typography>
                <Box className={styles.listingWrapper}>
                    {[1, 2, 3, 4, 5, 6].map((_, index) => (
                        <Box className={styles.card} key={index}>
                            <Grid container sx={{ height: "100%" }}>
                                <Grid item xs={5} md={4}>
                                    <Box className={styles.left}>
                                        <Skeleton variant="rectangular" height="100%" />
                                    </Box>
                                </Grid>
                                <Grid item xs={7} md={8}>
                                    <Box className={styles.right}>
                                        <Typography variant="h6" mb={0}>
                                            <Skeleton variant="text" width={200} />
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary">
                                            <Skeleton variant="text" width={150} />
                                        </Typography>
                                        {
                                            window.innerWidth > 600 && (
                                                <Box className={styles.highlight}>
                                                    <Typography variant="body1" color="text.secondary">
                                                        <Skeleton variant="text" width="100%" />
                                                    </Typography>
                                                    <Typography variant="body1" color="text.secondary">
                                                        <Skeleton variant="text" width="90%" />
                                                    </Typography>
                                                    <Typography variant="body1" color="text.secondary">
                                                        <Skeleton variant="text" width="80%" />
                                                    </Typography>
                                                    <Typography variant="body1" color="text.secondary">
                                                        <Skeleton variant="text" width="70%" />
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                        <Box className={styles.details}>
                                            <Box className={styles.detail}>
                                                <Skeleton variant="rounded" width={20} />
                                                <Skeleton variant="text" width={50} />
                                            </Box>
                                            <Box className={styles.detail}>
                                                <Skeleton variant="rounded" width={20} />
                                                <Skeleton variant="text" width={50} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </Box>

                <Box sx={{ my: 8 }}>
                    <Skeleton variant="rectangular" height={280} />
                </Box>
            </Container>
        </Box>
    );
};

const UpcomingExps = () => {
    const dispatch = useDispatch();
    const { upcomingExps, experiences } = useSelector(
        (state) => state.experiences
    );

    const sortByDate = useCallback(
        (a, b) => new Date(a.start_date) - new Date(b.start_date),
        []
    );

    const data = useMemo(() => {
        return upcomingExps
            ?.map((item) => {
                const matchingExp = experiences?.find(
                    (exp) => exp?.id === item?.experience?.id
                );
                const updatedExperience = {
                    ...item?.experience,
                    cover_photo: matchingExp?.cover_photo,
                    experience_type: matchingExp?.experience_type,
                    highlight: matchingExp?.highlight,
                };
                return {
                    ...item,
                    experience: updatedExperience,
                };
            })
            ?.sort(sortByDate);
    }, [experiences, upcomingExps, sortByDate]);

    useEffect(() => {
        if (!experiences) dispatch(getExperiences());
        if (!upcomingExps) dispatch(getUpcomingExps());
        //eslint-disable-next-line
    }, [dispatch]);

    return (
        <>
            <Helmet>
                <title>Upcoming Experiences | EdOutdoors</title>
                <meta name="description" content="Upcoming Experiences | EdOutdoors" />
                <meta property="og:title" content="Upcoming Experiences | EdOutdoors" />
                <meta
                    property="og:description"
                    content="Upcoming Experiences | EdOutdoors"
                />
                <link rel="canonical" href={`https://www.edoutdoors.in/upcoming`} />
            </Helmet>
            {data?.length > 0 ? (
                <Box>
                    <Container>
                        <Typography
                            variant="h1"
                            sx={{
                                mt: 5,
                                textTransform: "capitalize",
                                fontWeight: 700,
                                fontSize: { xs: "22px", md: "24px" },
                            }}
                        >
                            Upcoming Experiences
                        </Typography>
                        <Typography variant="subtitle2" mb={5} mt={1} ml={0.5}>
                            Showing {data?.length} results
                        </Typography>
                        <Box className={styles.listingWrapper}>
                            {data?.map((item) => (
                                <Card item={item} key={item.id} />
                            ))}
                        </Box>
                    </Container>

                    <Box sx={{ my: 10 }}>
                        <Banner />
                    </Box>
                </Box>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default UpcomingExps;
