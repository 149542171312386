export const testimonials = [
    {
        id: 1,
        img: "/images/testimonial/1.webp",
        name: "Sindhu R",
        parent: "Parent (Grade 6)",
        desc: "The team handled the whole process extremely smoothly - may it be daily communication or arranging the kids' daily finances. By the end of the trip, the kids came back with a plethora of great experiences. Overall a great 360° experience."
    },
    {
        id: 2,
        img: "/images/testimonial/2.webp",
        name: "Bridget A",
        parent: "Parent (Grade 7)",
        desc: "Sent my son on his own for the first time. It was worth it. The team took care of each and every aspect. Children came back with great memories and wanting to go again. The exposure to the life of people, food of the place they visited was great. All safety was taken care off and parents were updated regularly."
    },
    {
        id: 3,
        img: "/images/testimonial/3.webp",
        name: "Prakash J",
        parent: "Parent (Grade 9)",
        desc: "Sending my daughter on an industry tour was one of the best decisions I took. She is not a big fan of science but an exposure like this helped her gain curiosity in a few topics. I think a lot of parents miss out on seeing their child's true interests because they don't experience practical learning."
    },
    {
        id: 4,
        img: "/images/testimonial/4.webp",
        name: "Neeraj S",
        parent: "Student (Grade 11)",
        desc: "One of the most well planned and fun educational trips I've been on. The learning part of the trip was disguised with adventure and fun!"
    },
    {
        id: 5,
        img: "/images/testimonial/5.webp",
        name: "Chiraag A",
        parent: "Parent (Grade 9)",
        desc: "Students get very good exposure to general knowledge and its application in life and are taught ways of dealing with problems in the long run, in a very fun manner which increases efficiency of learning in comparison with school learning. A definite 5."
    },
]