
import styles from "./style.module.css"
import {
    Box,
    Typography,
    SwipeableDrawer,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const CancellationPolicySlider = ({ open, setOpen, heading, description }) => {
    return (
        <div>
            <SwipeableDrawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                sx={{
                    '& .MuiDrawer-paper':
                    {
                        height: "fit-content",
                        maxWidth: { sm: 400, md: 550},
                        position: "absolute",
                        top: { sm: "1%" },
                        right: { sm: "1%" },
                        boxShadow: "0 0 10px",
                        borderRadius: { sm: "10px" }
                    },
                }}
            >
                <Box className={styles.Slider}>
                    <Box className={styles.SliderHeader}>
                        <Typography variant="h6" component="h6" mb={2}>
                            {heading}
                        </Typography>
                        <CloseIcon sx={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                    </Box>
                    <Box className={styles.SliderBody}>
                        <Box pl={4}> 
                            {/* html */}
                            <div dangerouslySetInnerHTML={{ __html: description }} />
                        </Box>
                    </Box>
                </Box>
            </SwipeableDrawer>
        </div>
    )
}

export default CancellationPolicySlider