import { useState, useRef, useEffect } from 'react';
import {
    Box,
    Typography,
    Modal,
    CircularProgress,
    Alert as MuiAlert,
    Stack,
    TextField,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AuthModal.module.css';
import OTPInput from "otp-input-react";
import { Alert } from "../"
import { authApi } from "../../services/api"

const AuthModal = ({ btn, show, closeAuth }) => {
    const [open, setOpen] = useState(false);
    const [openOtp, setOpenOtp] = useState(false);
    const [number, setNumber] = useState("");
    const [numError, setNumError] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [action, setAction] = useState("signup")
    const [resendCount, setResendCount] = useState(0)

    const inputRef = useRef(null);
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setOpenOtp(false);
        setNumber("");
    };

    const handleShowOtpAlert = () => {
        setOtpError(true)
        setTimeout(() => {
            setOtpError(false)
        }, 6000)
    }

    const handleSignUpChange = () => {
        action === "signup" ? setAction("signin") : setAction("signup")
        setError("")
        setOtpError(false)
        setNumError(false)
    }

    // Login
    const handleSendLoginOtp = async (e) => {
        e.preventDefault()
        setLoading(true)
        setResendCount(0)
        try {
            const response = await authApi.sendLoginOtp(number)
            if (response.data.success) {
                setOpenOtp(true)
            } else {
                setError(response.data.message)
            }
        } catch (error) {
            setNumError(true)
        } finally {
            setLoading(false)
        }
    }

    // Signup
    const handleSendSignupOtp = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await authApi.sendSignupOtp(number)
            if (response.data.success) {
                setOpenOtp(true)
            } else {
                setError(response.data.message)
            }
        } catch (error) {
            setNumError(true)
        } finally {
            setLoading(false)
        }
    }

    const EnterOtpModal = (props) => {
        const [otp, setOtp] = useState("");

        const handleOtpChange = (otp) => {
            setOtp(otp)
        }

        // Login verify
        const handleVerifyLoginOpt = async (e) => {
            e.preventDefault()
            if (otp.length !== 6) return
            setLoading(true)
            try {
                const response = await authApi.verifyLoginOtp({
                    mobile: number,
                    otp
                })
                if (response.data.token) {
                    localStorage.setItem("token", response.data.token)
                    window.location.reload()
                } else {
                    handleShowOtpAlert()
                }
            } catch (error) {
                handleShowOtpAlert()
            } finally {
                setLoading(false)
            }

        }

        // Signup verify
        const handleVerifySignupOpt = async (e) => {
            e.preventDefault()
            if (otp.length !== 6) return
            setLoading(true)
            try {
                const response = await authApi.verifySignupOtp({
                    mobile: number,
                    otp
                })
                if (response.data.token) {
                    localStorage.setItem("token", response.data.token)
                    window.location.reload()
                } else {
                    handleShowOtpAlert()
                }
            } catch (error) {
                handleShowOtpAlert()
            } finally {
                setLoading(false)
            }

        }

        const handleChangeNumber = () => {
            setOtp("")
            setOpenOtp(false)
        }

        const handleSendAgain = async () => {
            if (resendCount >= 2) return
            setLoading(true)
            try {
                const response = await authApi.resendOtp(number)
                if (response.data.success) {
                    setOpenOtp(true)
                } else {
                    setError(response.data.message)
                    setTimeout(() => {
                        setError("")
                    }, 3000)
                }
            } catch (error) {
                setNumError(true)
            } finally {
                setResendCount(resendCount + 1)
                setLoading(false)
            }
        }

        return (
            <div>
                <Modal hideBackdrop open={props.open}>
                    <Box className={styles.otpModal}>
                        <Box className={styles.otpModalHead}>
                            <IconButton onClick={closeAuth ? closeAuth : handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box className={styles.otpModalBody}>

                            <Box className={styles.otpModalBodyLeft}>
                                <img
                                    src="/images/signup.webp"
                                    alt="EdOutdoors"
                                />
                            </Box>

                            <Box className={styles.otpModalBodyRight}>
                                <form onSubmit={action === "signup" ? handleVerifySignupOpt : handleVerifyLoginOpt}>
                                    <Box className={styles.leftHead}>
                                        <Typography variant="h5" component="h5">
                                            Enter OTP
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            We have sent a 6 digit OTP to {props.number}
                                        </Typography>
                                    </Box>

                                    <Box className={styles.leftBody}>
                                        <Box className={styles.OtpInput}>
                                            <OTPInput
                                                value={otp}
                                                onChange={handleOtpChange}
                                                otpType="number"
                                                autoFocus
                                                OTPLength={6}
                                                inputStyles={{
                                                    width: "2rem",
                                                    height: "2.5rem",
                                                    margin: "0 0.5rem",
                                                    fontSize: "2rem",
                                                    border: "1px solid #ccc",
                                                    outline: "none",
                                                    textAlign: "center",
                                                    backgroundColor: "#f8f7f7",
                                                    borderRadius: "5px",
                                                }}
                                            />
                                        </Box>

                                        {
                                            otpError && (
                                                <Box className={styles.errorBox} sx={{ mt: 2 }}>
                                                    <Alert
                                                        type="error"
                                                        message="Invalid OTP"
                                                    />
                                                </Box>
                                            )
                                        }

                                    </Box>

                                    <Box className={styles.leftFooter}>
                                        <button
                                            className={styles.verifyBtn}
                                            onClick={action === "signup" ? handleVerifySignupOpt : handleVerifyLoginOpt}
                                            disabled={otp.length !== 6 || loading}
                                        >
                                            {
                                                loading ? (
                                                    <CircularProgress size="25px" sx={{ color: "#fff" }} />
                                                ) : (
                                                    "Verify"
                                                )
                                            }
                                        </button>

                                        <Box className={styles.resendOtp}>
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                onClick={handleSendAgain}
                                                color={resendCount >= 2 ? "text.disabled" : ""}
                                            >
                                                Resend OTP
                                            </Typography>

                                            <Typography
                                                variant="body2"
                                                component="p"
                                                onClick={handleChangeNumber}
                                            >
                                                Change phone number
                                            </Typography>
                                        </Box>

                                        <Box className={styles.terms}>
                                            <Typography variant="caption" component="p">
                                                By signing up or logging in, you acknowledge and agree to Edoutdoor's <a href={`${window.location.origin}/terms-of-use`} target='_black'>General Terms</a> of Use and <a href={`${window.location.origin}/privacy-policy`} target='_black'>Privacy Policy</a>.
                                            </Typography>
                                        </Box>
                                    </Box>

                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    // setting the max length of the input field to 10
    const handleNumberChange = (object) => {
        if (object.target.value.length > 10) {
            object.target.value = object.target.value.slice(0, 10)
            setNumber(object.target.value)
        } else {
            setNumber(object.target.value)
        }
    }

    useEffect(() => {
        if (open || show) {
            const timeoutId = setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 0);

            return () => clearTimeout(timeoutId);
        }
    }, [open, show, action]);

    return (
        <div>
            {
                btn && (
                    <button onClick={handleOpen} className={styles.signInBtn}>
                        Sign Up
                    </button>
                )
            }
            <Modal open={show ? show : open}>
                <Box className={styles.loginModal}>
                    <Box className={styles.loginModalHead}>
                        <IconButton onClick={closeAuth ? closeAuth : handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box className={styles.loginModalBody}>
                        <Box className={styles.loginModalBodyLeft}>
                            <img
                                src="/images/signup.webp"
                                alt="EdOutdoors"
                            />
                        </Box>

                        <Box className={styles.loginModalBodyRight}>
                            <Box className={styles.loginModalBodyRightTop}>
                                <img src="/logo.webp" alt="EdOutdoors" />
                                <Typography variant="h6">
                                    {
                                        action === "signup" ? (
                                            "Create an account"
                                        ) : (
                                            "Login to your account"
                                        )
                                    }
                                </Typography>
                                <Typography variant="body2" mt={0.5}>
                                    {
                                        action === "signup" ? (
                                            "Let's get started with your mobile number"
                                        ) : (
                                            "Enter your mobile number to login"
                                        )
                                    }
                                </Typography>
                            </Box>

                            <form onSubmit={action === "signup" ? handleSendSignupOtp : handleSendLoginOtp}>
                                <TextField
                                    label="Mobile Number"
                                    variant='standard'
                                    placeholder="Enter your mobile number"
                                    fullWidth
                                    value={number}
                                    onChange={handleNumberChange}
                                    error={numError}
                                    helperText={numError ? "Please enter a valid number" : ""}
                                    type='number'
                                    sx={{ mt: 2 }}
                                    inputRef={inputRef}
                                />

                                {
                                    numError && (
                                        <Box className={styles.errorBox} sx={{ mt: 2 }}>
                                            <Alert
                                                type="error"
                                                message="Please enter a valid number"
                                            />
                                        </Box>
                                    )
                                }
                                {
                                    error && (
                                        <Box className={styles.errorBox} sx={{ mt: 2 }}>
                                            <Stack sx={{ width: '100%' }} spacing={2}>
                                                <MuiAlert severity="error">
                                                    {error === "User already exist." ?
                                                        "User already exists." :
                                                        error === "Try Login on portal." ?
                                                            "Account already exists." :
                                                            error} {" "}
                                                    <span
                                                        className={styles.alreadyHaveAccount}
                                                        onClick={handleSignUpChange}
                                                        style={{ cursor: "pointer", textDecoration: "underline" }}
                                                    >
                                                        {
                                                            action === "signup" ? "Sign In" : "Sign Up"
                                                        }
                                                    </span>
                                                </MuiAlert>

                                            </Stack>
                                        </Box>
                                    )
                                }
                                <button
                                    className={styles.otpBtn}
                                    onClick={action === "signup" ? handleSendSignupOtp : handleSendLoginOtp}
                                    disabled={number.length !== 10 || loading}
                                >
                                    {
                                        loading ? (
                                            "Sending"
                                        ) : (
                                            "Send OTP"
                                        )
                                    }
                                </button>
                                {
                                    action === "signup" ? (
                                        <Typography variant="body1" sx={{ my: 2, textAlign: "center" }}>
                                            Already have an account ? {" "}
                                            <span
                                                className={styles.alreadyHaveAccount}
                                                onClick={handleSignUpChange}
                                                style={{ fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                                            >
                                                Sign In
                                            </span>
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1" sx={{ my: 2, textAlign: "center" }}>
                                            Don't have an account ? {" "}
                                            <span
                                                className={styles.alreadyHaveAccount}
                                                onClick={handleSignUpChange}
                                                style={{ fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                                            >
                                                Sign Up
                                            </span>
                                        </Typography>
                                    )
                                }
                            </form>
                        </Box>
                        <EnterOtpModal open={openOtp} number={number} />
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default AuthModal