
import {
    Box,
    Container,
    Typography,
    Grid,
    Skeleton
} from "@mui/material";
import { paymentApi } from "../../services/api";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "./Thankyou.module.css";
import { BackdropLoading } from "../../components";
import moengage from "@moengage/web-sdk";
import { useSelector } from "react-redux";
import { Phone, Email } from "@mui/icons-material";

const PageLoader = () => {
    return (
        <Box className={styles.thankyouBox}>
            <BackdropLoading open={true} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Skeleton variant="rectangular" width="100%" height="50vh" />
                    <Box sx={{ mt: 1 }}>
                        <Skeleton variant="text" width="20%" height={30} />
                        <Skeleton variant="text" width="60%" height={50} />
                        <Skeleton variant="text" width="60%" height={100} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton variant="rectangular" width="100%" height="50vh" />
                    <Box sx={{ mt: 3 }}>
                        <Skeleton variant="rectangular" width="100%" height="50vh" />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const ThankYouPage = () => {
    const orderId = useLocation().search.split("=")[1]
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    const { child } = useSelector(state => state.auth.user)
    const [details, setDetails] = useState({})
    const [redirectUrl, setRedirectUrl] = useState("")

    useEffect(() => {
        (async function () {
            try {
                const { data } = await paymentApi.getPaymentStatus(orderId)
                if (data?.data?.order_status === "PAID") {
                    // Getting Experience Details from Local Storage ( Storaed during the booking of experience )
                    const expDetails = JSON.parse(localStorage.getItem("experience_booked"))
                    setDetails(() => {
                        return {
                            expTitle: expDetails?.expTitle,
                            expTourDate: expDetails?.expTourDate,
                            expTourTime: expDetails?.expTourTime,
                            partner: expDetails?.partner
                        }
                    })
                    // Pushing Moengage Event
                    moengage.track_event("experience_booked", {
                        child_first_name: child.first_name,
                        email_parent1: child.parent_email,
                        experience_title: expDetails?.expTitle,
                        experience_tour_date: expDetails?.expTourDate,
                        experience_tour_time: expDetails?.expTourTime,
                    });
                    setSuccess(true)
                } else {
                    setSuccess(false)
                    setRedirectUrl(localStorage.getItem("failed_redirect"))
                }
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
                // Removing Experience Details and failed_redirect from Local Storage
                setTimeout(() => {
                    localStorage.removeItem("experience_booked")
                    localStorage.removeItem("failed_redirect")
                }, 5000)
            }
        })()
        // eslint-disable-next-line
    }, [])

    if (loading) return <PageLoader />

    return (
        success ? (
            <Container className={styles.thankyouBox}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7} >
                        <Box className={styles.detailsBox}>
                            {
                                window.innerWidth > 768 ? (
                                    <Box className={styles.logo}>
                                        <img src="/logo.webp" alt="edvolve" height="100%" />
                                    </Box>
                                ) : (
                                    <Box className={styles.logo}>
                                        <img src="/images/thank.gif" alt="edoutdoors" />
                                    </Box>
                                )
                            }

                            <Box className={styles.message}>
                                <Box className={styles.messageTop}>
                                    <Typography variant="h6" component="h6">
                                        Reservation complete for {details?.expTitle}!
                                    </Typography>
                                    <Typography variant="h4" component="h4">
                                        What to expect next?
                                    </Typography>
                                </Box>

                                <Box className={styles.messageBottom}>
                                    <Box className={styles.messageText}>
                                        <Typography variant="p" component="p">
                                            The outdoor partner, {details?.partner?.name}, has received your booking for {details?.expTitle} happening on {details?.expTourDate}, starting at {details?.expTourTime}. In a few hours, you will receive further communication from them directly on preparing for the trip.
                                        </Typography>
                                    </Box>

                                    <Box className={styles.messageTextBottom}>
                                        <Typography variant="p" component="p">
                                            Should you need assistance, here are their contact details:
                                        </Typography>
                                        <Box className={styles.contact}>
                                            <Typography variant="p" component="p">
                                                <Phone /> {details?.partner?.user?.mobile_number}
                                            </Typography>
                                            <Typography variant="p" component="p">
                                                <Email /> {details?.partner?.email}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box className={styles.btn}>
                                        <button
                                            type="button"
                                            onClick={() => navigate("/", { replace: true })}
                                        >
                                            Go To Homepage
                                        </button>
                                    </Box>

                                </Box>
                            </Box>


                        </Box>
                    </Grid>
                    {
                        window.innerWidth > 768 && (
                            <Grid item xs={12} md={5}>
                                <Box className={styles.thankImage}>
                                    <img src="/images/thank.gif" alt="edoutdoors" />
                                </Box>
                            </Grid>
                        )
                    }

                </Grid>
            </Container>
        ) : (
            <Container className={styles.paymentError}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box className={styles.errorbox}>
                            <Typography variant="h3">
                                Payment Error
                            </Typography>
                            <Typography variant="body1" component="p">
                                It appears that an error has occurred! In the event that money was deducted from your account, it will be refunded within 4-7 days. We recommend attempting the payment once more. Call +91 90434 08421 for help.
                            </Typography>
                            <Box className={styles.errorButtons}>
                                <button
                                    type="button"
                                    onClick={() => navigate(redirectUrl, { replace: true })}
                                >
                                    Retry
                                </button>

                                <button
                                    type="button"
                                    onClick={() => navigate("/experiences", { replace: true })}
                                >
                                    Go to Experiences
                                </button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className={styles.errorImage}>
                            <img src="/images/payError.webp" alt="edoutdoors" />
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        )
    );
};

export default ThankYouPage;