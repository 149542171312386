import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Container
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { faqs } from "../../assets"

const Faqs = () => {
    return (
        <Container sx={{ py: 3 }}>
            <Typography sx={{ fontSize: { xs: "20px", sm: "30px" }, fontWeight: "bold", mb: 2 }}>
                Frequently Asked Questions
            </Typography>
            {
                faqs?.map((faq, index) => (
                    <Accordion
                        defaultExpanded={index === 0 ? true : false}
                        key={index}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography sx={{ fontSize: { xs: "16px", sm: "20px" } }}>
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Typography sx={{ fontSize: { xs: "14px", sm: "17px", } }}>
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </Container>
    )
}

export default Faqs