export const faqs = [
    {
        question: "Is transport arranged ?",
        answer: "Yes, safe transport is arranged for all experiences by the outdoor partner of the experience."
    },
    {
        question: "Who will accompany the group from your side ?",
        answer: "Outdoor partners ensure there's at least 1 adult coordinator for every 15 kids."
    },
    {
        question: "Can my child come with his/her friends/sublings ?",
        answer: "Sure! You can book for multiple participants after booking for your child. We'll share a link separately."
    },
    {
        question: "Do you arrange accommodation for multi-day programs ?",
        answer: "Yes, accommodation facilities are shortlisted after thorough inspection of properties at the locations. The type of accommodation depends on the location and the learning experience planned."
    },
    {
        question: "I have more questions on this experience. How can I reach you ?",
        answer: "Just chat with us by clicking on the chat icon on the bottom right."
    },
]