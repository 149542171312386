import { useState, useEffect } from 'react';

function AnimatedText() {
  const words = ['animate', 'change', 'shift'];
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setWordIndex((index) => (index + 1) % words.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [words.length]);

  return (
    <div className="animated-text-container">
      <h4 className="animated-text" style={{fontSize:"3rem", color:"white", textAlign:"center"}}>
        I want to <span className="word-change" style={{backgroundColor:"red", padding:"0 20px"}}>{words[wordIndex]}</span> this text!
      </h4>
    </div>
  );
}

export default AnimatedText;
