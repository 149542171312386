import styles from "./SchoolTripModal.module.css"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    Slide,
    IconButton,
    Grid,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Input,
    Checkbox,
    ListItemText,
    TextField,
    Button,
    CircularProgress,
    FormHelperText,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { forwardRef } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from "react"
import { getGrades } from '../../services/actions/grades'
import { RangeCalendar, SuccessAlert } from "../../components"
import moment from "moment"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { schoolTripApi } from "../../services/api"

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BATCH_SIZE_OPTIONS = ["5-20", "20-30", "30-40", "40+"]

const SchoolTripModal = ({ open, handleClose, expId, partnerName }) => {
    const dispatch = useDispatch()
    const { grades } = useSelector((state) => state.grades)
    const [showCalendar, setShowCalendar] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const [schoolTripData, setSchoolTripData] = useState({
        experience_id: expId,
        batch_size: "",
        school_name: "",
        contact_name: "",
        email: "",
        contact_number: "",
        alternate_number: "",
        start_date: "",
        end_date: "",
        grades: []
    })

    const handleDetailsChange = (e) => {
        const { name, value } = e.target;
        setSchoolTripData({
            ...schoolTripData,
            [name]: value,
        })
    }

    const handleContactNumChange = (object) => {
        if (object.target.value.length > 10) {
            object.target.value = object.target.value.slice(0, 10)
            setSchoolTripData((prev) => {
                return {
                    ...prev,
                    contact_number: object.target.value
                }
            })
        } else {
            setSchoolTripData((prev) => {
                return {
                    ...prev,
                    contact_number: object.target.value
                }
            })
        }
    }

    const handleAltNumChange = (object) => {
        if (object.target.value.length > 10) {
            object.target.value = object.target.value.slice(0, 10)
            setSchoolTripData((prev) => {
                return {
                    ...prev,
                    alternate_number: object.target.value
                }
            })
        } else {
            setSchoolTripData((prev) => {
                return {
                    ...prev,
                    alternate_number: object.target.value
                }
            })
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const handleSubmit = async () => {
        if (requiredFields.some(item => !schoolTripData[item]) || !validateEmail(schoolTripData.email)) {
            setError(true)
            return
        }
        setLoading(true)
        try {
            const data = {
                ...schoolTripData,
                start_date: moment(schoolTripData.start_date).format('YYYY-MM-DD'),
                end_date: moment(schoolTripData.end_date).format('YYYY-MM-DD'),
            }
            const response = await schoolTripApi.createRequest(data)
            if (response.data.success) {
                setShowSuccess(true)
            } else {
                throw new Error(response.data.message)
            }

        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }


    const requiredFields = ["batch_size", "school_name", "contact_name", "email", "contact_number", "grades"]

    useEffect(() => {
        if (!grades) {
            dispatch(getGrades())
        }
    }, [grades, dispatch])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            sx={{
                '& .MuiDialog-paper': {
                    margin: 1,
                    width: '100%',
                    pb: 5,
                    borderRadius: "20px",
                    opacity: showSuccess ? 0 : 1,
                },

            }}
        >
            <DialogTitle>
                <Box className={styles.header}>
                    <Typography variant="h5">
                        Booking for a school trip?
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Typography variant="subtitle2" color="text.secondary" component="div" sx={{ maxWidth: "90%" }} >
                    Please share the below details. We'll contact you within a few hours.
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <Grid container spacing={2}>

                    {/* Batch Size */}
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth
                            required
                            variant="standard"
                            error={error && !schoolTripData.batch_size}
                        >
                            <InputLabel>Batch size</InputLabel>
                            <Select
                                value={schoolTripData.batch_size}
                                label="Batch size"
                                onChange={handleDetailsChange}
                                name="batch_size"
                            >
                                {
                                    BATCH_SIZE_OPTIONS.map(item => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            {
                                error && !schoolTripData.batch_size ? (
                                    <FormHelperText>
                                        This field is required
                                    </FormHelperText>
                                ) : null
                            }
                        </FormControl>
                    </Grid>

                    {/* Grades  */}
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth
                            required
                            variant="standard"
                            error={error && !schoolTripData.grades.length}
                        >
                            <InputLabel>Grades</InputLabel>
                            <Select
                                multiple
                                value={schoolTripData.grades}
                                label="Grades"
                                onChange={(e) => {
                                    const selectedOptions = e.target.value;
                                    setSchoolTripData(prev => ({ ...prev, grades: selectedOptions }))
                                }}

                                input={<Input />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {
                                    grades?.map(item => (
                                        <MenuItem key={item.id} value={item.grade}>
                                            <Checkbox checked={schoolTripData?.grades?.includes(item.grade)} />
                                            <ListItemText primary={item.grade} />
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            {
                                error && !schoolTripData.grades.length ? (
                                    <FormHelperText>
                                        This field is required
                                    </FormHelperText>
                                ) : null
                            }
                        </FormControl>
                    </Grid>

                    {/* Date  */}
                    <Grid item xs={12} sm={6}>
                        <Box className={styles.dateSelectButton}>
                            <button type="button" onClick={() => setShowCalendar(true)}>
                                {
                                    schoolTripData.start_date && schoolTripData.end_date ? (
                                        <>
                                            <Typography variant="body1" color="text.secondary" >
                                                {moment(schoolTripData.start_date).format('DD/MM/YYYY')} - {moment(schoolTripData.end_date).format('DD/MM/YYYY')}
                                            </Typography>
                                            <span className={styles.dateButtonIcon}>
                                                <ArrowDropDownIcon />
                                            </span>
                                        </>
                                    ) : (

                                        <>
                                            <Typography variant="body1" color="text.secondary">
                                                Date range of trip *
                                            </Typography>
                                            <span className={styles.dateButtonIcon}>
                                                <ArrowDropDownIcon />
                                            </span>
                                        </>
                                    )
                                }
                            </button>

                            {
                                showCalendar && (
                                    <RangeCalendar
                                        open={showCalendar}
                                        handleClose={() => setShowCalendar(false)}
                                        startDate={schoolTripData.start_date}
                                        endDate={schoolTripData.end_date}
                                        setStartDate={(date) => setSchoolTripData(prev => ({ ...prev, start_date: date }))}
                                        setEndDate={(date) => setSchoolTripData(prev => ({ ...prev, end_date: date }))}
                                    />
                                )
                            }
                        </Box>
                        {
                            error && !schoolTripData.start_date && !schoolTripData.end_date && (
                                <Typography variant="caption" color="error">
                                    This field is required
                                </Typography>

                            )
                        }
                    </Grid>

                    {/*  School Name */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            required
                            fullWidth
                            label="School name"
                            name="school_name"
                            value={schoolTripData.school_name}
                            onChange={handleDetailsChange}
                            error={error && !schoolTripData.school_name}
                            helperText={
                                error && !schoolTripData.school_name?.trim() ? "This field is required" : ""
                            }
                        />
                    </Grid>

                    {/* Name */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            required
                            fullWidth
                            label="Contact name"
                            name="contact_name"
                            value={schoolTripData.contact_name}
                            onChange={handleDetailsChange}
                            error={error && !schoolTripData.contact_name}
                            helperText={
                                error && !schoolTripData.contact_name?.trim() ? "This field is required" : ""
                            }
                        />
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            required
                            fullWidth
                            label="Email Id"
                            name="email"
                            value={schoolTripData.email}
                            onChange={handleDetailsChange}
                            error={
                                error && (
                                    !schoolTripData.email || !validateEmail(schoolTripData.email)
                                )
                            }
                            helperText={
                                error && (
                                    !schoolTripData.email || !validateEmail(schoolTripData.email)
                                ) ? "Please enter a valid email ID" : ""
                            }
                        />
                    </Grid>

                    {/* Number */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            required
                            fullWidth
                            type="number"
                            label="Contact number"
                            name="contact_number"
                            value={schoolTripData.contact_number}
                            onChange={handleContactNumChange}
                            inputProps={
                                {
                                    maxLength: 10,
                                }
                            }
                            error={error && !schoolTripData.contact_number}
                            helperText={
                                error && !schoolTripData.contact_number ? "This field is required" : ""
                            }
                        />
                    </Grid>

                    {/* Alternate Number */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            fullWidth
                            type="number"
                            label="Alternate number"
                            name="alternate_number"
                            value={schoolTripData.alternate_number}
                            onChange={handleAltNumChange}
                            inputProps={
                                {
                                    maxLength: 10,
                                }
                            }
                        />
                    </Grid>

                    <Box className={styles.formButton}>
                        <Button
                            disabled={loading}
                            onClick={handleSubmit}
                            variant="outlined"
                            color="warning"
                            sx={{
                                color: "#1D9929",
                                fontWeight: "bold",
                                borderColor: "#1D9929",
                                '&:hover': {
                                    borderColor: "#1D9929",
                                    backgroundColor: "#1D9929",
                                    color: "#fff"
                                }

                            }}
                        >
                            {
                                loading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    "Submit"
                                )
                            }
                        </Button>
                    </Box>
                </Grid>
            </DialogContent>

            {
                showSuccess && (
                    <SuccessAlert
                        open={showSuccess}
                        title="Success!"
                        message={`Inquiry raised! The outdoor partner, ${partnerName}, will reach out to you in a few hours`}
                        handleClose={handleClose}
                    />
                )
            }

        </Dialog>
    )
}

export default SchoolTripModal