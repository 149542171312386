import {
    GET_COLLECTIONS,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_FAILURE,
} from '../actions/collections';

const initialState = {
    collections: null,
    loading: false,
    error: null
}

export const collections = (data = initialState, action) => {
    switch (action.type) {
        case GET_COLLECTIONS:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_COLLECTIONS_SUCCESS:
            return {
                ...data,
                loading: false,
                collections: action.payload
            }

        case GET_COLLECTIONS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
                collections: []
            }

        default:
            return data
    }
}