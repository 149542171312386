import styles from "./Header.module.css"

const Submenu = ({ items, setLink }) => {

  return (
    <ul className={styles.subMenu}>
      {
        items?.slice(0, 10)?.map((item, index) => {
          return (
            <li key={index} className={styles.subMenuLink}>
              <button
                type="button"
                onClick={() => setLink(item)}
              >
                {item.name==="Singleday" ? "Single Day" : item.name === "Multiday" ? "Multi Day" : item.name}
              </button>
            </li>
          )
        })
      }
    </ul>
  )
}

export default Submenu