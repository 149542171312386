import { Box, Typography } from "@mui/material"
import styles from "./PartnerProfile.module.css"
import { useState } from "react"
import ModalImageSlider from "./ModalImageSlider"
import PageviewIcon from '@mui/icons-material/Pageview';

const Media = ({ data }) => {
  const [openModal, setOpenModal] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const handleOpenModal = (index) => {
    setOpenModal(true)
    setCurrentImage(index)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setCurrentImage(0)
  }

  return (
    <Box className={styles.mediaBox}>
      {
        data?.length > 0 ? (
          data.map((image, index) => (
            <Box
              className={styles.mediaBoxImage}
              key={image?.id}
              onClick={() => handleOpenModal(index)}
            >
              <img src={image?.image_url} alt={image?.partner?.name} />
              <Box className={styles.cardHover}>
                <p className={styles.cardHoverText}>
                  <PageviewIcon fontSize="large" /> View
                </p>
              </Box>
            </Box>
          ))
        ) : (
          <Typography variant="h6" color="textSecondary">
            No media found
          </Typography>
        )
      }
      {
        openModal && (
          <ModalImageSlider
            images={data}
            currentImage={currentImage}
            open={openModal}
            handleClose={handleCloseModal}
          />
        )
      }
    </Box>
  )
}

export default Media