export const GET_EXPERIENCES = 'GET_EXPERIENCES';
export const GET_EXPERIENCES_SUCCESS = 'GET_EXPERIENCES_SUCCESS';
export const GET_EXPERIENCES_FAILURE = 'GET_EXPERIENCES_FAILURE';

export const getExperiences = () => ({
    type: GET_EXPERIENCES,
});

export const GET_EXPERIENCE = 'GET_EXPERIENCE';
export const GET_EXPERIENCE_SUCCESS = 'GET_EXPERIENCE_SUCCESS';
export const GET_EXPERIENCE_FAILURE = 'GET_EXPERIENCE_FAILURE';

// payload id
export const getExperience = (id) => ({
    type: GET_EXPERIENCE,
    id,
});

// Upcoming Experiences
export const GET_UPCOMING_EXPS = 'GET_UPCOMING_EXPS';
export const GET_UPCOMING_EXPS_SUCCESS = 'GET_UPCOMING_EXPS_SUCCESS';
export const GET_UPCOMING_EXPS_FAILURE = 'GET_UPCOMING_EXPS_FAILURE';

export const getUpcomingExps = () => ({
    type: GET_UPCOMING_EXPS,
});
