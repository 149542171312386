import React from 'react'
import {
  Box,
  Typography,
  Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './CalendarModal.module.css';
import Calendar from './Calendar';

const CalendarModal = ({ open, handleClose, selectedDate, setSelectedDate, toursData, setSelectedTime }) => {
  return (
    <Modal
      hideBackdrop
      open={open}
    >
      <Box className={styles.modalBox}>
        <Box className={styles.modalHeader}>
          <Typography variant="h6" component="h6">
            Select an upcoming slot
          </Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
        </Box>
        <Box className={styles.modalBody}>
          <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleClose={handleClose}
            toursData={toursData}
            setSelectedTime={setSelectedTime}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default CalendarModal