import React, { useState } from 'react'
import styles from "./HelpSlider.module.css"
import {
    Box,
    Typography,
    SwipeableDrawer,
    Button,
    IconButton
} from "@mui/material";
import { LazyImage } from '../';

const HelpSlider = ({ parent }) => {
    const [openHelp, setOpenHelp] = useState(false)

    const handleOpenHelp = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpenHelp(open);
    };

    return (
        <div>
            {
                parent === "sidebar" ? (
                    <Box sx={{ px: 1 }}>
                        <Button
                            variant="text"
                            onClick={handleOpenHelp(true)}
                            size='large'
                            color='inherit'
                        >
                            <img
                                style={{ width: "16px", marginRight: "4px" }}
                                src="/images/helpIcon.png"
                                alt="Help Icon"
                            />
                            Help
                        </Button>
                    </Box>
                ) : parent === "header" ? (
                    <Box>
                        <IconButton onClick={handleOpenHelp(true)}>
                            <img src="/images/help.png" alt="EdOutdoors" width={25} />
                        </IconButton>

                    </Box>
                ) : (
                    <Box className={styles.link} onClick={handleOpenHelp(true)}>
                        <img
                            style={{ width: "12px", marginRight: "4px" }}
                            src="/images/helpIcon.png"
                            alt="Help Icon"
                        />
                        <Typography variant="p" component="p">
                            Help
                        </Typography>
                    </Box>
                )
            }
            <SwipeableDrawer
                anchor="right"
                open={openHelp}
                onClose={handleOpenHelp(false)}
                onOpen={handleOpenHelp(true)}
                sx={{
                    '& .MuiDrawer-paper':
                    {
                        height: "30vh",
                        width: "100%",
                        maxWidth: { sm: 380 },
                        position: "absolute",
                        top: { sm: "1%" },
                        right: { sm: "1%" },
                        boxShadow: "0 0 10px",
                        borderRadius: { sm: "10px" }
                    },
                }}
            >
                <Box className={styles.helpSlider}>
                    <Box className={styles.helpSliderBox}>
                        <LazyImage
                            src="/mobile-logo.png"
                            alt="EdOutdoors"
                        />
                        <Box className={styles.helpSliderContent}>
                            <Typography variant="h5" component="h5">
                                Need Help?
                            </Typography>
                            <Typography variant="h6" align='center'>
                                Reach out to help centre at +91 88840 45670 <br />
                                or email us at <a href="mailto:hello@edoutdoors.in"> hello@edoutdoors.in</a>.
                            </Typography>
                        </Box>
                        <button type="button" onClick={handleOpenHelp(false)}>
                            Okay
                        </button>
                    </Box>
                </Box>
            </SwipeableDrawer>
        </div >
    )
}

export default HelpSlider