import styles from "./Home.module.css"
import { Typography, Container, Box, Skeleton, Card, CardContent } from '@mui/material';

export const Loading = () => {
    return (
        <Box className={styles.homeContainer}>
            <Box className={styles.homeTop}>
                <Box sx={{ height: window.innerWidth > 600 ? "70vh" : "40vh" }}>
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                </Box>
            </Box>

            <Box className={styles.searchBox} mb={8}>
                <Skeleton variant="rounded" width={window.innerWidth > 600 ? 600 : 300} height={50} />
            </Box>

            <Container sx={{ display: "flex", justifyContent: "space-between" }}>
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={80} />
            </Container>

            <Container className={styles.expList} sx={{ display: "flex", justifyContent: { xs: "center", sm: "space-between" }, flexWrap: "wrap", }}>

                {
                    [1, 2, 3].map((item) => (
                        <Card key={item} sx={{ maxWidth: 320, minHeight: 350, maxHeight: 355, display: "block", my: 4 }} >
                            <Skeleton variant="rectangular" width={320} height={200} />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    <Skeleton variant='text' width={150} height={30} />
                                </Typography>

                                <Typography gutterBottom variant="body2" color="text.secondary">
                                    <Skeleton variant='text' width={200} height={50} />
                                </Typography>

                                <Typography variant="body2" color="text.primary">
                                    <Skeleton variant='text' width={200} />
                                </Typography>

                            </CardContent>
                        </Card>
                    ))
                }
            </Container>

            <Container className={styles.bannerWrapper}>
                <Skeleton variant="rectangular" width="100%" height={400} />
            </Container>

        </Box>
    )
}