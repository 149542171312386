import styles from "./About.module.css"
import {
  Box,
  Container,
  Typography,
  Grid
} from "@mui/material"
import StarIcon from '@mui/icons-material/Star';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { team } from "../../assets"
import { LazyImage } from "../../components"
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const About = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>About | EdOutdoors</title>
        <meta
          name="description"
          content="Making outdoor learning a lifestyle for K12. Get the best outdoor learning providers under one roof."
        />
        <meta property="og:title" content="About | EdOutdoors" />
        <meta
          property="og:description"
          content="Making outdoor learning a lifestyle for K12. Get the best outdoor learning providers under one roof."
        />
        <link rel="canonical" href="https://www.edoutdoors.in/about-us" />
      </Helmet>

      <Box className={styles.aboutBox}>
        <Container className={styles.section1}>
          <Box className={styles.aboutTitle}>
            {
              window.innerWidth > 500 ? (
                <>
                  <Typography variant="h3">
                    Making Outdoor Learning
                  </Typography>
                  <Typography variant="h3">
                    A Lifestyle for K12
                  </Typography>
                </>
              ) : (
                <Typography variant="h3">
                  Making Outdoor Learning A Lifestyle for K12
                </Typography>
              )
            }
          </Box>

          <Box className={styles.aboutImages}>
            <Box className={styles.aboutImagesLeft}>
              <Box className={styles.leftImagesLeft}>
                <img src="/images/about/1.webp" alt="EdOutdoors" />
              </Box>

              <Box className={styles.leftImagesRight}>
                <img src="/images/about/2.webp" alt="EdOutdoors" />
                <img src="/images/about/3.webp" alt="EdOutdoors" />
              </Box>
            </Box>

            <Box className={styles.aboutImagesCenter}>
              <Box className={styles.centerImgStar}>
                <StarIcon fontSize="large" color="primary" />
              </Box>
              <img src="/images/about/centerImg.webp" alt="EdOutdoors" />
            </Box>

            <Box className={styles.aboutImagesRight}>
              <Box className={styles.rightImagesLeft}>
                <img src="/images/about/4.webp" alt="EdOutdoors" />
                <img src="/images/about/5.webp" alt="EdOutdoors" />
              </Box>

              <Box className={styles.rightImagesRight}>
                <img src="/images/about/6.webp" alt="EdOutdoors" />
              </Box>

            </Box>
          </Box>

          <Box className={styles.quoteText}>
            <Box>
              <FormatQuoteIcon
                sx={{
                  transform: "rotate(180deg)",
                  fontSize: "2rem",
                }}
              />
            </Box>
            <Typography variant="h6" style={{ fontStyle: "italic" }}>
              I love how my daughter comes back from every field trip and talks about how her school theory suddenly makes sense!
            </Typography>

            <Typography variant="h6">
              -Parul, Mother of Disha, Grade 8
            </Typography>
          </Box>

        </Container>


        <Box className={styles.section2}>
          <Container className={styles.section2Container}>
            <Box className={styles.section2Titles}>
              {
                window.innerWidth > 500 ? (
                  <>
                    <Typography variant="h2" sx={{ textAlign: { xs: "center", sm: "left" } }}>
                      We Get The Best
                    </Typography>
                    <Typography variant="h2" sx={{ textAlign: { xs: "center", sm: "left" } }}>
                      Outdoor Learning Providers
                    </Typography>
                    <Typography variant="h2" sx={{ textAlign: { xs: "center", sm: "left" } }}>
                      under One Roof
                    </Typography>
                  </>
                ) : (
                  <Typography variant="h2" sx={{ textAlign: { xs: "center", sm: "left" } }}>
                    We Get The Best Outdoor Learning Providers under <span>One Roof</span>
                  </Typography>
                )
              }
            </Box>
            <Box className={styles.section2Desc}>
              <Typography variant="h6" sx={{ textAlign: { xs: "center", sm: "left" } }}>
                By gathering these providers under one roof, we offer a convenient platform for parents and schools to effortlessly discover and select the ideal outdoor learning opportunities that align with their child's needs. Through this collaborative effort, we aim to facilitate the process of finding the best outdoor learning experiences, fostering a rich and impactful educational journey for every child.
              </Typography>
            </Box>
          </Container>

          <Container className={styles.section2Image}>
            <LazyImage
              src="/images/about/bigBanner.webp"
              alt="EdOutdoors"
              width="100%"
              height="100%"
            />
          </Container>
        </Box>


        <Box className={styles.section3}>
          <Container className={styles.section3Container}>
            <Grid container className={styles.section3Content}>
              <Grid item md={5} className={styles.left}>
                <Typography variant="h3">
                  Together we <br /> are  strong
                </Typography>
              </Grid>
              <Grid item md={7} className={styles.right}>
                <Typography variant="h6">
                  "Let's build the world's largest outdoor learning school together! Join us."
                </Typography>
                <Box className={styles.section3Desc}>
                  <Typography variant="p" paragraph>
                    Our crew is always getting bigger, but we all work toward one goal: help a child discover their strengths and interests with holistic learning in the outdoors.
                  </Typography>
                </Box>
                <Box className={styles.section3Profile}>
                  <LazyImage
                    src="/images/about/arpith.webp"
                    alt="EdOutdoors"
                  />
                  <Box className={styles.section3ProfileName}>
                    <Typography variant="h6">
                      Arpith C
                    </Typography>
                    <Typography variant="boby2">
                      Cofounder and CEO
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>

          <Container className={styles.section3Stats}>
            <Box className={styles.section3StatsContent}>
              <Box className={styles.section3StatBox}>
                <Typography variant="h5">
                  Experiences Conducted
                </Typography>
                <Typography variant="h3" align="center">
                  100+
                </Typography>
              </Box>
              <Box className={styles.section3StatBox}>
                <Typography variant="h5">
                  Children Impacted
                </Typography>
                <Typography variant="h3" align="center">
                  2000+
                </Typography>
              </Box>
              <Box className={styles.section3StatBox}>
                <Typography variant="h5">
                  Subjects Covered
                </Typography>
                <Typography variant="h3" align="center">
                  25+
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>

        <Box className={styles.section4}>
          <Container className={styles.section4Container}>
            <Typography variant="h3" sx={{ textAlign: { xs: "center", sm: "left" } }}>
              Meet our <br /> amazing team
            </Typography>

            <Box className={styles.teamWrapper}>
              {
                team?.map(member => (
                  <Box className={styles.teamBox} key={member?.name}>
                    <LazyImage
                      src={member?.img}
                      alt={member?.name}
                      width="100%"
                      height="100%"
                    />
                    <Box className={styles.social}>
                      {
                        member?.linkedIn ? (
                          <a href={member?.linkedIn} target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon fontSize="large" color="primary" />
                          </a>
                        ) : (
                          <span>
                            <LinkedInIcon fontSize="large" color="primary"  />
                          </span>
                        )
                      }
                    </Box>
                    <Typography variant="h5">{member?.name}</Typography>
                    <Typography variant="subtitle1" component="h6">{member?.title}</Typography>
                  </Box>
                ))
              }
            </Box>

            <Box className={styles.section4Border} />

            <Box className={styles.joinTeam}>
              <Box className={styles.joinTeamTitle}>
                <Typography variant="h3">
                  Join our team
                </Typography>
              </Box>

              <Box className={styles.joinTeamContent}>
                <Typography variant="body1">
                  We believe it takes great people to make a great product. That's why we hire not only perfect professional fits, but people who embody our company values.
                </Typography>

                <a href="https://www.linkedin.com/company/edoutdoors/jobs" target="_blank" rel="noopener noreferrer">
                  Join our team <ArrowForwardIosIcon fontSize="small" />
                </a>
              </Box>
            </Box>

          </Container>
        </Box>


        <Box className={styles.section5}>
          <Container className={styles.section5Container}>
            <Box className={styles.section5Titles}>
              <Typography variant="h2" sx={{ textAlign: { xs: "center", sm: "left" } }}>
                Have a question? <br />
                We are happy <br />
                to assist you
              </Typography>

              <Typography variant="body1" sx={{ textAlign: { xs: "center", sm: "left" } }}>
                Ask about the experiences listed on the platform, booking related queries or anything else. We are here to help you find a solution.
              </Typography>

              <Box className={styles.contact}>
                <button
                  type="button"
                  onClick={() => navigate("/contact-us")}
                >
                  Contact us <ArrowForwardIosIcon fontSize="small" />
                </button>

                <Typography variant="h6">
                  Or call +91 88840 45670
                </Typography>
              </Box>
            </Box>

            <Box className={styles.section5Img}>
              <img src="/images/about/haveAQue.webp" alt="EdOutdoors" />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  )
}

export default About