import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

const SuccessAlert = ({ title, message, open, handleClose }) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="success" onClick={handleClose} autoFocus>
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SuccessAlert;