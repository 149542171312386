import {
    Box,
    Container,
    Grid,
    Typography,
    Button,
    CircularProgress,
    Skeleton
} from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';
import CheckIcon from '@mui/icons-material/Check';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import styles from "./CheckOut.module.css"
import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { AuthModal, ProfileModal, CancellationPolicySlider } from "../../components"
import { paymentApi, expApi } from "../../services/api";
import Callback from "./Callback";
import { SuccessAlert } from "../../components";
import { BackdropLoading } from "../../components";

const PageLoader = () => {
    return (
        <Box className={styles.checkoutBox} sx={{ minHeight: "70vh", mt: 3 }}>
            <BackdropLoading open={true} />
            <Container className={styles.checkoutContainer}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={7}>
                        <Box className={styles.left}>
                            <Box className={styles.leftTop}>
                                <Box className={styles.info}>
                                    <Box className={styles.infoHead}>
                                        <Typography variant="h5" component="h5">
                                            <Skeleton variant="text" width="100%" height={50} />
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={styles.leftBottom}>

                                <Typography variant="h5" component="h5">
                                    <Skeleton variant="text" width="100%" height={80} />
                                </Typography>

                                <Box className={styles.infoForm}>
                                    <Box className={styles.inputField}>
                                        <Skeleton variant="text" width="100%" height={80} />
                                        <Skeleton variant="text" width="100%" height={80} />
                                    </Box>

                                    <Box className={styles.inputField}>
                                        <Skeleton variant="text" width="100%" height={80} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Box className={styles.right} sx={{ minHeight: "60vh" }}>
                            <Box className={styles.rightTop}>
                                <Grid container spacing={0} className={styles.titleImage}>
                                    <Grid item xs={12} md={8} className={styles.title}>
                                        <Typography variant="h6" component="h6">
                                            <Skeleton variant="text" width="100%" />
                                            <Skeleton variant="text" width="100%" />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4} className={styles.image}>
                                        <Skeleton variant="rectangular" width="100%" height="100%" />
                                    </Grid>
                                </Grid>
                                <Box className={styles.dateTimeEdit}>
                                    <Box className={styles.dateTime}>
                                        <Typography variant="p" component="p">
                                            <Skeleton variant="text" width={300} />
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={styles.rightMid}>
                                <Typography variant="p" component="p">
                                    <Skeleton variant="text" width="100%" />
                                </Typography>
                                <Typography variant="p" component="p">
                                    <Skeleton variant="text" width="100%" />
                                </Typography>
                                <Typography variant="p" component="p">
                                    <Skeleton variant="text" width="100%" />
                                </Typography>
                            </Box>

                            <Box className={styles.rightBottom}>
                                <Box className={styles.price}>
                                    <Box className={styles.amount}>
                                        <Typography variant="p" component="p">
                                            <Skeleton variant="text" width={150} />
                                        </Typography>
                                        <Typography variant="p" component="p">
                                            <Skeleton variant="text" width={50} />
                                        </Typography>
                                    </Box>
                                    <Box className={styles.totalAmount}>
                                        <Typography variant="h6" component="h6">
                                            <Skeleton variant="text" width={150} />
                                        </Typography>
                                        <Typography variant="h6" component="h6">
                                            <Skeleton variant="text" width={50} />
                                        </Typography>
                                    </Box>
                                </Box>

                                <Typography variant="caption" component="p">
                                    <Skeleton variant="text" width="100%" height={50} />
                                </Typography>
                                <Skeleton variant="text" width="100%" height={50} />
                            </Box>

                        </Box>
                    </Grid>

                </Grid>
            </Container>

        </Box >
    )
}

const CheckOut = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const [numberOfChilds] = useState(1)
    const [price, setPrice] = useState(0)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [exp, setExp] = useState(null)
    const [tourId, setTourId] = useState(null)
    const [openRequestCallback, setOpenRequestCallback] = useState({
        open: false,
        status: false
    })
    const [cancellationPolicy, setCancellationPolicy] = useState("")
    const [loading, setLoading] = useState(false)
    const [showAuth, setShowAuth] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const { user } = useSelector(state => state.auth)
    const { child } = useSelector(state => state.child)
    const { requestedCallbacks } = useSelector(state => state.callbacks)
    const [openCancellation, setOpenCancellation] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true)
    const [searchParams] = useSearchParams()

    const [childDetails, setChildDetails] = useState({
        name: "",
        age: "",
        mobile: user?.user?.mobile_number,
    })

    // payment
    const handlePayment = async () => {
        try {
            setLoading(true)
            const response = await paymentApi.createPayment({
                experience_id: exp.id,
                tour_id: tourId,
            })

            if (response.data.success) {
                const { payment_session_id } = response.data.data
                const cashfree = new window.Cashfree(payment_session_id);
                // This detail will be used in the checkout/thankyou page for pushing moengage event
                localStorage.setItem("experience_booked", JSON.stringify({
                    expTitle: exp.title,
                    expTourDate: moment(selectedDate).format("DD MMM, YYYY"),
                    expTourTime: moment(selectedTime, 'HH:mm:ss').format('hh:mm A'),
                    partner: exp.partner
                }))
                // if the payment is unsuccessful, the user will be redirected to this url ( Check the checkout/thankyou page)
                localStorage.setItem("failed_redirect", location.pathname + location.search)
                cashfree.redirect()
            } else {
                throw new Error(response.data.message)
            }
        } catch (error) {
            navigate(`/experience/${exp.id}`)
        } finally {
            setLoading(false)
        }
    }

    // When click on edit button 
    const handleEditClick = () => {
        navigate(`/experience/${id}`, {
            state: {
                edit: true,
            }
        })
    }

    // When click on confirm and pay button
    const handleConfirmPay = () => {
        if (!user) {
            setShowAuth(true)
            return
        }

        if (child.length === 0) {
            setShowProfile(true)
            return
        }

        handlePayment()
    }

    // When page load
    useEffect(() => {
        if (location.state === undefined || !location.state) {
            if (searchParams.get("date") && searchParams.get("time") && searchParams.get("tour_id")) {
                (async () => {
                    try {
                        const response = await expApi.getExperience(id)
                        if (response.data.success) {
                            const { data } = response.data
                            setExp(data?.experience)
                            setCancellationPolicy(data?.policy[0]?.cancellation_policy)
                            const date = moment(searchParams.get("date"), "DD-MM-YYYY").toDate()
                            const time = moment(searchParams.get("time"), "HH:mm:ss").toDate()
                            setSelectedDate(date)
                            setSelectedTime(time)
                            setTourId(searchParams.get("tour_id"))
                            setTimeout(() => {
                                setLoadingPage(false)
                            }, 1000);
                        }
                    } catch (error) {
                        navigate(`/experience/${id}`)
                    }
                })();
            } else {
                navigate(`/experience/${id}`)
            }
        } else {
            setSelectedDate(location.state?.selectedDate)
            setSelectedTime(location.state?.selectedTime)
            setExp(location.state?.exp)
            setTourId(location.state?.tourId)
            setCancellationPolicy(location.state?.policy)
            setTimeout(() => {
                setLoadingPage(false)
            }, 2000);
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (exp) {
            const { fee } = exp
            setPrice((fee * numberOfChilds))
            document.title = `${exp?.title} | School Trip | EdOutdoors`;
        }
    }, [exp, numberOfChilds])

    useEffect(() => {
        if (user && child) {
            const fullName = child.first_name + " " + child.last_name
            setChildDetails({
                name: fullName.includes("undefined") ? "" : fullName,
                dob: moment(child?.dob).format("DD MMM, YYYY"),
                mobile: user?.user?.user?.mobile_number,
            })
        }

    }, [child, user])

    useEffect(() => {
        if (requestedCallbacks.includes(id)) {
            setOpenRequestCallback((prev) => ({
                ...prev,
                status: true
            }));
        }
    }, [requestedCallbacks, id])

    if (loadingPage) return <PageLoader />

    return (
        <Box className={styles.checkoutBox}>
            <Container className={styles.checkoutContainer}>
                <Grid container spacing={0}>

                    <Grid item xs={12} md={7}>
                        <Box className={styles.left}>
                            <Box className={styles.leftTop}>

                                <Box className={styles.info}>
                                    <Box className={styles.infoHead}>
                                        <Typography variant="h4" component="h5">
                                            Slot available! RSVP now.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={styles.leftBottom}>

                                <Typography variant="h5" component="h5">
                                    Child details
                                </Typography>

                                <Box className={!user || child.length === 0 ? styles.infoFormBlur : styles.infoForm}>
                                    <Box className={styles.inputField}>
                                        <Box className={styles.infoBox}>
                                            <Typography variant="caption" component="p">
                                                Child Name
                                            </Typography>
                                            <Typography variant="h6" component="h6">
                                                {childDetails.name || ""}
                                            </Typography>
                                        </Box>

                                        <Box className={styles.infoBox}>
                                            <Typography variant="caption" component="p">
                                                DOB
                                            </Typography>
                                            <Typography variant="h6" component="h6">
                                                {childDetails.dob || ""}
                                            </Typography>
                                        </Box>

                                        <Box className={styles.infoBox}>
                                            <Typography variant="caption" component="p">
                                                Mobile Number
                                            </Typography>
                                            <Typography variant="h6" component="h6">
                                                {childDetails.mobile || ""}
                                            </Typography>
                                        </Box>
                                    </Box>

                                </Box>
                                {
                                    !user || child.length === 0 ? (
                                        <Box className={styles.infoFormButton}>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    !user ? setShowAuth(true) : setShowProfile(true)
                                                }}
                                            >
                                                Signup/Login
                                            </button>
                                            <Typography variant="p" component="p">
                                                to proceed
                                            </Typography>
                                        </Box>
                                    ) : null
                                }
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={5} >
                        <Box className={styles.right}>
                            <Box className={styles.rightTop}>
                                <Grid container spacing={0} className={styles.titleImage}>
                                    <Grid item xs={12} md={8} className={styles.title}>
                                        <Typography variant="h6" component="h6">
                                            {exp?.title?.length > 40 ? exp?.title?.slice(0, 40) + "..." : exp?.title}
                                            <Typography variant="body2" mt={0.5}>
                                                By {exp?.partner?.name}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4} className={styles.image}>
                                        <img
                                            src={exp?.cover_photo}
                                            alt={exp?.title}
                                        />
                                    </Grid>
                                </Grid>
                                <Box className={styles.dateTimeEdit}>
                                    <Box className={styles.dateTime}>
                                        <Typography variant="p" component="p">
                                            <DateRangeIcon /> {moment(selectedDate).format("ddd, Do MMM YY")}, {moment(selectedTime, 'HH:mm:ss').format('hh:mm A')}
                                        </Typography>
                                    </Box>
                                    <Box className={styles.edit}>
                                        <Button onClick={handleEditClick}>
                                            Edit
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className={styles.rightMid}>
                                <Typography variant="p" component="p">
                                    <CheckIcon color="success" /> Flexible cancellation
                                    <button
                                        onClick={() => setOpenCancellation(true)}
                                        style={{ width: '20px', border: 'none', background: 'none', cursor: 'pointer' }}
                                    >
                                        <img
                                            style={{ width: "16px", marginLeft: "4px", marginTop: "5px" }}
                                            src="/images/helpIcon.png"
                                            alt="Help Icon"
                                        />
                                    </button>
                                </Typography>
                                <Typography variant="p" component="p">
                                    <MoneyOffIcon color="info" /> No additional fees
                                </Typography>
                            </Box>

                            <Box className={styles.rightBottom}>
                                <Box className={styles.price}>
                                    <Box className={styles.amount}>
                                        <Typography variant="p" component="p">
                                            {
                                                numberOfChilds > 1 ? `${numberOfChilds} Children` : `${numberOfChilds} Child`
                                            }
                                        </Typography>
                                        <Typography variant="p" component="p">
                                            ₹{price}
                                        </Typography>
                                    </Box>
                                    <Box className={styles.totalAmount}>
                                        <Typography variant="h6" component="h6">
                                            Total Payable {" "}
                                            <Typography variant="caption">
                                                (inclusive of taxes)
                                            </Typography>
                                        </Typography>
                                        <Typography variant="h6" component="h6">
                                            ₹{price}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box className={styles.pay}>
                                    <Typography variant="caption" component="p">
                                        By clicking 'Confirm & pay', you agree to our {" "}
                                        <a href={`${window.location.origin}/terms-of-use`} target='_black'>Terms</a> and {" "}
                                        <a href={`${window.location.origin}/privacy-policy`} target='_black'>Privacy Policy</a>.
                                    </Typography>
                                    <button
                                        type="button"
                                        onClick={handleConfirmPay}
                                        disabled={loading}
                                    >
                                        {
                                            loading ? (
                                                <CircularProgress size={30} />
                                            ) : (
                                                "Confirm & Pay"
                                            )
                                        }
                                    </button>
                                    <Box>
                                        {
                                            !openRequestCallback.status ? (
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setOpenRequestCallback((prev) => ({
                                                            ...prev,
                                                            open: true
                                                        }));
                                                    }}
                                                    className={styles.requestCallback}
                                                >
                                                    Questions? Request Callback
                                                </button>
                                            ) : (
                                                <Typography variant="body">
                                                    {
                                                        `Done! You’ll be contacted by ${exp?.partner?.name} in the next few hours.`
                                                    }
                                                </Typography>
                                            )
                                        }
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Grid>

                </Grid>
            </Container>

            {
                openRequestCallback.open && (
                    <Callback
                        open={openRequestCallback.open}
                        setOpenRequestCallback={setOpenRequestCallback}
                        expId={id}
                        setOpenSuccessAlert={setOpenSuccessAlert}
                        data={childDetails}
                        parentName={child?.father_full_name}
                        partnerName={exp?.partner?.name}
                        expTitle={exp?.title}
                        expTourDate={moment(selectedDate).format("DD MMM, YYYY")}
                        expTourTime={moment(selectedTime, 'HH:mm:ss').format('hh:mm A')}
                    />
                )
            }

            {
                openRequestCallback.status && (
                    <SuccessAlert
                        open={openSuccessAlert}
                        title="Request Submitted"
                        message={`Your request has been submitted successfully. ${exp?.partner.name} will get back to you in a few hours.`}
                        handleClose={() => setOpenSuccessAlert(false)}
                    />
                )
            }

            {
                showAuth && (
                    <AuthModal show={showAuth} closeAuth={() => setShowAuth(false)} />
                )
            }

            {
                showProfile && (
                    <ProfileModal
                        open={showProfile}
                        action="create"
                        handleClose={() => setShowProfile(false)}
                    />
                )
            }

            {
                openCancellation && (
                    <CancellationPolicySlider
                        open={openCancellation}
                        setOpen={setOpenCancellation}
                        heading={`Cancellation policy stated by the organizer, ${exp?.partner?.name}`}
                        description={cancellationPolicy}
                    />
                )
            }

        </Box >
    )
}

export default CheckOut