import styles from "./ModalImageSlider.module.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Modal, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { LazyImage } from "../../components"

const CustomPrevArrow = () => (
    <div className="swiper-button-prev">
        <img src="/images/backIcon.png" alt="Previous" className='arrows-sm' />
    </div>
);

const CustomNextArrow = () => (
    <div className="swiper-button-next">
        <img src="/images/nextIcon2.png" alt="Next" className='arrows-sm' />
    </div>
);

const ModalImageSlider = ({ images, currentImage, open, handleClose }) => {

    return (
        <Modal
            open={open}
            hideBackdrop
            sx={{
                backgroundColor: "rgba(0,0,0,0.8)",
            }}
        >
            <Box className={styles.modalBox}>
                <Box className={styles.modalHead}>
                    <IconButton onClick={handleClose} color="error" size="large">
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Box className={styles.modalBody}>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        slidesPerView={1}
                        navigation={
                            {
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }
                        }
                        pagination={{ clickable: true }}
                        initialSlide={currentImage}
                    >
                        <CustomPrevArrow />
                        <CustomNextArrow />
                        {
                            images && (
                                images.map((item) => {
                                    return (
                                        <SwiperSlide key={item.id}>
                                            <Box className={styles.imageSlider}>
                                                <LazyImage
                                                    src={item.image_url}
                                                    alt="EdOutdoors"
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </Box>
                                        </SwiperSlide>
                                    )
                                })
                            )
                        }
                    </Swiper>

                </Box>
            </Box>
        </Modal>
    )
}

export default ModalImageSlider
