import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from "react-redux";

export default function SearchInput({ py, px, width, id }) {
  const { experiences } = useSelector(state => state.experiences);
  const navigate = useNavigate();
  
  return (
    <Autocomplete
      // Styling for Autocomplete component
      sx={
        id === "home" ? {
          width: { xs: "80%", md: width },
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            backgroundColor: "#fff",
            color: "#444",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            padding: `${py}px ${px}px`,
            border: 0,
            outline: 0,
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: 0,
            outline: 0,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: 0,
            outline: 0,
          },
          "& .MuiOutlinedInput-input::placeholder": {
            color: "#000",
          },
        } : {
          width: { xs: "80%", md: width },
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            backgroundColor: "#fff",
            color: "#444",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            padding: `${py}px ${px}px`,
            border: 0,
            outline: 0,
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: 0,
            outline: 0,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: 0,
            outline: 0,
          },
          "& .MuiOutlinedInput-input::placeholder": {
            color: "#000",
          },
          "& ::placeholder": {
            position: "relative",
            top: "-1px",
          },
        }
      }
      disableClearable
      // Set options to be searched and displayed in the dropdown
      options={experiences?.map((option) => option.title) || []}
      // Callback function called when user selects an option from the dropdown
      onChange={(event, value) => {
        if (value) {
          const experience = experiences.find(exp => exp.title === value);
          if (experience) {
            navigate(`/experience/${experience.id}`);
            // Clear the input field after user has selected an option
            event.target.value = "";
          }
        }
      }}
      // Text to be displayed when no matching options are found
      noOptionsText="No Experiences found 😞"
      // Render the input field with search icon as startAdornment
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Search Experiences'
          InputProps={{
            ...params.InputProps,
            autoFocus: true,
            startAdornment: (
              <SearchIcon
                sx={{
                  color: "gray",
                  position: "relative",
                  top: "1px",
                }}
              />
            ),
            endAdornment: false
          }}
        />
      )}
      // Function to filter the options based on search term and limit the number of displayed options
      filterOptions={(options, state) => {
        const filteredOptions = options.filter((option) =>
          option.toLowerCase().includes(state.inputValue.toLowerCase())
        );
        return filteredOptions.slice(0, 5);
      }}
    />
  );
}
