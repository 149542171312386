import {
    GET_EXPERIENCE_TYPES,
    GET_EXPERIENCE_TYPES_SUCCESS,
    GET_EXPERIENCE_TYPES_FAILURE,
    GET_DESTINATION_TYPES,
    GET_DESTINATION_TYPES_SUCCESS,
    GET_DESTINATION_TYPES_FAILURE,
} from '../actions/exp_destination_types';

const initialState = {
    experienceTypes: null,
    destinationTypes: null,
    loading: false,
    error: null
}

export const exp_destination_types = (data = initialState, action) => {
    switch (action.type) {
        case GET_EXPERIENCE_TYPES:
        case GET_DESTINATION_TYPES:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_EXPERIENCE_TYPES_SUCCESS:
            return {
                ...data,
                loading: false,
                experienceTypes: action.payload
            }

        case GET_DESTINATION_TYPES_SUCCESS:
            return {
                ...data,
                loading: false,
                destinationTypes: action.payload
            }

        case GET_EXPERIENCE_TYPES_FAILURE:
        case GET_DESTINATION_TYPES_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}