import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import RecommendationCard from './Card';
import { Typography, Box, Skeleton, Card, CardContent, Container } from '@mui/material';
import { recommendationsApi } from '../../services/api'
import { useEffect, useState } from 'react';

const CustomPrevArrow = () => (
    <div className="swiper-button-prev"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/prevIcon.png"
            alt="Previous"
            className='arrows-sm'
            style={{ width: "100%" }}
        />
    </div>
);

const CustomNextArrow = () => (
    <div
        className="swiper-button-next"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/nextIcon.png"
            alt="Next"
            className='arrows-sm'
            style={{ width: "100%" }}

        />
    </div>
);

const SkeletonLoading = () => {
    return (
        <Card sx={{ maxWidth: 320, minHeight: 350, maxHeight: 355, display: "block" }} >
            <Skeleton variant="rectangular" width={320} height={200} />
            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                    <Skeleton variant='text' width={150} height={30} />
                </Typography>

                <Typography gutterBottom variant="body2" color="text.secondary">
                    <Skeleton variant='text' width={200} height={50} />
                </Typography>

                <Typography variant="body2" color="text.primary">
                    <Skeleton variant='text' width={200} />
                </Typography>

            </CardContent>
        </Card>
    )
}

const Recommendations = ({ expId, collection }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (expId) {
            (async () => {
                const { data } = await recommendationsApi.getRecommendations(expId)
                setData(data?.data?.filter((item) => item?.id !== expId))
                setLoading(false)
            })()
        }
    }, [expId])

    return (
        data?.length > 0 && (
            <Container>
                <Box>
                    <Typography
                        variant={window.innerWidth > 500 ? "h4" : "h5"}
                        component="h4"
                        sx={{ fontWeight: '700', mb: 3, ml: 1 }}
                    >
                        {
                            collection ? "More experiences" : "More experiences like this"
                        }
                    </Typography>
                </Box>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={0}
                    slidesPerView={window.innerWidth > 1000 ? 3 : window.innerWidth > 600 ? 2 : 1}
                    navigation={
                        {
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }
                    }
                    style={window.innerWidth > 600 ? { padding: '20px 25px 20px 10px' } : { padding: '15px 40px 15px 0' }}

                >
                    <CustomPrevArrow />
                    <CustomNextArrow />
                    {
                        loading ? (
                            [1, 2, 3, 4, 5, 6].map((item) => (
                                <SwiperSlide key={item}>
                                    <SkeletonLoading />
                                </SwiperSlide>
                            ))) : (
                            data.map((item) => (
                                <SwiperSlide key={item.id}>
                                    <RecommendationCard item={item} />
                                </SwiperSlide>
                            ))
                        )
                    }
                </Swiper>
            </Container>
        )
    )
}

export default Recommendations