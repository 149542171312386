import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const RefundPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Refund Policy | EdOutdoors</title>
                <meta property="og:title" content="Refund Policy | EdOutdoors" />
                <link rel="canonical" href="https://edoutdoors.in/refund-policy" />
            </Helmet>
            
            <Container maxWidth="md">
                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Refunds & Cancellations Policy
                    </Typography>

                    <Typography variant="body1" paragraph>
                        All experiences offered on this platform have a variable cancellation policy, depending on the service provider associated with the listed experience. In case of no information provided in the experience details on its cancellations and refunds policy, the following policy will be applicable:
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        On domestic experiences (service provided within India):
                    </Typography>

                    <Box pl={4}>
                        <ul>
                            <Typography variant="body1" mb={0.5}>
                                <li>
                                    20% is deducted on cancellations up to 5 days before program departure date.
                                </li>
                            </Typography>

                            <Typography variant="body1" mb={0.5}>
                                <li>
                                    50% of total fee is deducted on cancellations made between 2 to 5 days before program departure date.
                                </li>
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <li>
                                    There is no refund on cancellations less than 2 days from departure date.
                                </li>
                            </Typography>
                        </ul>
                    </Box>

                    <Typography variant="h6" gutterBottom>
                        On international experiences (service provided outside India):
                    </Typography>

                    <Box pl={4}>
                        <ul>
                            <Typography variant="body1" mb={0.5}>
                                <li>
                                    25% is deducted on cancellations up to 14 days before program departure date.
                                </li>
                            </Typography>

                            <Typography variant="body1" mb={0.5}>
                                <li>
                                    50% of total fee is deducted on cancellations made between 5 to 14 days before program departure date.
                                </li>
                            </Typography>

                            <Typography variant="body1" mb={0.5}>
                                <li>
                                    There is no refund on cancellations less than 5 days from departure date.
                                </li>
                            </Typography>
                        </ul>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default RefundPolicy;
