import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Card from './Card';
import { Typography, Box, Container, Button } from '@mui/material';
import styles from './styles.module.css';
import { data } from "../../assets"


const CustomPrevArrow = () => (
    <div className="swiper-button-prev"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/prevIcon.png"
            alt="Previous"
            className='arrows-sm'
            style={{ width: "100%" }}
        />
    </div>
);

const CustomNextArrow = () => (
    <div
        className="swiper-button-next"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/nextIcon.png"
            alt="Next"
            className='arrows-sm'
            style={{ width: "100%" }}

        />
    </div>
);

const Blogs = () => {
    return (
        <Container>
            <Box className={styles.heading}>
                <Typography variant="h2" gutterBottom>
                    EdOutdoors Blog
                </Typography>
            </Box>
            <Swiper
                modules={[Navigation]}
                spaceBetween={0}
                slidesPerView={window.innerWidth > 1000 ? 3 : window.innerWidth > 600 ? 2 : 1}
                navigation={
                    {
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }
                }
                style={
                    window.innerWidth > 600 ? { padding: '5px 25px 5px 0' } : { padding: '5px 40px 5px 0' }
                }

            >
                <CustomPrevArrow />
                <CustomNextArrow />
                {

                    data?.map((item, index) => (
                        <SwiperSlide key={index}>
                            <Card item={item} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>

            <Box display="flex" justifyContent="center" mt={2}>
                <Button
                    href='https://blog.edoutdoors.in/'
                    target='_blank'
                >
                    Read More
                </Button>
            </Box>
        </Container>
    )
}

export default Blogs