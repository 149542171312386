import styles from "./ProfileModal.module.css"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    TextField,
    Typography,
    Box,
    Slide,
    Stepper,
    Step,
    StepLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Modal,
    IconButton,
    FormHelperText,
    // Autocomplete
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { forwardRef, useState, useEffect } from "react"
import { getGrades } from '../../services/actions/grades'
// import { getSchools } from '../../services/actions/schools'
import { getChild } from '../../services/actions/child'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import Calendar from 'react-calendar'
import { childApi } from "../../services/api"
import { getUser } from '../../services/actions/auth'
import moengage from "@moengage/web-sdk";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// For Date of Birth Picker
function DateOfBirthPicker({ open, handleClose, onDateChange }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleDateChange = (date) => {
        setSelectedDate(date);
        onDateChange(date);
    };
    const maxDate = new Date();
    return (
        <Modal
            hideBackdrop
            open={open}
        >
            <Box className={styles.modalBox}>
                <Box className={styles.modalHeader}>
                    <Typography variant="h5" component="h5">
                        Select a date
                    </Typography>
                    <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
                </Box>
                <Box className={styles.modalBody}>
                    <Calendar
                        onChange={handleDateChange}
                        value={selectedDate}
                        maxDate={maxDate}
                        onClickDay={() => {
                            setTimeout(() => {
                                handleClose()
                            }, 100)
                        }}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

const ProfileModal = ({ open, handleClose, action, editData, setShowWelcome, setShowSuccess }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [details, setDetails] = useState({
        first_name: "",
        last_name: "",
        dob: "",
        grade_id: "",
        school_id: null,
        email: "dummy12@gmail.com",
        father_full_name: "",
        father_profession: "Other",
        mother_full_name: "",
        mother_profession: "Other",
        parent_email: "",
        has_passport: false,
        passport_expiry_date: "2017-02-09T00:00:00",
        country_visited: [],
        hobby: [],
        subject: []

    })
    const [formError, setFormError] = useState(false)
    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const { grades, loading: gradesLoading } = useSelector((state) => state.grades)
    // const { schools, loading: schoolsLoading } = useSelector((state) => state.schools)
    const { loading: childLoading } = useSelector((state) => state.child)

    const handleTextFieldChange = (e) => {
        const { name, value } = e.target;
        setDetails({
            ...details,
            [name]: value
        });
    };

    const handleSelectChange = (name) => (e) => {
        const { value } = e.target;
        setDetails({
            ...details,
            [name]: value
        });
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    function validateDetails(details) {
        const requiredDetails = ['first_name', 'last_name', 'dob', 'grade_id', 'father_full_name', 'mother_full_name', 'parent_email', 'father_profession', 'mother_profession'];
        const isValid = requiredDetails.every((detail) => {
            const value = details[detail];
            return (typeof value === 'string' && value.trim() !== '') || (typeof value === 'number' && value > 0);
        });

        const emailIsValid = details.email ? validateEmail(details.email) : true;
        const parentEmailIsValid = details.parent_email ? validateEmail(details.parent_email) : true;

        return isValid && emailIsValid && parentEmailIsValid;
    }

    // const professions = [
    //     {
    //         name: "Doctor",
    //         value: "doctor"
    //     },

    //     {
    //         name: "Engineer",
    //         value: "engineer"
    //     },

    //     {
    //         name: "Business",
    //         value: "business"
    //     },

    //     {
    //         name: "Teacher",
    //         value: "teacher"
    //     },
    //     {
    //         name: "Other",
    //         value: "other"
    //     },

    // ]

    // Pushing Moengage Event
    const pushMoengageEvent = () => {
        moengage.add_first_name(details.first_name);
        moengage.add_last_name(details.last_name);
        moengage.add_email(details.parent_email);
        moengage.add_user_name(
            details.first_name + " " + details.last_name
        );

        moengage.track_event("SignUp", {
            child_first_name: details.first_name,
            parent1_first_name: details.father_full_name,
            parent2_first_name: details.mother_full_name,
            grade: grades?.find(grade => grade?.id === details.grade_id)?.grade,
            age: moment().diff(details.dob, 'years', false),
            email_parent1: details.parent_email,
        });
    }

    // Submitting the form
    const handleNext = async () => {
        if (activeStep === 1) {
            if (!validateDetails(details)) {
                setFormError(true);
                return;
            }
            setFormError(false)
            setLoading(true)
            if (details.id) {
                const response = await childApi.updateChild(details)
                if (response.data.success) {
                    dispatch(getChild(details.id))
                    handleClose()
                    setShowSuccess(true)
                } else {
                    throw new Error(response.data.message)
                }
            } else {
                const response = await childApi.createChild(details)
                if (response.data.success) {
                    pushMoengageEvent()
                    dispatch(getUser())
                    setShowWelcome(true)
                } else {
                    throw new Error(response.data.message)
                }
            }
            setLoading(false)
        } else {
            if (details.first_name?.trim() && details.last_name?.trim() && details.dob && details.grade_id) {
                if (!validateEmail(details.email)) {
                    setFormError(true)
                    return
                }
                setFormError(false)
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setFormError(true)
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if (action === "edit") {
            return
        } else {
            dispatch(getGrades())
            // dispatch(getSchools())
        }
    }, [dispatch, action])

    useEffect(() => {
        if (editData) {
            setDetails({
                id: editData.id,
                user_id: editData.user_id,
                first_name: editData.first_name,
                last_name: editData.last_name,
                dob: editData.dob,
                grade_id: editData.grade_id,
                school_id: null,
                email: editData.email,
                father_full_name: editData.father_full_name,
                father_profession: "Other",
                mother_full_name: editData.mother_full_name,
                mother_profession: "Other",
                parent_email: editData.parent_email,
                has_passport: false,
                passport_expiry_date: "2017-02-09T00:00:00",
                country_visited: [],
                hobby: [],
                subject: []
            })
        }
    }, [editData])

    if (gradesLoading || childLoading) {
        return null
    }

    const steps = ['Child', 'Parent']

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            sx={{
                '& .MuiDialog-paper': {
                    margin: 1,
                    width: '100%',
                    padding: { xs: "5px", md: "18px", },
                    borderRadius: "20px"
                },

            }}
        >
            <DialogTitle>
                <Box className={styles.header}>
                    {
                        action === "edit" ? (
                            <>
                                <Typography variant="h5">
                                    Edit profile
                                </Typography>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <Typography variant="h5">
                                    Setup your child's profile
                                </Typography>
                            </>
                        )
                    }
                </Box>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </DialogTitle >
            <DialogContent>
                {
                    activeStep === 0 ? (
                        <Box className={styles.form} >
                            <Box className={styles.inputField}>
                                <TextField
                                    fullWidth
                                    label="First Name of child"
                                    name="first_name"
                                    value={details.first_name}
                                    onChange={handleTextFieldChange}
                                    required
                                    inputProps={
                                        {
                                            maxLength: 20,
                                        }
                                    }
                                    error={formError && !details.first_name?.trim()}
                                    variant="standard"
                                    helperText={
                                        formError && !details.first_name?.trim() ? "This field is required" : ""
                                    }
                                />
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="last_name"
                                    value={details.last_name}
                                    onChange={handleTextFieldChange}
                                    required
                                    inputProps={
                                        {
                                            maxLength: 20,
                                        }
                                    }
                                    error={formError && !details.last_name?.trim()}
                                    variant="standard"
                                    helperText={
                                        formError && !details.last_name?.trim() ? "This field is required" : ""
                                    }
                                />
                            </Box>

                            <Box className={styles.inputField}>
                                <TextField
                                    fullWidth
                                    label="Date of Birth (YYYY-MM-DD)"
                                    name="dob"
                                    value={
                                        details.dob ? moment(details.dob).format("YYYY-MM-DD") : ""
                                    }
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                    required
                                    error={formError && !details.dob}
                                    onClick={() => setDatePickerOpen(true)}
                                    variant="standard"
                                    helperText={
                                        formError && !details.dob ? "This field is required" : ""
                                    }
                                />

                                {
                                    datePickerOpen && (
                                        <DateOfBirthPicker
                                            open={datePickerOpen}
                                            handleClose={() => setDatePickerOpen(false)}
                                            onDateChange={(date) => setDetails({
                                                ...details,
                                                dob: moment(date).format("YYYY-MM-DD")
                                            })}
                                        />
                                    )
                                }

                                <FormControl
                                    fullWidth
                                    required
                                    error={formError && !details.grade_id}
                                    variant="standard"
                                >
                                    <InputLabel>Grade</InputLabel>
                                    <Select
                                        value={details.grade_id}
                                        label="Grade"
                                        onChange={handleSelectChange("grade_id")}
                                        name="grade_id"
                                    >
                                        {
                                            grades?.map(grade => (
                                                <MenuItem key={grade.id} value={grade.id}>{grade.grade}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        formError && !details.grade_id ? (
                                            <FormHelperText>
                                                This field is required
                                            </FormHelperText>
                                        ) : null
                                    }
                                </FormControl>
                            </Box>

                            {/* <Box className={styles.inputField}>
                                <Box sx={{ width: "100%" }}>
                                    <Autocomplete
                                        fullWidth
                                        disableClearable
                                        options={schools?.map((option) => option?.name) || []}
                                        onChange={(event, value) => {
                                            if (value) {
                                                setDetails({
                                                    ...details,
                                                    school_id: schools?.find(school => school.name === value).id
                                                })
                                            }
                                        }}
                                        getOptionLabel={(option) => option}
                                        noOptionsText="No School found 😞"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="School Name"
                                                variant="standard"
                                                error={formError && !details.school_id}
                                            />
                                        )}
                                        // Function to filter the options based on search term and limit the number of displayed options
                                        filterOptions={(options, state) => {
                                            const filteredOptions = options?.filter((option) =>
                                                option?.toLowerCase().includes(state?.inputValue?.toLowerCase())
                                            );
                                            return filteredOptions?.slice(0, 5);
                                        }}
                                    />
                                    {
                                        formError && !details.school_id ? (
                                            <FormHelperText sx={{ color: "#d32f2f" }}>
                                                This field is required
                                            </FormHelperText>
                                        ) : null
                                    }
                                </Box>

                                <TextField
                                    fullWidth
                                    value={schools?.find(school => school.id === details?.school_id)?.name || " "}
                                    label="Selected School Name"
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Box> */}
                        </Box>
                    ) : (
                        <Box className={styles.form} >
                            <Box className={styles.inputField}>
                                <TextField
                                    fullWidth
                                    label="Parent 1 Full Name"
                                    name="father_full_name"
                                    value={details.father_full_name}
                                    onChange={handleTextFieldChange}
                                    required
                                    inputProps={
                                        {
                                            maxLength: 20,
                                        }
                                    }
                                    error={formError && !details.father_full_name?.trim()}
                                    variant="standard"
                                    helperText={
                                        formError && !details.father_full_name?.trim() ? "This field is required" : ""
                                    }
                                />

                                <TextField
                                    fullWidth
                                    label="Parent 2 Full Name"
                                    name="mother_full_name"
                                    value={details.mother_full_name}
                                    onChange={handleTextFieldChange}
                                    required
                                    inputProps={
                                        {
                                            maxLength: 20,
                                        }
                                    }
                                    error={formError && !details.mother_full_name?.trim()}
                                    variant="standard"
                                    helperText={
                                        formError && !details.mother_full_name?.trim() ? "This field is required" : ""
                                    }
                                />

                                {/* <FormControl
                                    fullWidth
                                    required
                                    error={formError && !details.father_profession}
                                    variant="standard"
                                >
                                    <InputLabel>Parent 1 Profession</InputLabel>
                                    <Select
                                        value={details.father_profession}
                                        label="Parent 1 Profession"
                                        onChange={handleSelectChange("father_profession")}
                                        name="father_profession"
                                    >
                                        {
                                            professions?.map((profession, index) => (
                                                <MenuItem key={index} value={profession.value}>{profession.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        formError && !details.father_profession ? (
                                            <FormHelperText>
                                                This field is required
                                            </FormHelperText>
                                        ) : null
                                    }
                                </FormControl> */}
                            </Box>

                            {/* <Box className={styles.inputField}>
                               

                                <FormControl
                                    fullWidth
                                    required
                                    error={formError && !details.mother_profession}
                                    variant="standard"
                                >
                                    <InputLabel>Parent 2 Profession</InputLabel>
                                    <Select
                                        value={details.mother_profession}
                                        label="Parent 2 Profession"
                                        onChange={handleSelectChange("mother_profession")}
                                        name="mother_profession"
                                    >
                                        {
                                            professions?.map((profession, index) => (
                                                <MenuItem key={index} value={profession.value}>{profession.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        formError && !details.mother_profession ? (
                                            <FormHelperText>
                                                This field is required
                                            </FormHelperText>
                                        ) : null
                                    }
                                </FormControl>
                            </Box> */}

                            <Box className={styles.inputField}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Email of any parent (for communication purposes)"
                                    name="parent_email"
                                    value={details.parent_email}
                                    onChange={handleTextFieldChange}
                                    error={formError && (
                                        !details.parent_email?.trim() || !validateEmail(details.parent_email)
                                    )}
                                    variant="standard"
                                    helperText={
                                        formError && (
                                            !details.parent_email?.trim() ? "This field is required" : "Please enter a valid email"
                                        )
                                    }
                                />
                            </Box>
                        </Box>
                    )
                }
                <Box className={styles.formButton}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 2 }}
                        variant="outlined"
                        color="warning"
                    >
                        Back
                    </Button>
                    <Button
                        onClick={handleNext}
                        variant="outlined"
                        disabled={loading}
                        sx={{
                            color: "#1D9929",
                            borderColor: "#1D9929",
                            '&:hover': {
                                borderColor: "#1D9929",
                                backgroundColor: "#1D9929",
                                color: "#fff"
                            }

                        }}
                    >
                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                </Box>
            </DialogContent>

        </Dialog >
    )
}

export default ProfileModal