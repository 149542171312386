import {
    GET_SUBJECTS,   
    GET_SUBJECTS_SUCCESS,
    GET_SUBJECTS_FAILURE,
} from '../actions/subjects';

import { subjectsApi } from '../api';
import { call, put, takeEvery } from 'redux-saga/effects';

function* getSubjects() {
    try {
        const response = yield call(subjectsApi.getSubjects);
        yield put({ type: GET_SUBJECTS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_SUBJECTS_FAILURE, payload: error });
    }
}

export default function* getSubjectsSaga() {
    yield takeEvery(GET_SUBJECTS, getSubjects);
}