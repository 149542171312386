import {
    GET_CHILD,
    GET_CHILD_SUCCESS,
    GET_CHILD_FAILURE,
} from '../actions/child';

const initialState = {
    child: [],
    loading: false,
    error: null,
};

export const child = (data = initialState, action) => {
    switch (action.type) {
        case GET_CHILD:
            return {
                ...data,
                loading: true,
                error: null,
            };

        case GET_CHILD_SUCCESS:
            return {
                ...data,
                loading: false,
                child: action.payload,
            };

        case GET_CHILD_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            };

        default:
            return data;
    }
};
