import { CHANGE_NOTIFY_EXP } from '../actions/notify';

const initialState = {
    notifiedExps: [],
};

export const notify = (data = initialState, action) => {
    switch (action.type) {
        case CHANGE_NOTIFY_EXP:
            return {
                ...data,
                notifiedExps: [...data.notifiedExps, action.expId],
            };
        default:
            return data;
    }
}