import {
    GET_SUBJECTS,
    GET_SUBJECTS_SUCCESS,
    GET_SUBJECTS_FAILURE,
} from '../actions/subjects';

const initialState = {
    subjects: null,
    loading: false,
    error: null
}

export const subjects = (data = initialState, action) => {
    switch (action.type) {
        case GET_SUBJECTS:
            return {
                ...data,
                loading: true,
                error: null
            }

        case GET_SUBJECTS_SUCCESS:
            return {
                ...data,
                loading: false,
                subjects: action.payload
            }

        case GET_SUBJECTS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
            }

        default:
            return data
    }
}