import { CHANGE_RUQUESTED_CALLBACKS } from '../actions/callbacks';

const initialState = {
    requestedCallbacks: [],
};

export const callbacks = (data = initialState, action) => {
    switch (action.type) {
        case CHANGE_RUQUESTED_CALLBACKS:
            return {
                ...data,
                requestedCallbacks: [...data.requestedCallbacks, action.expId],
            };
        default:
            return data;
    }
}