import {
    Box,
    Container,
    Typography,
    Card,
    CardContent,
    CardMedia,
    Skeleton,
} from "@mui/material";
import styles from "./Collections.module.css";
import { useState, useEffect } from "react";
import { collectionsApi } from "../../services/api";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getExperiences } from "../../services/actions/experiences";
import { Link } from "react-router-dom";
import { Banner, LazyImage } from "../../components";
import { Recommendations } from "../../components";
import { Helmet } from "react-helmet";

const Loader = () => {
    return (
        <Box className={styles.collections}>
            <Container>
                <Typography variant="h4" sx={{ my: 5 }}>
                    <Skeleton variant="text" width={200} />
                </Typography>

                <Box className={styles.listingWrapper}>
                    {[1, 2, 3].map((item) => (
                        <Card
                            key={item}
                            sx={{
                                width: 320,
                                minHeight: 355,
                                maxHeight: 360,
                                display: "block",
                                "&:hover": {
                                    boxShadow: 3,
                                },
                            }}
                        >
                            <Skeleton variant="rectangular" height={180} />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    <Skeleton variant="text" width={200} />
                                </Typography>

                                <Typography gutterBottom variant="body2" color="text.secondary">
                                    <Skeleton variant="text" width={200} />
                                </Typography>

                                <Typography variant="body2" color="text.primary">
                                    <Skeleton variant="text" width={200} />
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Box>

                <Box sx={{ my: 10 }}>
                    <Skeleton variant="rectangular" height={280} />
                </Box>

                <Box sx={{ my: 10 }}>
                    <Skeleton variant="rectangular" height={180} />
                </Box>
            </Container>
        </Box>
    );
};

const Collections = () => {
    const dispatch = useDispatch();
    const { experiences } = useSelector((state) => state.experiences);
    const [collectionsData, setCollectionsData] = useState([]);
    const [collection, setCollection] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        (async () => {
            try {
                const { data } = await collectionsApi.getCollection(id);
                if (data.success) {
                    setCollectionsData(data?.data);
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [id]);

    useEffect(() => {
        if (!experiences) {
            dispatch(getExperiences());
        } else {
            const expIds = collectionsData?.map((collection) => {
                return collection?.experience?.id;
            });
            setCollection(
                experiences?.filter((experience) => expIds?.includes(experience?.id))
            );
        }
    }, [dispatch, experiences, collectionsData]);

    return (
        <>
            <Helmet>
                <title>{`${collectionsData[0]?.collection.name} | EdOutdoors`}</title>
                <meta property="og:title" content={`${collectionsData[0]?.collection.name} | EdOutdoors`} />
                <link
                    rel="canonical"
                    href={`https://www.edoutdoors.in/collections/${collectionsData[0]?.id}`}
                />
            </Helmet>
            {collection?.length > 0 ? (
                <Box className={styles.collections}>
                    <Container>
                        <Typography
                            variant="h4"
                            sx={{
                                mt: 5,
                                textTransform: "capitalize",
                                fontWeight: 700,
                                fontSize: { xs: "22px", md: "24px" },
                            }}
                        >
                            {collectionsData[0]?.collection.name}
                        </Typography>
                        <Typography variant="subtitle2" mb={5} mt={1} ml={0.5}>
                            Showing {collectionsData?.length} results
                        </Typography>
                        <Box className={styles.listingWrapper}>
                            {collection.map((exp) => {
                                return (
                                    <Card
                                        component={Link}
                                        to={`/experience/${exp.id}`}
                                        sx={{
                                            maxWidth: 320,
                                            minHeight: 380,
                                            maxHeight: 380,
                                            borderRadius: "1.5rem",
                                            borderColor: "rgb(232, 232, 232)",
                                            boxShadow: {
                                                xs: "rgba(28, 28, 28, 0.12) 0 0 5px",
                                                sm: "rgba(28, 28, 28, 0.12) 0px 0.2rem 1.2rem",
                                            },
                                            display: "block",
                                            mx: { xs: 0.5 },
                                            "&:hover": {
                                                "& .MuiCardMedia-root img": {
                                                    transition: "all 0.3s",
                                                    transform: "scale(1.05)",
                                                },
                                            },
                                        }}
                                        key={exp.id}
                                    >
                                        <CardMedia component="div">
                                            <LazyImage
                                                src={exp.cover_photo}
                                                alt={exp.title}
                                                height="180"
                                                width="100%"
                                            />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {exp.title.length > 40
                                                    ? exp.title.slice(0, 40) + "..."
                                                    : exp.title}
                                            </Typography>

                                            <Typography
                                                gutterBottom
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {exp.highlight.length > 80
                                                    ? exp.highlight.slice(0, 80) + "..."
                                                    : exp.highlight}
                                            </Typography>

                                            <Typography
                                                variant="subtitle1"
                                                color="text.secondary"
                                                mt={1}
                                            >
                                                {exp?.duration === "1 Days"
                                                    ? exp?.duration?.replace("Days", "Day")
                                                    : exp?.duration}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                );
                            })}
                        </Box>
                    </Container>

                    <Box sx={{ my: 8 }}>
                        <Recommendations
                            expId={collectionsData[0]?.experience?.id}
                            collection={true}
                        />
                    </Box>

                    <Box sx={{ my: 8 }}>
                        <Banner />
                    </Box>
                </Box>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default Collections;
