import React, { useMemo, useEffect } from 'react'
import moment from 'moment'
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material'


// helper function to get the time array
const getTimeArray = (toursData, selectedDate) => {
    const ourDate = moment(selectedDate).format('YYYY-MM-DD') // the date is already selected 
    return toursData.filter((dateTime) => {
        const date = dateTime.start_date.split('T')[0]
        return date === ourDate
    }).map((time) => moment(time.start_date).format('HH:mm:ss'))
}

// helper function to get tourId
const getTourId = (toursData, selectedDate, selectedTime) => {
    const ourDate = moment(selectedDate).format('YYYY-MM-DD') // the date is already selected 
    return toursData.find(tour => {
        const date = tour.start_date.split('T')[0]
        const time = moment(tour.start_date).format('HH:mm:ss')
        return date === ourDate && time === selectedTime
    }).id
}

// Main component
const Time = ({ selectedTime, setSelectedTime, toursData, selectedDate, setTourId }) => {

    const handleChange = (event) => {
        setSelectedTime(event.target.value)
        setTourId(getTourId(toursData, selectedDate, event.target.value))
    }

    const timeOptions = useMemo(() => getTimeArray(toursData, selectedDate), [toursData, selectedDate])

    useEffect(() => {
        if (timeOptions?.length === 1) {
            setSelectedTime(timeOptions[0])
            setTourId(getTourId(toursData, selectedDate, timeOptions[0]))
        }
        //eslint-disable-next-line
    }, [timeOptions])

    return (
        <FormControl variant="standard" sx={{ mx: 1, width: '98%' }} >
            <InputLabel>Select time slot</InputLabel>
            <Select
                value={selectedTime}
                onChange={handleChange}
                aria-label="Select time slot"
            >
                {timeOptions.map((time, index) => (
                    <MenuItem key={index} value={time}>
                        {moment(time, 'HH:mm:ss').format('hh:mm A')}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default React.memo(Time)
