import { Backdrop, CircularProgress } from '@mui/material';

const BackdropLoading = ({ open }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="success" />
        </Backdrop>
    )
}

export default BackdropLoading