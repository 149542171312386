import {
    AppBar,
    Box,
    Toolbar,
    Menu,
    MenuItem,
    Avatar,
    Container,
    IconButton,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useEffect, useMemo, useCallback } from "react";
import styles from "./Header.module.css";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Submenu from "./Submenu";
import AuthModal from "../auth/AuthModal";
import HelpSlider from "../helpSlider/HelpSlider";
import { useSelector } from "react-redux";
import { LazyImage, SearchInput } from "../";
import Sidebar from "./Sidebar";
import CloseIcon from "@mui/icons-material/Close";
import { HideOnScroll } from "../";
import moengage from "@moengage/web-sdk";

const Header = () => {
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const { user } = useSelector((state) => state.auth);
    const [loggedIn, setLoggedIn] = useState(false);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [link, setLink] = useState(null);
    const [dropdown, setDropdown] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);

    const { experienceTypes } = useSelector((state) => state.exp_destination_types)
    const { subjects } = useSelector((state) => state.subjects)
    const navigate = useNavigate();

    const openProfile = Boolean(profileAnchorEl);

    const handleOpenMobileProfileMenu = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleCloseMobileProfileMenu = () => {
        setProfileAnchorEl(null);
    };

    const opendropdown = () => {
        setDropdown(true);
    };

    const closedropdown = () => {
        setDropdown(false);
    };

    const handleSignOut = () => {
        localStorage.removeItem("token");
        moengage.destroy_session();
        window.location.reload();
    };

    const menuCategroies = useMemo(() => {
        return [
            {
                id: 1,
                name: "All Categories",
                slug: "/experiences",
                subCategories: [
                    {
                        id: 1,
                        name: "Experience Themes",
                        slug: "/experiences",
                        img: "/images/leisure.png",
                        subCategories: experienceTypes
                    },
                    {
                        id: 2,
                        name: "Subjects",
                        slug: "/experiences",
                        img: "/images/campus-visit.png",
                        subCategories: subjects
                    },
                    {
                        id: 3,
                        name: "Duration",
                        img: "/images/trekking.png",
                        slug: "/experiences",
                        subCategories: [
                            {
                                name: "Singleday",
                                slug: "/experiences",
                            },
                            {
                                name: "Multiday",
                                slug: "/experiences",
                            },
                        ]
                    },

                ]
            },
            {
                id: 2,
                name: "Top School Trips",
                slug: "/collections/4",
            },
            {
                id: 3,
                name: "Summer Excursions",
                slug: "/collections/5",
            },
            {
                id: 4,
                name: "Industry Exposure",
                slug: "/collections/2",
            },
        ]
    }, [subjects, experienceTypes])

    const handleNavigate = useCallback((link) => {
        switch (currentCategory) {
            case "Experience Themes":
                navigate(`experiences?experience_type=${link.name}`)
                break

            case "Subjects":
                navigate(`experiences?subjects=${link.id}`)
                break

            case "Duration":
                navigate(`experiences?event=${link.name}`)
                break

            default:
                break
        }
        // eslint-disable-next-line
    }, [currentCategory])

    useEffect(() => {
        if (user) {
            setLoggedIn(true);
        }
    }, [user]);

    useEffect(() => {
        if (link && currentCategory) {
            handleNavigate(link)
        }
    }, [currentCategory, link, handleNavigate])

    return (
        // Mobile view
        window.innerWidth < 768 ? (
            <>
                <HideOnScroll direction="down">
                    <AppBar position="sticky" className={styles.navContainer} sx={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)" }}>
                        <Container maxWidth="xl">
                            <Toolbar disableGutters>
                                <Box className={styles.navbar}>
                                    {showMobileSearch ? (
                                        <Box className={styles.mobileSearch}>
                                            <SearchInput py="2" px="10" width="100%" />
                                            <IconButton onClick={() => setShowMobileSearch(false)}>
                                                <CloseIcon fontSize="large" />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        <>
                                            <Box className={styles.navLeft} sx={loggedIn ? { right: "10px" } : { right: "15px" }}>
                                                <IconButton onClick={() => setOpenSidebar(true)}>
                                                    <MenuIcon color="action" fontSize="large" />
                                                </IconButton>
                                                <Box className={styles.mobileLogo}>
                                                    <RouterLink to="/">
                                                        <LazyImage
                                                            src="/logo.webp"
                                                            alt="EdOutdoor Logo"
                                                        />
                                                    </RouterLink>
                                                </Box>
                                            </Box>


                                            <Box className={styles.navRight} sx={loggedIn ? { left: "15px" } : { left: "10px" }}>
                                                <IconButton
                                                    onClick={() => setShowMobileSearch(true)}
                                                    sx={{ position: "relative", left: "10px" }}
                                                >
                                                    <img
                                                        src="/images/search.png"
                                                        alt="EdOutdoors"
                                                        width={25}
                                                    />
                                                </IconButton>
                                                <HelpSlider parent="header" />
                                                {!loggedIn ? (
                                                    <AuthModal btn={true} />
                                                ) : (
                                                    <>
                                                        <Box
                                                            className={styles.userProfile}
                                                            sx={{ display: "flex", alignItems: "center" }}
                                                        >
                                                            <img
                                                                onClick={handleOpenMobileProfileMenu}
                                                                src="/images/user.png"
                                                                alt={user?.user?.email}
                                                                style={{ width: "25px", height: "25px" }}
                                                            />
                                                        </Box>
                                                        <Menu
                                                            anchorEl={profileAnchorEl}
                                                            id="account-menu"
                                                            open={openProfile}
                                                            onClose={handleCloseMobileProfileMenu}
                                                            onClick={handleCloseMobileProfileMenu}
                                                            PaperProps={{
                                                                elevation: 0,
                                                                sx: {
                                                                    overflow: "visible",
                                                                    filter:
                                                                        "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                    mt: 1.5,
                                                                    "& .MuiAvatar-root": {
                                                                        width: 32,
                                                                        height: 32,
                                                                        ml: -0.5,
                                                                        mr: 1,
                                                                    },
                                                                    "&:before": {
                                                                        content: '""',
                                                                        display: "block",
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        right: 14,
                                                                        width: 10,
                                                                        height: 10,
                                                                        bgcolor: "background.paper",
                                                                        transform: "translateY(-50%) rotate(45deg)",
                                                                        zIndex: 0,
                                                                    },
                                                                },
                                                            }}
                                                            transformOrigin={{
                                                                horizontal: "right",
                                                                vertical: "top",
                                                            }}
                                                            anchorOrigin={{
                                                                horizontal: "right",
                                                                vertical: "bottom",
                                                            }}
                                                        >
                                                            <MenuItem sx={{ mt: 1.5 }}>
                                                                <RouterLink
                                                                    to="/profile"
                                                                    className={styles.menuItemLink}
                                                                >
                                                                    My Profile
                                                                </RouterLink>
                                                            </MenuItem>

                                                            <MenuItem >
                                                                <RouterLink
                                                                    to="/history"
                                                                    className={styles.menuItemLink}
                                                                >
                                                                    History
                                                                </RouterLink>
                                                            </MenuItem>

                                                            <MenuItem>
                                                                <p
                                                                    onClick={handleSignOut}
                                                                    className={styles.menuItemSignOut}
                                                                >
                                                                    Sign Out
                                                                </p>
                                                            </MenuItem>
                                                        </Menu>
                                                    </>
                                                )}
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </HideOnScroll >
                <Sidebar
                    open={openSidebar}
                    handleClose={() => setOpenSidebar(false)}
                    menuCategroies={menuCategroies}
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                />
            </>
        ) : (
            // Desktop view
            <HideOnScroll direction="down">
                <AppBar position="sticky" className={styles.navContainer} sx={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)" }}>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Box className={styles.navbar}>
                                <Box className={styles.navLeft}>
                                    <RouterLink to="/">
                                        <LazyImage
                                            src="/logo.webp"
                                            alt="EdOutdoors"
                                        />
                                    </RouterLink>
                                </Box>

                                <Box className={styles.navSearch}>
                                    <SearchInput py="2" px="10" width="60%" />
                                </Box>

                                <Box className={styles.navRight}>
                                    <Box className={styles.navRightLinks}>
                                        <HelpSlider />
                                    </Box>
                                    {!loggedIn ? (
                                        <AuthModal btn={true} />
                                    ) : (
                                        <>
                                            <Box
                                                className={styles.userProfileBox}
                                                onMouseEnter={() => setShowProfileMenu(true)}
                                                onMouseLeave={() => setShowProfileMenu(false)}
                                            >
                                                <Box className={styles.userProfile}>
                                                    <Avatar
                                                        src={user?.avatar_url}
                                                        alt={user?.user?.name}
                                                    />
                                                </Box>
                                                {
                                                    showProfileMenu && (
                                                        <Box
                                                            className={styles.userProfileMenu}
                                                            onClick={() => setShowProfileMenu(false)}
                                                        >
                                                            <MenuItem sx={{ mt: 1.5 }}>
                                                                <RouterLink
                                                                    to="/profile"
                                                                    className={styles.menuItemLink}
                                                                >
                                                                    My Profile
                                                                </RouterLink>
                                                            </MenuItem>

                                                            <MenuItem >
                                                                <RouterLink
                                                                    to="/history"
                                                                    className={styles.menuItemLink}
                                                                >
                                                                    History
                                                                </RouterLink>
                                                            </MenuItem>

                                                            <MenuItem>
                                                                <p
                                                                    onClick={handleSignOut}
                                                                    className={styles.menuItemSignOut}
                                                                >
                                                                    Sign Out
                                                                </p>
                                                            </MenuItem>
                                                        </Box>
                                                    )
                                                }
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Toolbar>
                        <Box className={styles.navbarCategories}>
                            <Box className={styles.navbarCategoriesContainer}>
                                <Box className={styles.navbarCategoriesLinks}>
                                    {menuCategroies?.map((item) => {
                                        return item.name === "All Categories" ? (
                                            <button
                                                key={item.id}
                                                onMouseEnter={opendropdown}
                                                onMouseLeave={closedropdown}
                                                style={dropdown ? { color: "#1D9929" } : {}}
                                            >
                                                <MenuIcon className={styles.menuIcon} /> {item.name}
                                            </button>
                                        ) : (
                                            <RouterLink to={item.slug} key={item.id}>
                                                {item.name}
                                            </RouterLink>
                                        );
                                    })}
                                </Box>
                                {dropdown && (
                                    <Box
                                        className={styles.dropdownContainer}
                                        onMouseEnter={opendropdown}
                                        onMouseLeave={closedropdown}
                                    >
                                        <Box className={styles.dropdown}>
                                            {menuCategroies[0]?.subCategories?.map((item) => {
                                                return (
                                                    <Box
                                                        className={styles.dropdownItem}
                                                        key={item.id}
                                                        onClick={() => {
                                                            setCurrentCategory(item.name)
                                                            closedropdown()
                                                        }}>
                                                        <Box className={styles.dropdownItemLinks}>
                                                            <Typography variant="h6">
                                                                <LazyImage
                                                                    src={item.img}
                                                                    alt={item.name}
                                                                /> {item.name}
                                                            </Typography>
                                                        </Box>
                                                        <Box className={styles.dropdownItemContent}>
                                                            <Submenu
                                                                items={item.subCategories}
                                                                handleNavigate={handleNavigate}
                                                                setLink={setLink}
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Container>
                </AppBar>
            </HideOnScroll>
        )
    );
};

export default Header;
