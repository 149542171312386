import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Card from './Card';
import { useSelector } from 'react-redux';
import { Typography, Box, Container } from '@mui/material';
import styles from './styles.module.css';
import { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



const CustomPrevArrow = () => (
    <div className="swiper-button-prev"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/prevIcon.png"
            alt="Previous"
            className='arrows-sm'
            style={{ width: "100%" }}
        />
    </div>
);

const CustomNextArrow = () => (
    <div
        className="swiper-button-next"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
        }}
    >
        <img
            src="/images/nextIcon.png"
            alt="Next"
            className='arrows-sm'
            style={{ width: "100%" }}

        />
    </div>
);

const UpcomingExps = () => {
    const { upcomingExps, experiences } = useSelector((state) => state.experiences)

    const sortByDate = useCallback(
        (a, b) => new Date(a.start_date) - new Date(b.start_date),
        []
    );

    const data = useMemo(() => {
        return upcomingExps?.slice(0, 6).map((item) => {
            const matchingExp = experiences?.find((exp) => exp?.id === item?.experience?.id);
            const updatedExperience = {
                ...item?.experience,
                cover_photo: matchingExp?.cover_photo,
                experience_type: matchingExp?.experience_type,
            };
            return {
                ...item,
                experience: updatedExperience
            };
        })?.sort(sortByDate);
    }, [experiences, upcomingExps, sortByDate]);

    return (
        data?.length > 0 && (
            <Container>
                <Box className={styles.heading}>
                    <Typography variant="h2" gutterBottom>
                        Happening Soon
                    </Typography>
                    <Box className={styles.seeAllBtn}>
                        <Link to={`/upcoming-experiences`} >
                            See All
                        </Link>
                        <ArrowForwardIosIcon fontSize="small" />
                    </Box>
                </Box>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={0}
                    slidesPerView={window.innerWidth > 1000 ? 3 : window.innerWidth > 600 ? 2 : 1}
                    navigation={
                        {
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }
                    }
                    style={
                        window.innerWidth > 600 ? { padding: '5px 25px 5px 0' } : { padding: '5px 40px 5px 0' }
                    }

                >
                    <CustomPrevArrow />
                    <CustomNextArrow />
                    {
                        data?.map((item) => (
                            <SwiperSlide key={item.id}>
                                <Card item={item} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Container>
        )
    )
}

export default UpcomingExps