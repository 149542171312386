import {
    Home,
    Experiences,
    SingleExperience,
    ProfilePage,
    PartnerProfile,
    CheckOut,
    ThankYouPage,
    Collections,
    History,
    About,
    ErrorPage,
    ContactUs,
    PrivacyPolicy,
    TermsOfUse,
    RefundPolicy,
    Sitemap,
    Signup,
    UpcomingExps
} from "./containers"
import { ShowProfile } from "./components";
import { Routes, Route, Navigate } from 'react-router-dom'
import ScrollToTop from "./ScrollToTop";
import { getUser } from "./services/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getExperienceTypes, getDestinationTypes } from "./services/actions/exp_destination_types"
import { getSubjects } from "./services/actions/subjects"
import moengage from "@moengage/web-sdk";

const Router = () => {
    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);

    useEffect(() => {
        dispatch(getUser());
        dispatch(getExperienceTypes());
        dispatch(getDestinationTypes());
        dispatch(getSubjects())
        moengage.initialize({ app_id: process.env.REACT_APP_MOENGAGE_APP_ID, debug_logs: 0, enableSPA: true });
    }, [dispatch]);

    // Pushing Moengage Event
    useEffect(() => {
        if (auth.user) {
            moengage.add_unique_user_id(auth.user.user.id);
        }
    }, [auth.user])

    return (
        <>
            <ScrollToTop />
            <ShowProfile />
            <Routes>
                <Route
                    path="/"
                    element={<Home />}
                />

                <Route
                    path="/experiences"
                    element={<Experiences />}
                />

                <Route
                    path="/collections/:id"
                    element={<Collections />}
                />

                <Route
                    path="/experience/:id"
                    element={<SingleExperience />}
                />

                {
                    auth.user ? (
                        <Route
                            path="/profile"
                            element={<ProfilePage />}
                        />
                    ) : (
                        <Route
                            path="/profile"
                            element={<Home />}
                        />
                    )
                }

                {
                    auth.user ? (
                        <Route
                            path="/history"
                            element={<History />}
                        />
                    ) : (
                        <Route
                            path="/history"
                            element={<Home />}
                        />
                    )
                }

                <Route
                    path="/partner/:id"
                    element={<PartnerProfile />}
                />


                {
                    auth.user ? (
                        <Route
                            path="/checkout/confirm"
                            element={<ThankYouPage />}
                        />
                    ) : (
                        <Route
                            path="/checkout/confirm"
                            element={<Home />}
                        />
                    )
                }

                <Route
                    path="/checkout/:id"
                    element={<CheckOut />}
                />

                <Route
                    path="/about-us"
                    element={<About />}
                />

                <Route
                    path="/contact-us"
                    element={<ContactUs />}
                />

                <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicy />}
                />

                <Route
                    path="/terms-of-use"
                    element={<TermsOfUse />}
                />

                <Route
                    path="/refund-policy"
                    element={<RefundPolicy />}
                />

                <Route
                    path="/sitemap"
                    element={<Sitemap />}
                />

                {
                    auth.user ? (
                        <Route
                            path="/signup"
                            element={<Navigate to="/" replace={true} />}
                        />
                    ) : (
                        <Route
                            path="/signup"
                            element={<Signup />}
                        />
                    )
                }

                <Route
                    path="/upcoming-experiences"
                    element={<UpcomingExps />}
                />

                <Route
                    path="*"
                    element={<ErrorPage />}
                />
            </Routes>
        </>
    )
}

export default Router