import styles from './styles.module.css';
import { Typography, Box } from '@mui/material';
import { LazyImage } from "../"
import DateRangeIcon from '@mui/icons-material/DateRange';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Card = ({ item }) => {
    const navigate = useNavigate();
    return (
        <Box className={styles.card} onClick={() => navigate(`/experience/${item.experience.id}`)}>
            <Box className={styles.cardImg}>
                <LazyImage
                    src={item?.experience?.cover_photo}
                    alt={item?.experience?.title}
                    height="100%"
                    width="100%"
                />
            </Box>
            <Box className={styles.cardContent}>
                <Typography variant="body1" color="text.secondary">
                    {
                        item?.experience?.experience_type
                    }
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {item?.experience?.title}
                </Typography>
                <Box className={styles.cardDateDuration}>
                    <Box className={styles.cardDateDurationBox}>
                        <DateRangeIcon />
                        <Typography variant="h6" gutterBottom>
                            {moment(item?.start_date).format('DD MMM YYYY')}
                        </Typography>
                    </Box>
                    <Box className={styles.cardDateDurationBox}>
                        <AvTimerIcon />
                        <Typography variant="h6" gutterBottom>
                            {item?.experience?.duration === "1 Days" ? item?.experience?.duration?.replace("Days", "Day") : item?.experience?.duration}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Card