import {
    Box,
    Typography,
    Modal,
    Backdrop,
    Fade,
    IconButton,
    Button
} from "@mui/material";
import styles from "./styles.module.css"
import CloseIcon from '@mui/icons-material/Close';

const WelcomePopup = ({ open, handleClose, childName }) => {

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box className={styles.modal}>
                    <Box className={styles.head}>
                        <Box className={styles.closeBtn}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box className={styles.body}>
                        <Box className={styles.image}>
                            <img src="/images/welcome.webp" alt="EdOutdoors" />
                        </Box>

                        <Box className={styles.text}>
                            <Typography variant="h5">
                                Welcome Onboard!
                            </Typography>

                            <Typography variant="h6">
                                We can't wait to see your child, {childName}, discover new places and ideas with us!
                            </Typography>
                        </Box>

                        <Box className={styles.btn}>
                            <Button
                                onClick={handleClose}
                                variant="standard"
                                sx={{
                                    borderColor: "#1D9929",
                                    backgroundColor: "#1D9929",
                                    color: "#fff",
                                    border: "1px solid #1D9929",
                                    '&:hover': {
                                        color: "#1D9929",
                                        backgroundColor: "transparent",
                                    }
                                }}
                            >
                                Let's Go!
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default WelcomePopup;