export const data = [
    {
        title:"4 Ways to Plan STEM Education in School Trips check here",
        desc:"Welcome to the exciting world of STEM education! STEM, which stands for Science, Technology, Engineering, and Mathematics, is an interdisciplinary approach to learning that nurtures curiosity, creativity, and innovation. Now, imagine combining this powerful learning method with the thrill of school trips and educational tours.",
        img:"/images/blogs/blogImg1.webp",
        url:"https://blog.edoutdoors.in/incorporating-stem-education-into-school-trips/"
    },
    {
        title:"Top 7 Historical Sites in India for Educational Tours",
        desc:"Ever wondered how we can transform history from a dull subject to an exciting adventure? We have studied all places of historical significance and curated the 7 best spots you can take kids and spike their interest in the subject. Whether it's via a school trip or family tour, we recommend that children experience the profoundness of these places in person.",
        img:"/images/blogs/blogImg2.webp",
        url:"https://blog.edoutdoors.in/top-7-historical-sites-in-india-for-educational-tours/"
    },
    {
        title:"Roaming with Rhinos: Why Kids Should Visit Kaziranga National Park",
        desc:"Welcome to Kaziranga National Park, a wildlife paradise and home to the majestic one-horned rhinoceros. Nestled in the lush landscapes of Assam, India, this UNESCO World Heritage Site offers an unparalleled opportunity for kids to connect with nature, encounter diverse wildlife, and learn valuable lessons about conservation. Join us as we embark on a thrilling journey through Kaziranga",
        img:"/images/blogs/blogImg3.webp",
        url:"https://blog.edoutdoors.in/roaming-with-rhinos-why-kids-should-visit-kaziranga-national-park/"
    },
]