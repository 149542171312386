import React from 'react';
import App from './App';
import store from './services/store/store';
import { Provider } from 'react-redux';
import { hydrateRoot } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
} else {
  createRoot(rootElement).render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
}