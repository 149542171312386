import { Container, Box } from '@mui/material'
import styles from './Banner.module.css'
import LazyImage from '../lazyImage/LazyImage'

const Banner = () => {
    return (
        <Container
            className={styles.banner}
            onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfo1VcoxgWLRQAPaWqkEDhVUuxIRqTDQhW4OCahRTQy_3vzcA/viewform", "_blank")}
        >
            <LazyImage
                src={window.innerWidth > 768 ? "/homeBanner.webp" : "/homeBannerMob.webp"}
                alt="EdOutdoors Banner"
            />

            <Box className={styles.btns}>
                <button type='button' onClick={() => window.open("https://drive.google.com/file/d/1hKrFxxQnB8RDcAeGrRu7fr0ACIpzVbuu/view", "_blank")}>
                    View School Brochure
                </button>
            </Box>
        </Container>
    )
}

export default Banner