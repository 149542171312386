import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Chip } from '@mui/material';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const hasFiltersApplied = (appliedFilters, location) => {
  if (location) return true
  return Object.values(appliedFilters).some(
    (array) => Array.isArray(array) && array.length
  );
};

const OtherFilters = ({ handleOpenFilterDrawer, resetFilters, appliedFilters }) => {
  const location = useLocation();
  const isFiltered = useMemo(() => hasFiltersApplied(appliedFilters, location?.search), [appliedFilters, location?.search]);
  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={window.innerWidth > 768 ? 50 : 30}
      slidesPerView={window.innerWidth > 1000 ? 6 : window.innerWidth > 700 ? 4 : window.innerWidth > 600 ? 3 : 2}
      navigation={false}
      style={
        window.innerWidth > 768 ?
          {
            padding: '5px 20px'
          } : {

            padding: '5px 50px 5px 10px'
          }
      }
    >
      <SwiperSlide>
        <Chip
          label="All Filters"
          onClick={handleOpenFilterDrawer}
          variant={isFiltered ? 'default' : 'outlined'}
          color="warning"
          clickable
          sx={
            isFiltered ? {
              minWidth: '100%',
              minHeight: '35px',
              fontWeight: 'bold',
              boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;'
            } : {
              minWidth: '100%',
              minHeight: '35px'
            }
          }
        />
      </SwiperSlide>

      {
        isFiltered && (
          <SwiperSlide>
            <Chip
              label="Reset Filters"
              onClick={resetFilters}
              variant="outlined"
              color="error"
              sx={{ minWidth: '100%', minHeight: '35px' }}
              clickable
            />
          </SwiperSlide>
        )
      }
    </Swiper>
  );
};

export default OtherFilters;
