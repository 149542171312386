import styles from "./styles.module.css";
import { Typography, Box, Grid } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { LazyImage } from "../../components"

const Card = ({ item }) => {
    const navigate = useNavigate();
    return (
        <Box
            className={styles.card}
            onClick={() => navigate(`/experience/${item.experience.id}`)}
        >
            <Grid container sx={{ height: "100%" }}>
                <Grid item xs={5} md={4}>
                    <Box className={styles.left}>
                        <LazyImage
                            src={item?.experience?.cover_photo}
                            alt={item?.experience?.title}
                            height="100%"
                        />
                    </Box>
                </Grid>
                <Grid item xs={7} md={8}>
                    <Box className={styles.right}>
                        <Typography variant="h6" mb={0}>
                            {item?.experience?.title}
                        </Typography>
                        <Typography variant="p" color="text.secondary">
                            {item?.experience?.experience_type}
                        </Typography>
                        {
                            window.innerWidth > 600 && (
                                <Box className={styles.highlight}>
                                    <Typography variant="body1" color="text.secondary">
                                        {item?.experience?.highlight}
                                    </Typography>
                                </Box>
                            )
                        }
                        <Box className={styles.details}>
                            <Box className={styles.detail}>
                                <DateRangeIcon />
                                <Typography variant="body2">
                                    {moment(item?.start_date).format("MMM DD, YYYY")}
                                </Typography>
                            </Box>
                            <Box className={styles.detail}>
                                <AvTimerIcon />
                                <Typography variant="body2">
                                    {item?.experience?.duration}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Card;
