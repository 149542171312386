import {
    Box,
} from "@mui/material";
import styles from "./ImageSlider.module.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import LazyImage from "../lazyImage/LazyImage";

const CustomPrevArrow = () => (
    <div className="swiper-button-prev"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
        }}
    >
        <img
            src="/images/prevIcon.png"
            alt="Previous"
            className='arrows-sm'
            style={{ width: "100%" }}
        />
    </div>
);

const CustomNextArrow = () => (
    <div
        className="swiper-button-next"
        style={{
            background: "#fff",
            padding: "5px",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
        }}
    >
        <img
            src="/images/nextIcon.png"
            alt="Next"
            className='arrows-sm'
            style={{ width: "100%" }}

        />
    </div>
);


const ImageSlider = (props) => {
    return (
        <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            slidesPerView={1}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            }}
            pagination={{ clickable: true }}
            loop={props?.images?.length > 1 ? true : false}
        >
            {
                props?.images?.length > 1 && (
                    <>
                        <CustomPrevArrow />
                        <CustomNextArrow />
                    </>
                )
            }
            {
                props.images && (
                    props.images.map((item) => {
                        return (
                            <SwiperSlide key={item.id}>
                                <Box className={styles.imageSlider}>
                                    <LazyImage
                                        src={item.image_url}
                                        alt="EdOutdoors"
                                        width="100%"
                                        height="100%"
                                    />
                                </Box>
                            </SwiperSlide>
                        )
                    })
                )
            }
        </Swiper>
    )
}

export default ImageSlider