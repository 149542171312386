import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const TermsOfUse = () => {
    return (
        <>
            <Helmet>
                <title>Terms Of Use | EdOutdoors</title>
                <meta property="og:title" content="Terms Of Use | EdOutdoors" />
                <link rel="canonical" href="https://edoutdoors.in/terms-of-use" />
            </Helmet>
            <Container maxWidth="md">
                <Box mt={3}>
                    <Box>
                        <Typography variant="h4" gutterBottom>
                            Terms and conditions
                        </Typography>

                        <Typography variant="body1" paragraph>
                            This web site (the “Site”) is published and maintained by Globalshaala Travel (hereafter referred to as “Globalshaala”), a company incorporated and existing in accordance with the laws of the Republic of India. When you access, browse or use this Site you accept, without limitation or qualification, the terms and conditions set forth below. When you access any sub-site (whether belonging to an ‘associate’ of Globalshaala or otherwise) through this site, then such sub-site may have its own terms and conditions of use which is specific to such sub-site. Sub-sites may contain such additional terms and conditions of use as may be set out in such sub-site.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            These Terms and Conditions of Use and any additional terms posted on this Site together constitute the entire agreement between Globalshaala and you with respect to your use of this Site.
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Site and its contents
                        </Typography>

                        <Typography variant="body1" paragraph>
                            This Site is only for your personal use. You shall not distribute, exchange, modify, sell or transmit anything you copy from this Site, including but not limited to any text, images, audio and video, for any business, commercial or public purpose.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            As long as you comply with the terms of these Terms and Conditions of Use, Globalshaala grants you a non-exclusive, non-transferable, limited right to enter, view and use this Site. You agree not to interrupt or attempt to interrupt the operation of this Site in any way.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Access to certain areas of the Site may only be available to registered members. To become a registered member, you may be required to answer certain questions. Answers to such questions may be mandatory and/or optional. You represent and warrant that all information you supply to us, about yourself, and others, is true and accurate.
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Ownership
                        </Typography>

                        <Typography variant="body1" paragraph>
                            All materials on this Site, including but not limited to audio, images, software, text, icons and such like (the “Content”), are protected by copyright under international conventions and copyright laws. You cannot use the Content, except as specified herein. You agree to follow all instructions on this Site limiting the way you may use the Content.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            There are a number of proprietary logos, service marks and trademarks found on this Site whether owned/used by Globalshaala or otherwise. By displaying them on this Site, Globalshaala is not granting you any license to utilize those proprietary logos, service marks, or trademarks. Any unauthorized use of the Content may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statutes.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            You may download such copy/copies of the Content to be used only by you for your personal use at home unless the sub-site you are accessing states that you may not. If you download any Content from this Site, you shall not remove any copyright or trademark notices or other notices that go with it.
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Others’ rights
                        </Typography>

                        <Typography variant="body1" paragraph>
                            If this Site contains bulletin boards, chat rooms, access to mailing lists or other message or communication facilities, you agree to use the same only to send and receive messages and materials that are proper and related thereto. By way of example and not as a limitation, you agree that when using the Site or any facility available here from, you shall not do any of the following:
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others
                            Publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent or unlawful material or information.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Upload or attach files that contain software or other material protected by intellectual property laws (or by rights of privacy and publicity) unless the User owns or controls the rights thereto or has received all consents there for as may be required by law.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Upload or attach files that contain viruses, corrupted files or any other similar software or programs that may damage the operation of another’s computer.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Delete any author attributions, legal notices or proprietary designations or labels in any file that is uploaded.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Falsify the origin or source of software or other material contained in a file that is uploaded
                            Advertise or offer to sell any goods or services, or conduct or forward surveys, contests or chain letters, or download any file posted by another user of a Forum that the User knows, or reasonably should know, cannot be legally distributed in such manner.
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            User’s material
                        </Typography>

                        <Typography variant="body1" paragraph>
                            You are prohibited from posting or transmitting any defamatory, libelous, obscene, pornographic, profane, threatening or unlawful material or any material that could constitute or encourage conduct that would be considered a criminal offense or give rise to civil liability, or otherwise violate any law.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala assumes no liability or responsibility arising from the contents of any communications containing any defamatory, erroneous, inaccurate, libelous, obscene or profane material. Globalshaala may change, edit, or remove any user material or conversations that are illegal, indecent, obscene or offensive, or that violates Globalshaala’s policies in any way.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala will fully cooperate with any law enforcement authorities or court order requesting or directing Globalshaala to disclose the identity of anyone posting such materials.
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Globalshaala rights
                        </Typography>

                        <Typography variant="body1" paragraph>
                            If you send any communications or materials to the Site by electronic mail or otherwise, including any comments, data, questions, suggestions or the like, all such communications are, and will be treated by Globalshaala, as non-confidential.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            You hereby give up any and all claim that any use of such material violates any of your rights including moral rights, privacy rights, proprietary or other property rights, publicity rights, rights to credit for material or ideas, or any other right, including the right to approve the way Globalshaala uses such material.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Any material submitted to this Site may be adapted, broadcast, changed, copied, disclosed, licensed, performed, posted, published, sold, transmitted or used by Globalshaala anywhere in the world, in any medium, forever.
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Transmitted material
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Internet transmissions are never completely private or secure. You understand that any message or information you send to this Site may be read or intercepted by others unless there is a special notice that a particular message (for example, credit card information) is encrypted (send in code). Sending a message to Globalshaala does not cause Globalshaala to have any special responsibility to you.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            The copyright in the contents of this website belong to Globalshaala. Accordingly Globalshaala reserves all rights. Copying of part or all the contents of this website without permission of Globalshaala is prohibited except to the extent that such copying/printing is necessary for the purposes of availing of the paid services provided.
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Contests and interactions
                        </Typography>

                        <Typography variant="body1" paragraph>
                            This Site may contain contests that require you to send in material or information about yourself or offer prizes. Each contest has its own rules, which you must read and agree to before you participate.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            The material in this Site could include technical inaccuracies or typographical errors. Globalshaala may make changes or improvements at any time.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            The materials on this site are provided on an “As Is” basis, without warranties of any kind either expressed or implied. Information, software, products, and services contained on this website, and the inclusion or offering of any products or services on this website does not constitute any endorsement or recommendation of such products or services by Globalshaala, Globalshaala Affiliates and their respective suppliers. Globalshaala, Globalshaala Affiliates and their respective suppliers make no guarantees about the availability of specific products and services. Globalshaala may make improvements and/or changes on this website at any time.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala does not warrant that the functions contained in this site will be uninterrupted or error free, that defects will be corrected, or that this site or the servers that make it available are free of viruses or other harmful components, but shall endeavour to ensure your fullest satisfaction.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala does not warrant or make any representations regarding the use of or the result of the use of the material on the site in terms of their correctness, accuracy, reliability, or otherwise, insofar as such material is derived from other service providers such as railways, hotel owners.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            You acknowledge that this Website is provided only on the basis set out in these terms and conditions. Your uninterrupted access or use of this Website on this basis may be prevented by certain factors outside our reasonable control including, without limitation, the unavailability, inoperability or interruption of the Internet or other telecommunications services or as a result of any maintenance or other service work carried out on this Website. Globalshaala does not accept any responsibility and will not be liable for any loss or damage whatsoever arising out of or in connection with any ability/inability to access or to use the Site.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            You also acknowledge that through this Site, Globalshaala merely provides intermediary services in. You also acknowledge that through this Site, Globalshaala merely provides intermediary services in order to facilitate highest quality services to you. Globalshaala is not the last-mile service provider to you and therefore, Globalshaala shall not be or deemed to be responsible for any lack or deficiency of services provided by any person (airline, travel/tour operator, hotel, facility or similar agency) you shall engage or hire or appoint pursuant to or resulting from, the material available in this Site. Globalshaala, Globalshaala Affiliates, and their respective suppliers or partners hereby disclaim all warranties and conditions with regard to this information, software, products, and services, including all implied warranties and conditions of merchantability, satisfactory quality, and fitness for a particular purpose, title, and non-infringement.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala will not be liable to you or to any other person for any direct, indirect, incidental, punitive or consequential loss, damage, cost or expense of any kind whatsoever and howsoever caused from out of your usage of this Site. In no event shall Globalshaala, Globalshaala Affiliates ,and their respective suppliers or partners be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising out of, or in any way connected with, your access to, display of or use of this website or with the delay or inability to access, display or use this website (including, but not limited to, your reliance upon opinions appearing on this web site; any computer viruses, information, software, linked sites, products, and services obtained through this website; or otherwise arising out of the access to, display of or use of this website) whether based on a theory of negligence, contract, tort, strict liability, or otherwise, and even if the Globalshaala group companies, the Globalshaala Affiliates and their respective suppliers or partners have been advised of the possibility of such damages.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            You agree to defend and indemnify Globalshaala, Globalshaala Affiliates, and their respective suppliers or partners and any of their officers, directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Your breach of this Agreement or the documents referenced herein; or
                            Your violation of any law or the rights of a third party
                            Notwithstanding anything else to the contrary contained elsewhere herein or otherwise at law, Globalshaala’s liability (whether by way of indemnification to you or otherwise) shall be restricted to a maximum of INR 1000 (Indian Rupees One Thousand only).
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Availability
                        </Typography>

                        <Typography variant="body1" paragraph>
                            The products and services displayed on the Availability.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            The products and services displayed on the Site may not be available for purchase in your particular country or locality. The reference to such products and services on the Site does not imply or warrant that these products or services will be available at any time in your particular geographical location. You should check with your local Globalshaala authorized representative for the availability of specific products and services in your area.
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            Terms and conditions of use
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala may add to, change or remove any part of these Terms and Conditions of Use at any time, without notice. Any changes to these Terms and Conditions of Use or any terms posted on this Site apply as soon as they are posted. By continuing to use this Site after any changes are posted, you are indicating your acceptance of those changes.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala may add, change, discontinue, remove or suspend any other Content posted on this Site, including features and specifications of products described or depicted on the Site, temporarily or permanently, at any time, without notice and without liability.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala reserves the right to undertake all necessary steps to ensure that the security, safety and integrity of Globalshaala’s systems as well as its client’s interests are and remain, well-protected.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Towards this end, Globalshaala may take various steps to verify and confirm the authenticity, enforceability and validity of orders placed by you.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            If Globalshaala, in its sole and exclusive discretion, concludes that the said orders are not or do not reasonably appear to be, authentic, enforceable or valid, then Globalshaala may cancel the said orders at any time up to 5 hours before the scheduled time of departure of the relevant flight or 5 hours before the expected date of visit to any property booked through Globalshaala.
                        </Typography>
                    </Box>

                    <Box mt={4}>
                        <Typography variant="h4" gutterBottom>
                            General provisions
                        </Typography>

                        <Typography variant="body1" paragraph>
                            You may travel to certain destinations which involve greater risks than others, entirely at your risk as to cost and consequences.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            By offering for sale travel to particular international destinations, Globalshaala does not represent or warrant that travel to such point is advisable or without risk. Globalshaala does not accept liability for damages, losses, or delays that may result from improper documents for entry, exit, length of stay, or from travel to such destinations.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala, Globalshaala Affiliates and their respective suppliers or partners are not liable for the acts, errors, omissions, representations, warranties, breaches or negligence of any air carriers, hotels or other suppliers or for any personal injuries, death, property damage, or other damages or expenses resulting there from. Globalshaala, Globalshaala Affiliates and their respective suppliers or partners have no liability and will make no refund in the event of any delay, cancellation, overbooking, strike, force majeure or other causes beyond their direct control, and they have no responsibility for any additional expense, omissions, delays, re-routing or acts of any government or authority.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala reserves its exclusive right in its sole discretion to alter, limit or discontinue the Site or any material posted herein, in any respect. Globalshaala shall have no obligation to take the needs of any User into consideration in connection therewith.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            Globalshaala reserves its right to deny in its sole discretion any user access to this Site or any portion hereof without notice.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            You agree to abide by the terms and conditions of purchase imposed by any supplier with whom you elect to deal, including, but not limited to, payment of all amounts when due and with the supplier’s rules and restrictions regarding availability and use of fares, products, or services. You acknowledge that some third-party providers offering certain services and/or activities may require you to sign their liability waiver prior to participating in the service and/or activity they offer. You understand that any violation of any such supplier’s rules and restrictions may result in cancellation of your reservation(s), in your being denied access to the applicable travel product or services, in your forfeiting any monies paid for such reservation(s), and/or in Globalshaala debiting your account for any costs we incur as a result of such violation.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            No waiver by Globalshaala of any provision of these Terms and Conditions shall be binding except as set forth in writing and signed by its duly authorized representative.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            If any dispute arises between you and Globalshaala during your use of the Site or thereafter, in connection with and arising from your use or attempt to use this Site, the dispute shall be referred to arbitration. The place of arbitration shall be Bangalore. The arbitration proceedings shall be in the English language.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            The said arbitration proceedings shall be governed and construed in accordance with the Arbitration and Conciliation Act, 1996 and modifications thereof as in force at the relevant time.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            These terms and conditions are governed by and shall be construed in accordance with the laws of the Republic of India and any dispute shall exclusively be subject to the jurisdiction of the appropriate Courts situated at Bangalore, Karnataka, India.
                        </Typography>
                    </Box>

                </Box>
            </Container>
        </>
    );
};

export default TermsOfUse;
