import {
    GET_EXPERIENCE_TYPES,
    GET_EXPERIENCE_TYPES_SUCCESS,
    GET_EXPERIENCE_TYPES_FAILURE,
    GET_DESTINATION_TYPES,
    GET_DESTINATION_TYPES_SUCCESS,
    GET_DESTINATION_TYPES_FAILURE,
} from '../actions/exp_destination_types';

import { exp_destination_typesApi } from '../api';
import { call, put, takeEvery } from 'redux-saga/effects';

function* getExperienceTypes() {
    try {
        const response = yield call(exp_destination_typesApi.getAllExperienceTypes);
        yield put({ type: GET_EXPERIENCE_TYPES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_EXPERIENCE_TYPES_FAILURE, payload: error });
    }
}

function* getDestinationTypes() {
    try {
        const response = yield call(exp_destination_typesApi.getAllDestinationTypes);
        yield put({ type: GET_DESTINATION_TYPES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_DESTINATION_TYPES_FAILURE, payload: error });
    }
}

export default function* getExpDestinationsTypesSaga() {
    yield takeEvery(GET_EXPERIENCE_TYPES, getExperienceTypes);
    yield takeEvery(GET_DESTINATION_TYPES, getDestinationTypes);
}