import { BrowserRouter } from "react-router-dom"
import "./App.css"
import { Footer, Header } from "./components"
import Router from "./Router"

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Router />
        <Footer />
      </BrowserRouter>
    </div>
  )
}