import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | EdOutdoors</title>
                <meta property="og:title" content="Privacy Policy | EdOutdoors" />
                <link rel="canonical" href="https://edoutdoors.in/privacy-policy" />
            </Helmet>

            <Container maxWidth="md">
                <Box mt={3}>
                    <Typography variant="h4" gutterBottom>
                        PRIVACY POLICY
                    </Typography>

                    <Typography variant="body1" paragraph>
                        THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER THE INFORMATION TECHNOLOGY ACT, 2000 AND THE RULES MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THIS PRIVACY POLICY DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        HIS DOCUMENT IS PUBLISHED AND SHALL BE CONSTRUED IN ACCORDANCE WITH THE PROVISIONS OF THE INFORMATION TECHNOLOGY (REASONABLE SECURITY PRACTICES AND PROCEDURES AND SENSITIVE PERSONAL DATA OF INFORMATION) RULES, 2011 UNDER INFORMATION TECHNOLOGY ACT, 2000; THAT REQUIRE PUBLISHING OF THE PRIVACY POLICY FOR COLLECTION, USE, STORAGE AND TRANSFER OF SENSITIVE PERSONAL DATA OR INFORMATION.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        THIS PRIVACY POLICY IS A LEGALLY BINDING DOCUMENT BETWEEN YOU AND GLOBALSHAALA (BOTH TERMS DEFINED BELOW). THE TERMS OF THIS PRIVACY POLICY WILL BE EFFECTIVE UPON YOUR ACCEPTANCE OF THE SAME (DIRECTLY OR INDIRECTLY IN ELECTRONIC FORM, BY CLICKING ON THE "I ACCEPT" TAB OR BY USE OR THE PLATFORM OR BY OTHER MEANS) AND WILL GOVERN THE RELATIONSHIP BETWEEN GLOBALSHAALA AND YOU FOR YOUR USE OF THE PLATFORM.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING THE PLATFORM, YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT USE THIS PLATFORM. YOU HEREBY PROVIDE YOUR UNCONDITIONAL CONSENT OR AGREEMENT TO GLOBALSHAALA AS PROVIDED UNDER SECTION 43A AND SECTION 72A OF THE INFORMATION TECHNOLOGY ACT, 2000 AND THE INFORMATION TECHNOLOGY (INTERMEDIARY GUIDELINES) RULES, 2011.
                    </Typography>

                    <Typography variant="body2" paragraph>
                        Globalshaala Travel (referred as “GlobalShaala” or “We” or “Us” or “Our") takes privacy very seriously. We know how important personal information is to the user of the platform (referred as "You" or "Your" or "Yourself" or "User") and by sharing this information We appreciate your trust in us.

                    </Typography>

                    <Typography variant="body2" paragraph>
                        By providing Your Information or by making use of the facilities provided by the platform, You hereby consent to the collection, storage, processing and transfer of any or all of Your Personal Information and Non-Personal Information by GlobalShaala as specified under this Privacy Policy. You further agree that such collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to You or any other person.
                    </Typography>

                    <Typography variant="body2" paragraph>
                        This Privacy Policy is intended to help the User fully understand what information We collect, why We collect it and how it is being managed. This document describes GlobalShaala’s Privacy Policy. By visiting edoutdoors.in and/or the platform, the User is accepting all the terms, conditions, duties and liabilities that are described below. Capitalized terms that are not defined in this Privacy Policy have the meaning given in our Terms of Service.
                    </Typography>

                    <Typography variant="body2" paragraph>
                        This Privacy Policy lays out the following:
                    </Typography>

                    <Box pl={4}>
                        <ol>
                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Collection and Storage of Information
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Purpose for Collecting, Using, Storing and Processing of Information
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Sharing and Disclosure of Your Information
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Link to other platforms
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Security
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Children’s Privacy
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Data Protection Rights
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Choice/Opt-Out
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Data Retention
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Updates to this Privacy Policy
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Grievance Officer
                                </li>
                            </Typography>

                        </ol>

                    </Box>

                </Box>

                <Typography variant="h4" my={3} >
                    Collection and storage of information
                </Typography>

                <Box pl={4}>
                    <ol start={12}>
                        <Typography variant="body2" mb={0.4}>
                            <li>
                                The Information (which shall also include data) provided by You to GlobalShaala or collected from You by GlobalShaala may consist of "Personal Information" and "Non-Personal Information".
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                Personal Information is Information collected that can be used to uniquely identify or contact You. Personal Information for the purposes of this Privacy Policy shall include, but not be limited to Your name, address, contact number, e-mail addresses or other contact information, Information regarding Your transactions on the platform, Your financial information, Internet Protocol address, Any other items of "sensitive personal data or information" as such term is defined under the Information Technology (Reasonable Security Practices And Procedures And Sensitive Personal Data Of Information) Rules, 2011 enacted under the Information Technology Act, 2000, Identification code of Your communication device which You use to access the platform or any other Information that You provide during Your registration process, if any, on the platform.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                Such Personal Information may be collected in various ways including during the course of You registering as a user on the platform, or availing certain services offered on the platform. Such instances include but are not limited to participating in any online survey or contest or otherwise or posting user reviews or otherwise doing business on the platform.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                We may receive Personal information about You from third parties, such as social media services, commercially available sources and business partners. If you access the platform through a social media service or connect a service on the platform to a social media service, the information We collect may include Your user name associated with that social media service, any information or content the social media service has the right to share with Us, such as Your profile picture, email address or friends list, and any information You have made public in connection with that social media service. When You access the platform through social media services or when You connect platform to social media services, You are authorizing GlobalShaala to collect, store, and use and retain such information and content in accordance with this Privacy Policy.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                GlobalShaala may also collect information other than Personal Information from You through the platform when You visit and / or use the platform. Such information may be stored in server logs. This Non-Personal Information may include Your geographic location, details of Your telecom service provider or internet service provider, the type of browser (Internet Explorer, Firefox, Opera, Google Chrome etc.), the operating system of Your system, device and the platform You last visited before visiting the platform or the duration of Your stay on the platform is also stored in the session along with the date and time of Your access.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                Non-Personal Information is collected through various ways such through the use of cookies. GlobalShaala may store temporary or permanent "cookies" on Your computer. You can erase or choose to block these cookies from Your computer. You can configure Your computer’s browser to alert You when We attempt to send You a cookie with an option to accept or refuse the cookie. If You have turned cookies off, You may be prevented from using certain features of the platform.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                Please do note that Personal Information and Non Personal Information may be treated different as per this Privacy Policy.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                GlobalShaala and its officers, directors, contractors or agents shall not be responsible for the authenticity of the Information that You or any other user provides to GlobalShaala. You shall indemnify and hold harmless GlobalShaala and its officers, directors, contractors or agents and any third party relying on the Information provided by You in the event You are in breach of this Privacy Policy including this provision and the immediately preceding provision above.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                Your Information will primarily be stored in electronic form however certain data can also be stored in physical form. We may store, collect, process and use your data in countries other than the Republic of India but under compliance with applicable laws. We may enter into agreements with third parties (in or outside of India) to store or process your information or data. These third parties may have their own security standards to safeguard your information or data and we will on a commercial reasonable basis require such third parties to adopt reasonable security standards to safeguard your information / data.
                            </li>
                        </Typography>


                        <ol start={21}>
                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    You hereby represent to GlobalShaala that:
                                </li>
                            </Typography>
                        </ol>
                        <ol>
                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    the Information You provide from time to time is and shall be authentic, correct, current and updated and You have all the rights, permissions and consents as may be required to provide such Information to GlobalShaala.
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Your providing the Information to GlobalShaala and GlobalShaala's consequent storage, collection, usage, transfer, access or processing of the same shall not be in violation of any third party agreement, laws, charter documents, judgments, orders and decrees.
                                </li>
                            </Typography>
                        </ol>

                    </ol>

                </Box>

                <Typography variant="h4" my={3} >
                    Purpose for collecting, using, storing and processing of information
                </Typography>

                <Typography variant="subtitle" >
                    GlobalShaala collects, uses, stores and processes Your Information for any purpose as may be permissible under applicable laws (including where the applicable law provides for such collection, usage, storage or processes in accordance with the consent of the user) and shall include the following:
                </Typography>

                <Box pl={4} mt={3}>
                    <ol>
                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to facilitate Your use of the platform;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to respond to Your inquiries or fulfill Your requests for information about the various services offered on the platform;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to provide You with information about services available on the platform and to send You information, materials, and offers;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to send You important information regarding the platform, changes in terms and conditions, user agreements, and policies and/or other administrative information;

                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to send You surveys and marketing communications;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to personalize Your experience on the platform by presenting advertisements, services and offers tailored to Your preferences;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to help You address Your problems incurred on the platform including addressing any technical problems;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                if You avail of any service from the platform, to complete and fulfill the same.
                                to protect the integrity and for proper administering of the platform;

                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to conduct internal reviews and data analysis for the platform;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to improve the services and content on the platform;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to conducting academic research, surveys, analytical studies on various aspects including user behaviour, user preferences etc.;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to respond to legal, judicial, quasi-judicial process and provide information to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to implement information security practices;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to determine any security breaches, computer contaminant or computer virus;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to investigate, prevent, or take action regarding illegal activities and suspected fraud,
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to undertake forensics of the concerned computer resource as a part of investigation or internal audit;
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                to trace computer resources or any person who may have contravened, or is suspected of having or being likely to contravene, any provision of law including the Information Technology Act, 2000 that is likely to have an adverse impact on the services provided on any platform or by GlobalShaala; and
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={3}>
                            <li>
                                to enable a potential buyer or investor to evaluate the business of GlobalShaala.
                            </li>
                        </Typography>
                    </ol>
                </Box>

                <Typography variant="body2" paragraph>
                    [Individually and collectively referred to as ("Purposes")]
                </Typography>

                <Typography variant="body2" paragraph>
                    You hereby agree and acknowledge that the Information so collected is for lawful purpose connected with a function or activity of GlobalShaala or any person on its behalf, and the collection of Information is necessary for the Purposes.
                </Typography>

                <Typography variant="h4" my={3} >
                    Sharing and disclosure of your information
                </Typography>

                <Box pl={4}>
                    <ol>
                        <Typography variant="body2" mb={0.4}>
                            <li>
                                The Services are not available to You unless You hereby unconditionally agree and permit that GlobalShaala may transfer, share, disclose or part with all or any of Your Information, within and outside of the Republic of India to third party service providers / partners / banks and financial institutions for one or more of the Purposes or as may be required by applicable law.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                You acknowledge and agree that, to the extent permissible under applicable laws, it is adequate that when GlobalShaala transfers Your Information to any other entity within or outside Your country of residence, GlobalShaala will place contractual obligations on the transferee which will oblige the transferee to adhere to the provisions of this Privacy Policy.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                GlobalShaala may share statistical data and other details (other than Your Personal Information) without Your express or implied consent to facilitate various programmes or initiatives launched by GlobalShaala, its affiliates, agents, third party service providers, partners or banks & financial institutions, from time to time. We may transfer/disclose/share Information (other than Your Personal Information) to those parties who support Our business, such as providing technical infrastructure services, analyzing how Our services are used, providing services, facilitating payments, or conducting academic research and surveys. These affiliates and third party service providers shall adhere to confidentiality obligations consistent with this Privacy Policy. Notwithstanding the above, We use other third parties such as a credit/debit card processing company, payment gateway, pre-paid cards etc. to enable You to make payments for availing services. When You sign up for these services, You may have the ability to save Your card details for future reference and faster future payments. In such case, We may share Your relevant Personal Information as necessary for the third parties to provide such services, including Your name, residence and email address. The processing of payments or authorization is solely in accordance with these third parties policies, terms and conditions and We are not in any manner responsible or liable to You or any third party for any delay or failure at their end in processing the payments.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                We may also share Personal Information if We believe it is necessary in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of various terms and conditions or our policies.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                We reserve the right to disclose your information when required to do so by law or regulation, or under any legal obligation or order under law or in response to a request from a law enforcement or governmental agency or judicial, quasi-judicial or any other statutory or constitutional authority or to establish or exercise our legal rights or defend against legal claims.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                You further agree that such disclosure, sharing and transfer of Your Personal Information and Non-Personal Information shall not cause any wrongful loss to You or to any third party, or any wrongful gain to Us or to any third party.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                We and Our affiliates will share / sell some or all of Your personal information with another business entity should We (or Our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur, then the other business entity (or the new combined entity) will be required to follow this Privacy Policy with respect to Your Personal Information.
                            </li>
                        </Typography>
                    </ol>
                </Box>

                <Typography variant="h4" my={3} >
                    Link to other platforms
                </Typography>

                <Box pl={4}>
                    <ol start={8}>
                        <Typography variant="body2" mb={0.4}>
                            <li>
                                Our platform may provide links to other sites. These links are provided for Your convenience only and the provision of these links does not mean that sites are related or associated with Us. Please note that these sites have their terms of use and privacy policies. You should check their privacy policy before You submit Your Personal Information or any other data with them. We don’t guarantee the content and the security of those sites.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                We may have certain features on Our platform which may be hosted by third parties, Your interaction with such features shall be governed by the privacy policy of such third partie
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                We shall not be responsible for any loss, damage, claim or expense caused as a result of You accessing these third party sites and features.
                            </li>
                        </Typography>
                    </ol>
                </Box>

                <Typography variant="h4" my={3} >
                    Security
                </Typography>

                <Box pl={4}>
                    <ol start={11}>
                        <Typography variant="body2" mb={0.4}>
                            <li>
                                We strive to ensure the security, integrity and privacy of Your Information and to protect Your Personal Information against unauthorized access or unauthorized alteration, disclosure or destruction. We adopt adequate measures to prevent unauthorized access to Your Personal Information.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                We reserve the right to conduct a security review at any time to verify Your identity. You agree to provide Us all the information that We request for the security review. If You fail to comply with any security request, We reserve the right to terminate Your account with Us and prohibit Your access to the platform.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                We are not liable and cannot be held liable for any breach of security or for any actions of any third parties that receive Your Personal Information.
                            </li>
                        </Typography>

                        <Typography variant="body2" mb={0.4}>
                            <li>
                                The trademarks, logos and service marks displayed on the platform ("Marks") are the property of GlobalShaala. You are not permitted to use the Marks without the prior consent of GlobalShaala.
                            </li>
                        </Typography>
                    </ol>
                </Box>

                <Typography variant="body2" paragraph mt={3}>
                    Notwithstanding anything contained in this Policy or elsewhere, We shall not be held responsible for any loss, damage or misuse of Your Personal Information, if such loss, damage or misuse is attributable to a Force Majeure Event (as defined below).
                </Typography>

                <Typography variant="body2" paragraph>
                    A "Force Majeure Event" shall mean any event that is beyond Our reasonable control and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, epidemics, pandemics, insurrection, war, acts of government, computer hacking, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, etc.
                </Typography>

                <Typography variant="h4" my={3} >
                    Children's privacy
                </Typography>

                <Typography variant="body2" paragraph>
                    We recognize the need to protect the privacy of young children. The collection of personal information relating to children is limited to the extent as is reasonably necessary to participate in our Services. Such personal information is obtained only after taking consent from their parents or legal guardian and if we become aware that we have obtained such personal information without first taking consent from their parent or legal guardian, we will take reasonable steps to remove or destroy such information.
                </Typography>

                <Typography variant="h4" my={3} >
                    Data protection rights
                </Typography>

                <Box pl={4}>
                    <ol>
                        <Typography variant="body2" mb={0.4}>
                            <li>
                                You have certain choices available to you when it comes to your personal information. Below is a summary of those choices, how to exercise them and any limitations.
                            </li>
                        </Typography>
                    </ol>

                    <Box pl={1}>
                        <ol>
                            <Typography variant="body2" mb={0.4}>

                                <li>
                                    Correcting, updating and removing your information:
                                    You can edit, modify and/or update your personal information by logging into Your User profile and You can write to us via email or other means of communication in case You want to exercise Your data protection rights in respect of personal information stored or processed by us to delete, port or restrict processing of such personal information. Upon receipt of a request and upon verification, We will remove the personal information within thirty (30) days. We will retain personal information that we process and store for as long as needed to provide the Services.
                                </li>
                            </Typography>

                            <Typography variant="body2" mb={0.4}>
                                <li>
                                    Accessing and updating or deleting your information:
                                    Our Services and related documentation on our platform give you the ability to access, update and delete certain personal information from within the Service. We will provide you with information about whether we hold any of your personal information upon request. We will respond to such requests within a reasonable timeframe. Please note, however, that we may need to retain certain information for record keeping purposes, to complete Services or to comply with our legal obligations.
                                </li>
                            </Typography>
                        </ol>
                    </Box>

                </Box>

                <Typography variant="h6" mt={2}>
                    CHOICE/OPT-OUT
                </Typography>

                <Typography variant="body2" paragraph>
                    You agree and acknowledge that You are providing your Information out of your free will. You have an option not to provide or permit GlobalShaala to collect Your Personal Information or later on withdraw Your consent with respect to such Personal Information so provided herein by sending an email to the grievance officer or such other electronic address as may be notified to You. However, in such cases GlobalShaala may not be able to provide certain services to You.
                </Typography>

                <Typography variant="h4" my={2}>
                    Data retention
                </Typography>

                <Typography variant="body2" paragraph>
                    GlobalShaala may retain the Information pertaining to User for the maximum period permitted under the law of the land from the date of deactivation of User’s account.
                </Typography>

                <Typography variant="h4" my={2}>
                    Updates to this privacy policy
                </Typography>

                <Typography variant="body2" paragraph>
                    GlobalShaala may change the data privacy practices and update this Privacy Policy as and when the need arises, and the same will be made available on the platform. But our commitment to protect the privacy of platform users will continue to remain. We suggest that you regularly check this Privacy Policy to apprise yourself of any updates. Your continued use of the platform or provision of data or information thereafter will imply Your unconditional acceptance of such updates to this Privacy Policy.
                </Typography>

                <Typography variant="h4" my={2}>
                    Grievance officer
                </Typography>

                <Typography variant="body2" paragraph>
                    In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                    Name: ARPITH CHIDANAND
                    Address: No.19 "Aaramba" Phase 1 Santosh Vihar
                    Jakkur L/O Jakkur, Bangalore- 560092
                    Phone: +91 88840 45670
                    Email: hello@edoutdoors.in
                </Typography>

            </Container>
        </>
    );
};

export default PrivacyPolicy;
