import { Box, Container, Typography, Button } from "@mui/material";
import styles from "./History.module.css";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import HelpIcon from "@mui/icons-material/Help";
import { getUserBookings } from "../../services/actions/auth";
import { useEffect } from "react";
import ExploreIcon from "@mui/icons-material/Explore";
import { useNavigate } from "react-router-dom";

const History = () => {
    const { bookings } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getUserBookings());
        document.title = "Past Bookings | EdOutdoors"
    }, [dispatch]);

    return (
        <Box className={styles.bookingsBox}>
            <Container className={styles.bookingsContainer}>
                <Box className={styles.head}>
                    <Typography variant="h4" align="center">Your bookings history</Typography>
                </Box>

                <Box className={styles.bookingsWrapper}>
                    {bookings?.length ?
                        (
                            bookings.map((booking) => (
                                <Box className={styles.bookingItem} key={booking.id}>
                                    <Box className={styles.left}>
                                        <Typography variant="body1">
                                            Order id :{" "}
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                sx={{ fontWeight: 500 }}
                                            >
                                                {booking.cf_order_id}
                                            </Typography>
                                        </Typography>
                                        <Typography variant="body1">
                                            Booking date  :{" "}
                                            {moment(booking.created_at).format("DD/MM/YYYY")}
                                        </Typography>
                                        <Typography variant="body1">
                                            Tour date :{" "}
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                sx={{ fontWeight: 500 }}
                                            >
                                                {moment(booking.tour.start_date).format("DD/MM/YYYY")}{" "}
                                            </Typography>
                                        </Typography>
                                        <Typography variant="body1">
                                            Amount :{" "}
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                sx={{ fontWeight: 500 }}
                                            >
                                                {booking.amount}
                                            </Typography>
                                        </Typography>
                                        <Typography variant="body1">
                                            Registration number :{" "}
                                            <Typography
                                                variant="body1"
                                                component="span"
                                                sx={{ fontWeight: 500 }}
                                            >
                                                {booking.registrationTotal}
                                            </Typography>
                                        </Typography>
                                    </Box>

                                    <Box className={styles.right}>
                                        <Typography variant="h5">
                                            {booking.experience.title}
                                        </Typography>
                                        <Box className={styles.rightItem}>
                                            <Box className={styles.imageBox}>
                                                <img
                                                    src={booking?.experience?.cover_photo}
                                                    // src="https://sgp1.digitaloceanspaces.com/static-edvolve/outdoor/vz2Z3eNFEZ.jpeg"
                                                    alt={booking.experience.title}
                                                />
                                            </Box>
                                            <Box className={styles.rightDetails}>
                                                <Typography variant="h6" gutterBottom>
                                                    {booking.experience.title}
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Duration :{" "}
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        sx={{ fontWeight: 500 }}
                                                    >
                                                        {booking.experience.duration}
                                                    </Typography>
                                                </Typography>
                                                <Typography variant="body1">
                                                    Subjects :{" "}
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        sx={{ fontWeight: 500 }}
                                                    >
                                                        {booking.experience.primary_subject.name},{" "}
                                                        {booking.experience.secondary_subject.name}{" "}
                                                    </Typography>
                                                </Typography>
                                                {/* <Typography
                                                variant="body1"
                                                mb={2}
                                                sx={{ fontWeight: 500 }}
                                            >
                                                {booking.order_status}
                                            </Typography> */}
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        color: "#1D9929",
                                                        borderColor: "#1D9929",
                                                        mt: 2,
                                                        '&:hover': {
                                                            borderColor: "#1D9929",
                                                            backgroundColor: "#1D9929",
                                                            color: "#fff"
                                                        }

                                                    }}
                                                    size="small"
                                                    endIcon={<HelpIcon />}
                                                    onClick={() =>
                                                        window.open(
                                                            "https://docs.google.com/forms/d/e/1FAIpQLSeBagDt9BUwlM8vTCxRd9ycTGpGhGyEJBCBUjqMKIUecWK-zw/viewform",
                                                            "_blank"
                                                        )
                                                    }
                                                >
                                                    Help
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Box className={styles.notAvailable}>
                                <Typography variant="h4" gutterBottom>
                                    No bookings yet
                                </Typography>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: "#1D9929",
                                        borderColor: "#1D9929",
                                        '&:hover': {
                                            borderColor: "#1D9929",
                                            backgroundColor: "#1D9929",
                                            color: "#fff"
                                        }

                                    }}
                                    endIcon={<ExploreIcon />}
                                    onClick={() => navigate("/experiences")}
                                >
                                    Explore Experiences
                                </Button>

                            </Box>
                        )
                    }
                </Box>
            </Container>
        </Box>
    );
};

export default History;
