import styles from './Stats.module.css'
import {
    Box,
    Container,
} from "@mui/material";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import StarIcon from '@mui/icons-material/Star';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const Stats = () => {
    return (
        <Box className={styles.stats}>
            <Container>
                <h2>Our community has served thousands of students</h2>
                <Box className={styles.statsBox}>
                    <Box className={styles.box}>
                        <Box className={styles.icon}>
                            <EmojiEmotionsIcon color="warning" />
                        </Box>
                        <Box className={styles.content}>
                            <p>Find curriculum and skill mapped field trips</p>
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Box className={styles.icon}>
                            <StarIcon color="primary" />
                        </Box>
                        <Box className={styles.content}>
                            <p> We onboard only vetted service providers</p>
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Box className={styles.icon}>
                            <AccountCircleIcon color="secondary" />
                        </Box>
                        <Box className={styles.content}>
                            <p>Safety is given top priority in all experiences</p>
                        </Box>
                    </Box>

                    <Box className={styles.box}>
                        <Box className={styles.icon}>
                            <SupportAgentIcon color="info" />
                        </Box>
                        <Box className={styles.content}>
                            <p>Have a question? Live chat with us</p>
                        </Box>
                    </Box>

                </Box>
            </Container>
        </Box>
    )
}

export default Stats