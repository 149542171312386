import {
    GET_EXPERIENCES,
    GET_EXPERIENCES_SUCCESS,
    GET_EXPERIENCES_FAILURE,
    GET_UPCOMING_EXPS,
    GET_UPCOMING_EXPS_SUCCESS,
    GET_UPCOMING_EXPS_FAILURE,
} from '../actions/experiences';

import { call, put, takeEvery } from 'redux-saga/effects';

import { expApi } from '../api';

function* getExperiences() {
    try {
        const response = yield call(expApi.getAllExperiences);
        yield put({ type: GET_EXPERIENCES_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_EXPERIENCES_FAILURE, payload: error });
    }
}

function* getUpcomingExps() {
    try {
        const response = yield call(expApi.getUpcomingExperiences);
        yield put({ type: GET_UPCOMING_EXPS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_UPCOMING_EXPS_FAILURE, payload: error });
    }
}

export default function* experienceSaga() {
    yield takeEvery(GET_EXPERIENCES, getExperiences);
    yield takeEvery(GET_UPCOMING_EXPS, getUpcomingExps);
}