import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    TextField,
    Typography,
    Box,
    Slide,
    IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { forwardRef, useState } from "react"
import styles from './Callback.module.css'
import { requestCallbackApi } from '../../services/api'
import { changeRequestCallbackStatus } from '../../services/actions/callbacks'
import { useDispatch } from 'react-redux'
import moengage from "@moengage/web-sdk";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Callback = ({ open, expId, setOpenRequestCallback, setOpenSuccessAlert, data, parentName, partnerName, expTitle, expTourDate, expTourTime }) => {
    const dispatch = useDispatch()
    const [details, setDetails] = useState({
        experience_id: parseInt(expId),
        name: data.name || '',
        parent_name: parentName || '',
        mobile: data.mobile || ''
    })
    const [formError, setFormError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const handleChange = (e) => {
        const { name, value } = e.target
        setDetails({ ...details, [name]: value })
    }

    const handleClose = () => {
        setOpenRequestCallback((prev) => ({
            ...prev,
            open: false
        }))
    }

    const validateDetails = () => {
        if (!details.name?.trim() || !details.parent_name?.trim() || !details.mobile?.trim().length === 10) {
            setFormError(true)
            return false
        }
        return true
    }

    // Pushing Moengage Event
    const pushMoengageEvent = () => {
        moengage.track_event("requested_callback", {
            child_first_name: details.name,
            parent_name: details.parent_name,
            experience_title: expTitle,
            experience_tour_date: expTourDate,
            experience_tour_time: expTourTime,
        });
    }

    const handleSubmit = async () => {
        if (!validateDetails()) {
            return
        }
        setFormError(false)
        setErrorMsg('')
        try {
            setLoading(true)
            const response = await requestCallbackApi.createRequest(details)
            if (response.data.success) {
                setOpenRequestCallback((prev) => ({
                    ...prev,
                    open: false,
                    status: true
                }));
                pushMoengageEvent()
                dispatch(changeRequestCallbackStatus(expId))
                setOpenSuccessAlert(true)
            } else {
                setErrorMsg(response.data.message)
                throw new Error(response.data.message)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            sx={{
                '& .MuiDialog-paper': {
                    margin: 1,
                    width: '100%',
                    pb: 5,
                    borderRadius: '20px',
                },

            }}
        >
            <DialogTitle>
                <Box className={styles.header}>
                    <Typography variant="h5">
                        Request Callback
                    </Typography>
                    {
                        <IconButton onClick={handleClose}>
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    }
                </Box>
                <Box className={styles.desc}>
                    <Typography variant="body1" mt={1}>
                        You will be contacted by the {partnerName} team (the organizer) in a short while to help you with your queries
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box className={styles.form} >
                    <Box className={styles.inputField}>
                        <TextField
                            fullWidth
                            label="Child Name"
                            name="name"
                            value={details.name}
                            onChange={handleChange}
                            required
                            error={formError && !details.name?.trim()}
                            variant="standard"
                        />

                    </Box>
                    <Box className={styles.inputField}>
                        <TextField
                            fullWidth
                            label="Parent Name"
                            name="parent_name"
                            value={details.parent_name}
                            onChange={handleChange}
                            required
                            error={formError && !details.parent_name?.trim()}
                            variant="standard"
                        />

                    </Box>
                    <Box className={styles.inputField}>
                        <TextField
                            fullWidth
                            label="Mobile Number"
                            name="mobile"
                            value={details.mobile}
                            onChange={handleChange}
                            required
                            error={formError && !details.mobile?.trim()}
                            variant="standard"
                            inputProps={{
                                type: 'number'
                            }}
                        />

                    </Box>
                </Box>
                <Typography variant="body2" color="error" textAlign="center" >
                    {errorMsg}
                </Typography>
                <Box className={styles.formButton}>
                    <Button
                        variant="outlined"
                        onClick={handleSubmit}
                        disabled={loading}
                        sx={{
                            color: "#1D9929",
                            fontWeight: "bold",
                            borderColor: "#1D9929",
                            '&:hover': {
                                borderColor: "#1D9929",
                                backgroundColor: "#1D9929",
                                color: "#fff"
                            }

                        }}
                    >
                        Submit
                    </Button>
                </Box>
            </DialogContent>

        </Dialog>
    )
}

export default Callback