import { all } from 'redux-saga/effects';
import authSaga from "./auth"
import experienceSaga from "./experiences"
import schoolsSaga from './schools';
import gradesSaga from './grades';
import childSaga from './child';
import collectionsSaga from './collections';
import getExpDestinationsTypesSaga from './exp_destination_types';
import getSubjectsSaga from './subjects';
import bannersSaga from './banners'

export function* rootSaga() {
    yield all([
        authSaga(),
        experienceSaga(),
        schoolsSaga(),
        gradesSaga(),
        childSaga(),
        collectionsSaga(),
        getExpDestinationsTypesSaga(),
        getSubjectsSaga(),
        bannersSaga()
    ]);
}