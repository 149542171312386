import {
    useScrollTrigger,
    Slide,
} from "@mui/material";


const HideOnScroll = ({ children, direction }) => {
    const trigger = useScrollTrigger();
    return (
        <Slide appear={true} direction={direction} in={!trigger}>
            {children}
        </Slide>
    );
};

export default HideOnScroll;