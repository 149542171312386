import { combineReducers } from "redux";
import { auth } from "./auth";
import { experiences } from "./experiences";
import { schools } from "./schools";
import { grades } from "./grades";
import { child } from "./child";
import { collections } from './collections';
import { callbacks } from './callbacks';
import { notify } from './notify';
import { exp_destination_types } from "./exp_destination_types";
import { subjects } from "./subjects";
import { banners } from './banners';


const rootReducer = combineReducers({
    auth,
    experiences,
    schools,
    grades,
    child,
    collections,
    callbacks,
    notify,
    exp_destination_types,
    subjects,
    banners
})

export default rootReducer;