export const GET_CHILD = 'GET_CHILD';
export const GET_CHILD_SUCCESS = 'GET_CHILD_SUCCESS';
export const GET_CHILD_FAILURE = 'GET_CHILD_FAILURE';

export const getChild = (payload) => ({
    type: GET_CHILD,
    id : payload
});


export const CREATE_CHILD = 'CREATE_CHILD';
export const CREATE_CHILD_SUCCESS = 'CREATE_CHILD_SUCCESS';
export const CREATE_CHILD_FAILURE = 'CREATE_CHILD_FAILURE';

export const createChild = (payload) => ({
    type: CREATE_CHILD,
    data : payload
});

export const UPDATE_CHILD = 'UPDATE_CHILD';
export const UPDATE_CHILD_SUCCESS = 'UPDATE_CHILD_SUCCESS';
export const UPDATE_CHILD_FAILURE = 'UPDATE_CHILD_FAILURE';

export const updateChild = (payload) => ({
    type: UPDATE_CHILD,
    data : payload
});