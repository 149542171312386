import {
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    GET_USER_BOOKINGS,
    GET_USER_BOOKINGS_SUCCESS,
    GET_USER_BOOKINGS_FAILURE,
} from "../actions/auth";

const initialState = {
    user: null,
    loading: false,
    error: null,
    bookings: null,
};

export const auth = (data = initialState, action) => {
    switch (action.type) {
        case GET_USER:
        case GET_USER_BOOKINGS:
            return {
                ...data,
                loading: true,
                error: null,
            };


        case GET_USER_SUCCESS:
            return {
                ...data,
                loading: false,
                user: action.payload,
            };

        case GET_USER_BOOKINGS_SUCCESS:
            return {
                ...data,
                loading: false,
                bookings: action.payload,
            };

        case GET_USER_FAILURE:
        case GET_USER_BOOKINGS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
                bookings:[]
            };

        default:
            return data
    }
}