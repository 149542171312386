import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ProfileModal, WelcomePopup } from "../"

const ShowProfile = () => {
    const [showProfile, setShowProfile] = useState(false)
    const { user } = useSelector(state => state.auth)
    const { child } = useSelector(state => state.child)
    const [showWelcome, setShowWelcome] = useState(false)

    useEffect(() => {
        if (user && child.length === 0) {
            setShowProfile(true)
        } else {
            setShowProfile(false)
        }
    }, [user, child.length])

    return (
        showProfile ? (
            <ProfileModal
                open={showProfile}
                action="create"
                handleClose={() => setShowProfile(false)}
                setShowWelcome={setShowWelcome}
            />
        ) : showWelcome ? (
            <WelcomePopup
                open={showWelcome}
                handleClose={() => setShowWelcome(false)}
                childName={child?.first_name}
            />
        ) : null
    )
}

export default ShowProfile