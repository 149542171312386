import {
    Accordion as MUIAccordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Itineary from './Itinerary';

const Accordion = (props) => {
    return (
        <Box>
            {
                props.details && (
                    <>
                        <Box sx={{ py: "48px" }}>
                            <Divider />
                            <MUIAccordion defaultExpanded={true} sx={{ background: "none", boxShadow: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ p: 0, mt: 3 }}
                                >
                                    <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                                        Details
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <Box sx={{
                                        fontSize: "15px",
                                        lineHeight: "25px",
                                        "& ul": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43",
                                        },
                                        "& ol": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43",
                                        }

                                    }}>
                                        {/* html */}
                                        <div dangerouslySetInnerHTML={{ __html: props.details }} />
                                    </Box>
                                </AccordionDetails>
                            </MUIAccordion>
                        </Box>
                        <Divider />
                    </>
                )
            }

            {
                props.outcomes && (
                    <>
                        <Box sx={{ pb: "48px", pt: "28px" }}>
                            <MUIAccordion defaultExpanded={true} sx={{ background: "none", boxShadow: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ p: 0 }}
                                >
                                    <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                                        Learning Outcomes
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <Box sx={{
                                        fontSize: "15px",
                                        lineHeight: "25px",
                                        "& ul": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43"
                                        },
                                        "& ol": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43"
                                        }
                                    }}>
                                        {/* html */}
                                        <div dangerouslySetInnerHTML={{ __html: props.outcomes }} />
                                    </Box>
                                </AccordionDetails>
                            </MUIAccordion>
                        </Box>
                        <Divider />
                    </>
                )
            }

            {
                props.itineary && (
                    <>
                        <Box sx={{ pb: "48px", pt: "28px" }}>
                            <MUIAccordion defaultExpanded={true} sx={{ background: "none", boxShadow: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ p: 0 }}
                                >

                                    <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                                        Itinerary
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <Box>
                                        <Itineary data={props.itineary} />
                                    </Box>
                                </AccordionDetails>
                            </MUIAccordion>
                        </Box>
                        <Divider />
                    </>
                )
            }

            {
                props.inclusions && (
                    <>
                        <Box sx={{ pb: "48px", pt: "28px" }}>
                            <MUIAccordion defaultExpanded={true} sx={{ background: "none", boxShadow: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ p: 0 }}
                                >
                                    <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                                        Inclusions
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <Box sx={{
                                        fontSize: "15px",
                                        lineHeight: "25px",
                                        "& ul": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43"
                                        },
                                        "& ol": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43"
                                        }
                                    }}>
                                        {/* html */}
                                        <div dangerouslySetInnerHTML={{ __html: props.inclusions }} />
                                    </Box>
                                </AccordionDetails>
                            </MUIAccordion>
                        </Box>
                        <Divider />
                    </>
                )
            }

            {
                props.exclusions && (
                    <>
                        <Box sx={{ pb: "48px", pt: "28px" }}>
                            <MUIAccordion defaultExpanded={true} sx={{ background: "none", boxShadow: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ p: 0 }}
                                >
                                    <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                                        Exclusions
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <Box sx={{
                                        fontSize: "15px",
                                        lineHeight: "25px",
                                        "& ul": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43"
                                        },
                                        "& ol": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43"
                                        }
                                    }}>
                                        <div dangerouslySetInnerHTML={{ __html: props.exclusions }} />
                                    </Box>
                                </AccordionDetails>
                            </MUIAccordion>
                        </Box>
                        <Divider />
                    </>
                )
            }

            {
                props.requirements && (
                    <>
                        <Box sx={{ pb: "48px", pt: "28px" }}>
                            <MUIAccordion defaultExpanded={true} sx={{ background: "none", boxShadow: 0 }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ p: 0 }}
                                >
                                    <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                                        Requirements
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <Box sx={{
                                        fontSize: "15px",
                                        lineHeight: "25px",
                                        "& ul": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43"
                                        },
                                        "& ol": {
                                            paddingLeft: "20px",
                                            color: "#0F0F43"
                                        }
                                    }}>
                                        <div dangerouslySetInnerHTML={{ __html: props.requirements }} />
                                    </Box>
                                </AccordionDetails>
                            </MUIAccordion>
                        </Box>
                        <Divider />
                    </>
                )
            }

            <Box sx={{ pb: "48px", pt: "28px" }}>
                <MUIAccordion defaultExpanded={true} sx={{ background: "none", boxShadow: 0 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ p: 0 }}
                    >
                        <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                            {
                                props.cancellationPolicy ? "Cancellation Policy" : null
                            }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                        <Box sx={{
                            fontSize: "15px",
                            lineHeight: "25px",
                            "& ul": {
                                paddingLeft: "20px",
                                color: "#0F0F43"
                            },
                            "& ol": {
                                paddingLeft: "20px",
                                color: "#0F0F43"
                            }
                        }}>
                            <div dangerouslySetInnerHTML={{ __html: props.cancellationPolicy?.cancellation_policy }} />
                        </Box>
                    </AccordionDetails>
                </MUIAccordion>
            </Box>
            <Divider />

        </Box>
    );
}

export default Accordion;