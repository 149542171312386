import styles from './PartnerProfile.module.css'
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Avatar,
  Skeleton,
  Grid,
  IconButton,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Media from './Media';
import Experiences from './Experiences';
import { useState, useEffect } from 'react';
import { partnersApi } from '../../services/api';
import { useParams } from 'react-router-dom';
import { Facebook, Twitter, Instagram } from '@mui/icons-material';
import { Helmet } from 'react-helmet';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, mt: 4 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const LoadingSkeleton = () => {
  return (
    <Box className={styles.profileBox}>
      <Skeleton variant="rounded" width="100%" height={450} />
      <Box className={styles.profileBoxTop}>
        <Container className={styles.profileContainer}>
          <Box className={styles.profileContent}>
            <Box className={styles.profileContentImage}>
              <Skeleton variant="circular" width="100%" height="100%" />
            </Box>
            <Box className={styles.profileContentInfo} sx={{ mb: 2 }}>
              <Skeleton variant="text" width="250px" height="30px" />
              <Skeleton variant="text" width="200px" height="30px" />
              <Skeleton variant="text" width="280px" height="100px" />
            </Box>
            <Skeleton variant="rounded" width="100%" height={300} />
          </Box>
        </Container>
      </Box>
    </Box>

  )
}

const PartnerProfile = () => {
  const [value, setValue] = useState(0);
  const [partnerData, setPartnerData] = useState([])
  const [loading, setLoading] = useState(true)
  const { id } = useParams()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const response = await partnersApi.getPartner(id)
        if (response.data.success) {
          setPartnerData(response.data.data)
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [id])

  if (loading) {
    return <LoadingSkeleton />
  }

  return (
    <>
      <Helmet>
        <title>{`${partnerData?.partner?.name} | EdOutdoors`}</title>
        <meta
          name="description"
          content={`${partnerData?.partner?.highlight}`}
        />
        <meta property="og:title" content={`${partnerData?.partner?.name} | EdOutdoors`} />
        <meta property="og:description" content={`${partnerData?.partner?.highlight}`} />
        <link rel="canonical" href={`https://www.edoutdoors.in/partner/${partnerData?.partner?.id}`} />
      </Helmet>

      <Box className={styles.profileBox}>
        <Box className={styles.profileBoxTop}>
          <img
            src={partnerData?.partner?.cover_photo ?? "/banner.webp"}
            alt={partnerData?.partner?.name}
            className={styles.topImage}
          />
        </Box>
        <Container>
          <Grid container spacing={5} className={styles.profileContainer}>
            <Grid item xs={12} md={4}>
              <Box className={styles.profileContent}>
                <Box className={styles.profileContentImage}>
                  <Avatar
                    alt={partnerData?.partner?.name}
                    src={partnerData?.partner?.profile_photo}
                    sx={{ width: "100%", height: "100%" }}
                  />
                </Box>

                <Box className={styles.profileContentInfo}>
                  <Typography variant="h6">
                    {partnerData?.partner?.name}
                  </Typography>
                  <Typography variant="body2" component="p" className={styles.location}>
                    <span><LocationOnIcon fontSize='small' sx={{ position: "relative", top: "3px" }} /> </span> {partnerData?.partner?.city?.name}
                  </Typography>
                  <Typography variant="body2">
                    {partnerData?.partner?.highlight}
                  </Typography>
                </Box>

                <Box className={styles.profileContentSocial}>

                  {
                    partnerData?.partner?.facebook_link && (
                      <IconButton onClick={() => window.open(partnerData?.partner?.facebook_link)}>
                        <Facebook fontSize='large' color='primary' />
                      </IconButton>
                    )
                  }

                  {
                    partnerData?.partner?.instagram_link && (
                      <IconButton onClick={() => window.open(partnerData?.partner?.instagram_link)}>
                        <Instagram fontSize='large' color='error' />
                      </IconButton>
                    )
                  }

                  {
                    partnerData?.partner?.twitter_link && (
                      <IconButton onClick={() => window.open(partnerData?.partner?.twitter_link)}>
                        <Twitter fontSize='large' color='info' />
                      </IconButton>
                    )
                  }

                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box className={styles.tabs}>
                <Box >
                  <Tabs value={value} onChange={handleChange} >
                    <Tab label="INTRO" />
                    <Tab label="EXPERIENCES" />
                    <Tab label="MEDIA" />
                  </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                  <Typography variant="h6" gutterBottom sx={{ fontSize: "24px", fontWeight: 600 }}>
                    Description
                  </Typography>
                  <Box
                    sx={{
                      fontSize: "15px",
                      lineHeight: "25px",
                      "& ul": {
                        paddingLeft: "20px",
                        color: "#0F0F43"
                      },
                      "& ol": {
                        paddingLeft: "20px",
                        color: "#0F0F43"
                      }
                    }}>
                    {/* html */}
                    <div dangerouslySetInnerHTML={{ __html: partnerData?.partner?.description }} />
                  </Box>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Experiences data={partnerData?.experience?.filter(exp => exp?.status)} />
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <Media data={partnerData?.image} />
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default PartnerProfile