
// endpoints 
export const authEndpoints = {
    sendLoginOtp: '/login',
    verifyLoginOtp: 'loginverify',
    sendSignupOtp: '/signup',
    verifySignupOtp: '/signupverify',
    resendOtp: '/resendotp',
    getUser: '/loggedIn',
    bookingHistory: '/booking-history',
}

export const expEndpoints = {
    getAllExperiences: '/experience',
    getExperience: '/experience/',
    getFilteredExperiences: '/experience',
    getUpcomingExperiences: '/experience/upcoming',
}

export const subjectsEndpoints = {
    getAllSubjects: '/subject',
    getSubject: '/subject/',
}

export const countiresEndpoints = {
    getAllCountries: '/country',
    getCountry: '/country/',
}

export const hobbiesEndpoints = {
    getAllHobbies: '/hobby',
    getHobby: '/hobby/',
}

export const statesEndpoints = {
    getAllStates: '/state',
    getState: '/state/',
}

export const citiesEndpoints = {
    getAllCities: '/city',
    getCity: '/city/',
}

export const rolesEndpoints = {
    getAllRoles: '/role',
    getRole: '/role/',
}

export const gradesEndpoints = {
    getAllGrades: '/grade',
    getGrade: '/grade/',
}

export const schoolsEndpoints = {
    getAllSchools: '/school',
    getSchool: '/school/',
}


export const partnerEndpoints = {
    getAllPartners: '/partner',
    getPartner: '/partner/',
}

export const mediaEndpoints = {
    uploadMedia: '/media-upload'
}

export const childEndpoints = {
    getChild: '/child/',
    createChild: '/child',
    updateChild: '/child',
}

export const collectionsEndpoints = {
    getAllCollections: '/collection',
    getCollection: '/collection/',
}

export const requestCallbackEndpoints = {
    requestCallback: '/callback'
}

export const notifyEndpoints = {
    notifyMe: '/notify'
}

export const recommendationsEndpoints = {
    getRecommendations: '/recommendation?experience_id='
}

export const experienceTypesEndpoints = {
    getAllExperienceTypes: '/experience-type',
}

export const destinationTypesEndpoints = {
    getAllDestinationTypes: '/destination-type',
}

export const schoolTripEndpoints = {
    createRequest: '/school-trip',
}

export const bannerEdnpoints = {
    getAllBanners: '/banner',
}