import styles from "./Home.module.css";
import { Box, Container, Typography, Avatar } from "@mui/material";
import {
  SearchInput,
  Stats,
  Banner,
  Collections,
  Testimonials,
  UpcomingExps,
  Blogs,
  AuthModal,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getExperiences,
  getUpcomingExps,
} from "../../services/actions/experiences";
import { getCollections } from "../../services/actions/collections";
import { getBanners } from "../../services/actions/banners";
import BannerSlider from "./BannerSlider";
import { useNavigate } from "react-router-dom";
import { Loading } from "./Loading";
import { Helmet } from "react-helmet";
import Customers from "./Customers";
import FilterSlider from "./FilterSlider";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { collections, loading: collectionsLoading } = useSelector(
    (state) => state.collections
  );
  const {
    experiences,
    upcomingExps,
    loading: expLoading,
  } = useSelector((state) => state.experiences);
  const [collectionsData, setCollectionsData] = useState([]);
  const { banners, loading: bannersLoading } = useSelector(
    (state) => state.banners
  );
  const [showAuth, setShowAuth] = useState(false);

  useEffect(() => {
    if (!experiences) dispatch(getExperiences());
    if (!collections) dispatch(getCollections());
    if (!banners) dispatch(getBanners());
    if (!upcomingExps) dispatch(getUpcomingExps());
    //eslint-disable-next-line
  }, [dispatch]);

  // Setting the collections
  useEffect(() => {
    // Setting and sorting the collections
    (async function () {
      const filteredData = collections?.map((collection) => {
        return {
          ...collection,
          experiences: collection?.experiences?.map((experience) => {
            return experience?.experience?.id;
          }),
        };
      });
      setCollectionsData(() => {
        const active = filteredData?.filter(
          (collection) => collection?.status === true
        );
        return active?.sort((a, b) => a?.priority - b?.priority);
      });
    })();
  }, [collections]);

  if (collectionsLoading || expLoading || bannersLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>Best School Trips, Summer Camp & Activities for Kids</title>
        <meta
          name="description"
          content="Find unique school trips, summer camps and activities for kids in Bangalore and more cities in India. Empower children through hands-on learning and discovery."
        />
        <meta
          property="og:title"
          content="Best School Trips, Summer Camp & Activities for Kids"
        />
        <meta
          property="og:description"
          content="Find unique school trips, summer camps and activities for kids in Bangalore and more cities in India. Empower children through hands-on learning and discovery."
        />
        <link rel="canonical" href="https://www.edoutdoors.in" />
      </Helmet>
      <Box className={styles.homeContainer}>
        <Box className={styles.homeTop}>
          <Box className={styles.homeTopBanner}>
            {banners?.length ? (
              <BannerSlider data={banners} />
            ) : (
              <img
                src={
                  window.innerWidth > 500 ? "/banner.webp" : "/bannerMob.webp"
                }
                alt="EdOutdoors"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/experiences")}
              />
            )}
          </Box>
        </Box>

        {window.innerWidth > 768 && (
          <Box className={styles.searchBox}>
            <SearchInput py="5" px="10" width="50%" id="home" />
          </Box>
        )}

        {/* Collections */}
        <Container className={styles.expList}>
          {collectionsData?.slice(0, 2)?.map((collection) => (
            <Box key={collection.id} my={5}>
              <Collections collection={collection} />
            </Box>
          ))}
        </Container>

        {/*Upcoming Experiences */}
        {upcomingExps?.length > 0 && (
          <Box className={styles.upcoimgExps}>
            <UpcomingExps />
          </Box>
        )}

        {/*Filter Sider */}
        <Box className={styles.filters}>
          <Container className={styles.filterSlider}>
            <Box className={styles.filterSliderHeader}>
              <Avatar
                sx={{
                  background: "transparent",
                  color: "#0F0F43",
                  border: "1px solid #ccc",
                }}
              >
                <ModeOfTravelIcon />
              </Avatar>
              <Box className={styles.filterSliderContent}>
                <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                  Find New Experiences
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ fontWeight: "500" }}
                >
                  Browse from a host of options
                </Typography>
              </Box>
            </Box>
            <FilterSlider />
          </Container>
        </Box>
        {/* Collections */}
        <Container className={styles.expList}>
          {collectionsData?.slice(2)?.map((collection) => (
            <Box key={collection.id} my={5}>
              <Collections collection={collection} />
            </Box>
          ))}
        </Container>

        <Box className={styles.bannerWrapper}>
          <Customers />
        </Box>

        <Box className={styles.signUP}>
          <Container className={styles.signUPContainer}>
            <Typography variant="h3">
              Curated camps & experiences coming up.
            </Typography>
            <button
              onClick={() => setShowAuth(true)}
              type="button"
            >
              Stay Updated
            </button>
          </Container>
        </Box>

        <Box className={styles.bannerWrapper}>
          <Banner />
        </Box>

        <Box className={styles.statsWrapper}>
          <Stats />
        </Box>

        <Box
          className={styles.testimonials}
          sx={{
            backgroundImage: `url("/images/testimonials.webp")`,
          }}
        >
          <Testimonials />
        </Box>

        <Box className={styles.blogs}>
          <Blogs />
        </Box>
      </Box>

      {
        showAuth && (
          <AuthModal show={showAuth} closeAuth={() => setShowAuth(false)} />
        )
      }
    </>
  );
};

export default Home;
