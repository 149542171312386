import {
    Box, Typography,
} from "@mui/material"
import styles from "./PartnerProfile.module.css"
import Card from './Card'

const Experiences = ({ data }) => {
    return (
        <Box className={styles.experienceBox}>
            {
                data?.length > 0 ? (
                    data.map(exp => (
                        <Card data={exp} key={exp?.id} />
                    ))
                ) : (
                    <Typography variant="h6" color="textSecondary">
                        No media found
                    </Typography>
                )
            }
        </Box>
    )
}

export default Experiences