import styles from "./DateTimeModal.module.css"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    Slide,
    IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { forwardRef } from "react"
import {
    CalendarModal,
    Alert,
    TimePicker,
} from "../../components";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from "moment";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DateTimeModal = ({ open, handleClose, selectedDate, setSelectedDate, toursData, setSelectedTime, selectedTime, setTourId, setShowCalendar, showCalendar, handleProceed, showAlert }) => {

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            sx={{
                '& .MuiDialog-paper': {
                    margin: 1,
                    width: '100%',
                    pb: 5
                },

            }}
        >
            <DialogTitle>
                <Box className={styles.header}>
                    <Typography variant="h6">
                        Choose a slot
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <Box className={styles.date}>
                    <Box className={styles.dateSelectButton}>
                        <button type="button" onClick={() => setShowCalendar(true)}>
                            {
                                selectedDate ? (
                                    <>
                                        <Typography variant="body" color="text.secondary" >
                                            {moment(selectedDate).format('DD/MM/YYYY')}
                                        </Typography>
                                        <span className={styles.dateButtonIcon}>
                                            <ArrowDropDownIcon />
                                        </span>
                                    </>
                                ) : (

                                    <>
                                        <Typography variant="body" color="text.secondary">
                                            Select an upcoming slot
                                        </Typography>
                                        <span className={styles.dateButtonIcon}>
                                            <ArrowDropDownIcon />
                                        </span>
                                    </>
                                )
                            }
                        </button>
                    </Box>

                    {
                        selectedDate && (
                            <Box className={styles.timeSlot}>
                                <TimePicker
                                    selectedTime={selectedTime}
                                    setSelectedTime={setSelectedTime}
                                    toursData={toursData}
                                    selectedDate={selectedDate}
                                    setTourId={setTourId}
                                />
                            </Box>
                        )
                    }

                    {
                        selectedTime && (
                            <Box className={styles.timeAndLocation}>
                                <Typography variant="caption" component="p">
                                    Exact time+location shared on confirmed booking.
                                </Typography>
                            </Box>
                        )
                    }

                    <Box className={styles.availabilityButton}>
                        <button
                            type="button"
                            onClick={handleProceed}
                        >
                            Check Availability
                        </button>
                    </Box>

                    {
                        showAlert.show && (
                            <Box sx={{ width: "90%", mt: 2 }}>
                                <Alert
                                    type="error"
                                    message={showAlert.message}
                                />
                            </Box>
                        )
                    }

                    <Box className={styles.calendarBox}>
                        {
                            showCalendar &&
                            <CalendarModal
                                open={showCalendar}
                                handleClose={() => setShowCalendar(false)}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                toursData={toursData}
                                setSelectedTime={setSelectedTime}
                            />
                        }
                    </Box>

                </Box>
            </DialogContent>

        </Dialog>
    )
}

export default DateTimeModal