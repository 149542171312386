export const team = [
    {
        name: "Arpith C",
        title: "Cofounder and CEO",
        img: "/images/about/arpith.webp",
        linkedIn:"https://www.linkedin.com/in/arpithc"
    },
    {
        name: "Vikshak P",
        title: "Cofounder and Head of Operations",
        img: "/images/about/vikshak.webp",
        linkedIn:"https://www.linkedin.com/in/vikshak/"
    },
    {
        name: "Smriti S",
        title: "Catalog Coordinator",
        img: "/images/about/smriti.webp",
    },
    {
        name: "Shwetank Singh",
        title: "Technical Lead",
        img: "/images/about/shwetank.webp",
    },
    {
        name: "Abhishek Bhardwaj",
        title: "Software Engineer",
        img: "/images/about/abhishek.webp",
    },
]