import {
    Box,
    Drawer,
    Divider,
    IconButton,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from './Header.module.css'
import { useNavigate } from 'react-router-dom'
import { HelpSlider } from '../'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCallback } from 'react'

const Sidebar = ({ open, handleClose, menuCategroies, currentCategory, setCurrentCategory }) => {
    const navigate = useNavigate()

    const handleNavigate = useCallback((link) => {
        switch (currentCategory) {
            case "Experience Themes":
                handleClose()
                navigate(`experiences?experience_type=${link.name}`)
                break

            case "Subjects":
                handleClose()
                navigate(`experiences?subjects=${link.id}`)
                break

            case "Duration":
                handleClose()
                navigate(`experiences?event=${link.name}`)
                break

            default:
                break
        }
    }, [currentCategory, navigate, handleClose])

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor="left"
        >
            <Box
                className={styles.sidebar}
                role="presentation"
            >
                <Box className={styles.sidebarHead}>
                    <img
                        src="/logo.webp"
                        alt="edoutdoors"
                        onClick={() => {
                            handleClose()
                            navigate("/")
                        }}
                        width={180}
                    />
                    <IconButton onClick={handleClose} className={styles.closeBtn}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Divider />

                <Box className={styles.sidebarBody}>
                    <Box className={styles.mobileMenuLinks}>
                        {menuCategroies?.map(item => (
                            <Box key={item.id} className={styles.mobileMenuLinks}>
                                {item.name === "All Categories" ? (
                                    <Accordion
                                        sx={{
                                            background: "none",
                                            boxShadow: 0,
                                        }}
                                    >
                                        <AccordionSummary
                                            aria-controls={`panel${item.id}-content`}
                                            id={`panel${item.id}-header`}
                                            expandIcon={<ExpandMoreIcon />}
                                            sx={{
                                                py: 0,
                                                px: 2,
                                                "&.Mui-expanded": {
                                                    backgroundColor: "#f8f8f8",
                                                },

                                            }}
                                        >
                                            <Typography sx={{ color: "#0F0F43", fontWeight: "600" }}>
                                                {item.name}
                                            </Typography>
                                        </AccordionSummary>
                                        {/* <Divider /> */}
                                        <AccordionDetails sx={{ p: 0, m: 0 }}>
                                            {item.subCategories.map((subItem, index) => (
                                                <Accordion
                                                    sx={{
                                                        background: "none",
                                                        boxShadow: 0,
                                                        "&.Mui-expanded": {
                                                            background: "#f8f8f8",
                                                        },
                                                        "&.MuiPaper-root.MuiAccordion-root": { my: 0, py: 1 }
                                                    }}
                                                    key={index}
                                                    expanded={currentCategory === subItem.name}
                                                    onClick={() => {
                                                        if (currentCategory === subItem.name) {
                                                            setCurrentCategory("")
                                                        }
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        aria-controls={`panel${index}-content`}
                                                        id={`panel${index}-header`}
                                                        expandIcon={<ExpandMoreIcon />}
                                                        sx={{
                                                            py: 0,
                                                            px: 2,
                                                            "&.Mui-expanded": {
                                                                background: "#f8f8f8",
                                                            },

                                                        }}
                                                        onClick={() => setCurrentCategory(subItem.name)}
                                                    >
                                                        <Typography sx={{ color: "#0F0F43", fontWeight: "600" }}>
                                                            {subItem.name}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={{ p: 0 }}>
                                                        {subItem?.subCategories?.slice(0, 10)?.map((subSubItem, index) => (
                                                            <Box key={index} className={styles.mobileMenuLinks}>
                                                                <Button
                                                                    variant="text"
                                                                    onClick={() => handleNavigate(subSubItem)}
                                                                    size="large"
                                                                    sx={{ px: 2 }}
                                                                >
                                                                    {subSubItem.name}
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        {
                                                            subItem?.name !== "Duration" && (
                                                                <Box>
                                                                    <Button
                                                                        variant="text"
                                                                        onClick={() => {
                                                                            handleClose()
                                                                            navigate("/experiences")
                                                                        }}
                                                                        size="large"
                                                                        sx={{ fontWeight: "600", fontSize: "16px", paddingLeft: "17px" }}
                                                                    >
                                                                        View All
                                                                    </Button>
                                                                </Box>
                                                            )
                                                        }
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ) : (
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            handleClose()
                                            navigate(item.slug)
                                        }}
                                        size="large"
                                        sx={{ px: 2 }}
                                    >
                                        {item.name}
                                    </Button>
                                )}
                            </Box>
                        ))}
                    </Box>

                    <Divider />
                    <Box>
                        <HelpSlider parent="sidebar" />
                    </Box>
                </Box>

            </Box>
        </Drawer >
    )
}

export default Sidebar