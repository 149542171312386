import {
    GET_SCHOOLS,
    GET_SCHOOLS_SUCCESS,
    GET_SCHOOLS_FAILURE,
} from '../actions/schools';

const initialState = {
    schools: [],
    loading: false,
    error: null,
};

export const schools = (data = initialState, action) => {
    switch (action.type) {
        case GET_SCHOOLS:
            return {
                ...data,
                loading: true,
                error: null,
            };

        case GET_SCHOOLS_SUCCESS:
            return {
                ...data,
                loading: false,
                schools: action.payload,
            };

        case GET_SCHOOLS_FAILURE:
            return {
                ...data,
                loading: false,
                error: action.payload,
                schools: [],
            };

        default:
            return data;
    }
};
