import {
    Box,
    Drawer,
    ListItemText,
    Divider,
    Typography,
    Button,
    IconButton,
    Checkbox,
    Slider,
    Input,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from "./style.module.css"
import { useEffect } from 'react'
import { getGrades } from '../../services/actions/grades'
import { useDispatch } from 'react-redux'

const FilterDrawer = ({ open, handleClose, options, appliedFilters, setAppliedFilters, applyFilters, resetFilters }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            const hasGrades = !!options.find((item) => item.name === "Grades").options
            if (!hasGrades) dispatch(getGrades());
        }
    }, [open, dispatch, options])

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor="left"
        >
            <Box
                className={styles.filterDrawer}
                role="presentation"
            >
                <Box className={styles.filterDrawerHeader}>
                    <Typography variant="h5">Filters</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Divider />

                <Box className={styles.filterDrawerBody}>

                    {/* -----------------------Price------------------------------ */}
                    <Box className={styles.price}>
                        <Typography variant="h6" gutterBottom>Price</Typography>
                        <Box>
                            <Slider
                                valueLabelFormat={(value) => value === 51000 ? "50000+" : value}
                                value={
                                    Array.isArray(appliedFilters.price) && appliedFilters.price.length === 2
                                        ? appliedFilters.price
                                        : options.find((item) => item.name === "Price").options.length === 2 ?
                                            options.find((item) => item.name === "Price").options.length : [99, 25000]
                                }
                                step={1000}
                                min={99}
                                max={51000}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                                onChange={(e, value) =>
                                    setAppliedFilters((prevFilters) => ({
                                        ...prevFilters,
                                        price: value[1] === 51000 ? [99, 500000] : value
                                    }))
                                }
                            />
                        </Box>
                        {Array.isArray(appliedFilters.price) && appliedFilters.price.length === 2 ? (
                            <Typography variant="body1">
                                Range : {appliedFilters.price[1] > 50000 ? "50000+" : `${appliedFilters.price[0]} - ${appliedFilters.price[1]}`}
                            </Typography>
                        ) : (
                            <Typography variant="body1">
                                Range: {options.find((item) => item.name === "Price").options[0]} -{" "}
                                {options.find((item) => item.name === "Price").options[1]}
                            </Typography>
                        )}
                    </Box>
                    <Divider />

                    {/* -----------------------Theme ( Destination Types )------------------------------ */}
                    <Box className={styles.theme}>
                        <Typography variant="h6" gutterBottom>Location Type</Typography>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel>Location Type</InputLabel>
                            <Select
                                multiple
                                value={appliedFilters.destinationTypes}
                                onChange={(e) => {
                                    const selectedOptions = e.target.value;
                                    setAppliedFilters(prevFilters => ({ ...prevFilters, destinationTypes: selectedOptions }))
                                }}

                                input={<Input />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {options?.find((item) => item.name === "Destination Type")?.options?.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                        <Checkbox checked={appliedFilters?.destinationTypes?.includes(option.value)} />
                                        <ListItemText primary={option.value} />
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Box>

                    {/* -----------------------Subjects------------------------------ */}
                    <Box className={styles.subjects}>
                        <Typography variant="h6" gutterBottom>Subjects</Typography>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel>Subjects</InputLabel>
                            <Select
                                multiple
                                value={appliedFilters.subjects}
                                onChange={(e) => {
                                    const selectedOptions = e.target.value;
                                    setAppliedFilters(prevFilters => ({ ...prevFilters, subjects: selectedOptions }))
                                }}
                                input={<Input />}
                                renderValue={(selected) => {
                                    return options
                                        .find((item) => item.name === "Subjects")
                                        .options
                                        .filter((option) => selected.includes(option.value))
                                        .map((option) => option.name)
                                        .join(', ');
                                }}
                            >
                                {options.find((item) => item.name === "Subjects")?.options?.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                        <Checkbox checked={appliedFilters?.subjects?.includes(option.value)} />
                                        <ListItemText primary={option.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/* -----------------------Duration------------------------------ */}
                    <Box className={styles.duration}>
                        <Typography variant="h6" gutterBottom>Duration</Typography>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel>Duration</InputLabel>
                            <Select
                                multiple
                                value={appliedFilters.duration}
                                onChange={(e) => {
                                    const selectedOptions = e.target.value;
                                    setAppliedFilters(prevFilters => ({ ...prevFilters, duration: selectedOptions }))
                                }}
                                input={<Input />}
                                renderValue={(selected) => selected.map((item) => item === "Singleday" ? "Day trip" : "Multi day").join(', ')}
                            >
                                {options.find((item) => item.name === "Duration").options.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                        <Checkbox checked={appliedFilters?.duration?.includes(option.value)} />
                                        <ListItemText primary={option.value === "Singleday" ? "Day trip" : "Multi day"} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/* -----------------------Location------------------------------ */}
                    <Box className={styles.location}>
                        <Typography variant="h6" gutterBottom>Location</Typography>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel>Location</InputLabel>
                            <Select
                                multiple
                                value={appliedFilters.location}
                                onChange={(e) => {
                                    const selectedOptions = e.target.value;
                                    setAppliedFilters(prevFilters => ({ ...prevFilters, location: selectedOptions }))
                                }}
                                input={<Input />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {options.find((item) => item.name === "Location").options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Checkbox checked={appliedFilters?.location?.includes(option)} />
                                        <ListItemText primary={option} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/* -----------------------Grades------------------------------ */}
                    {
                        !!options.find((item) => item.name === "Grades").options ? (
                            <Box className={styles.subjects}>
                                <Typography variant="h6" gutterBottom>Grades</Typography>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel>Grades</InputLabel>
                                    <Select
                                        multiple
                                        value={appliedFilters.grades}
                                        onChange={(e) => {
                                            const selectedOptions = e.target.value;
                                            setAppliedFilters(prevFilters => ({ ...prevFilters, grades: selectedOptions }))
                                        }}
                                        input={<Input />}
                                        renderValue={(selected) => {
                                            return options
                                                .find((item) => item.name === "Grades")
                                                .options
                                                .filter((option) => selected.includes(option.value))
                                                .map((option) => option.name)
                                                .join(', ');
                                        }}
                                    >
                                        {options.find((item) => item.name === "Grades")?.options?.map((option, index) => (
                                            <MenuItem key={index} value={option.value}>
                                                <Checkbox checked={appliedFilters?.grades?.includes(option.value)} />
                                                <ListItemText primary={option.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        ) : null
                    }

                    <Divider />
                    <Box className={styles.buttons}>
                        <Button
                            variant='outlined'
                            color='error'
                            onClick={resetFilters}>
                            Clear All
                        </Button>
                        <Button
                            variant='outlined'
                            color='info'
                            onClick={applyFilters}
                            sx={{
                                color: "#1D9929",
                                borderColor: "#1D9929",
                                '&:hover': {
                                    borderColor: "#1D9929",
                                    backgroundColor: "#1D9929",
                                    color: "#fff"
                                }
                            }}
                        >
                            Apply
                        </Button>
                    </Box>

                </Box>

            </Box>
        </Drawer>
    )
}

export default FilterDrawer