import { call, put, takeEvery } from 'redux-saga/effects';
import { authApi } from '../api';
import {
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    GET_USER_BOOKINGS,
    GET_USER_BOOKINGS_SUCCESS,
    GET_USER_BOOKINGS_FAILURE,
} from "../actions/auth";

import { GET_CHILD_SUCCESS } from "../actions/child";

function* getUser() {
    try {
        const response = yield call(authApi.getUser);
        if (!response.data.success) {
            throw new Error(response.data.message);
        }
        if (response.data.data.user.role.name.toLowerCase() === "admin" || response.data.data.user.role.name.toLowerCase() === "partner") {
            localStorage.removeItem("token");
            throw new Error("You are not allowed to access this page");
        }
        yield put({ type: GET_USER_SUCCESS, payload: response.data.data });
        yield put({ type: GET_CHILD_SUCCESS, payload: response.data.data.child });
    } catch (error) {
        yield put({ type: GET_USER_FAILURE, payload: error });
    }
}

function* getUserBookings() {
    try {
        const response = yield call(authApi.bookingHistory);
        if (!response.data.success) {
            throw new Error(response.data.message);
        }
        yield put({ type: GET_USER_BOOKINGS_SUCCESS, payload: response.data.data });
    } catch (error) {
        yield put({ type: GET_USER_BOOKINGS_FAILURE, payload: error });
    }
}

export default function* authSaga() {
    yield takeEvery(GET_USER, getUser);
    yield takeEvery(GET_USER_BOOKINGS, getUserBookings);
}