import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Stepper,
    Step,
    StepLabel
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomStepIcon = ({ label, active }) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer"
        }}
    >
        <div
            style={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                backgroundColor: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "5px",
                boxShadow: "rgb(217, 217, 217) 0px 2px 10px",
            }}
        >
            <span style={{ fontSize: "12px", color: "rgb(38, 38, 38)" }}>
                {label}
            </span>
        </div>
    </div>
);

const Itinerary = ({ data }) => {
    return (
        <div>
            {
                data?.length && data?.length === 1 ? (
                    <div>
                        <Typography sx={{ fontWeight: 500, fontSize: "20px", mb: 2 }}>
                            {data[0]?.title}
                        </Typography>
                        <Box
                            sx={{
                                fontSize: "15px",
                                lineHeight: "25px",
                                "& ul": {
                                    paddingLeft: "20px",
                                    color: "#0F0F43"
                                },
                                "& ol": {
                                    paddingLeft: "20px",
                                    color: "#0F0F43"
                                }
                            }}>
                            {/* html */}
                            <div dangerouslySetInnerHTML={{ __html: data[0]?.description }} />
                        </Box>
                    </div>
                ) : (
                    data.map((day, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <Box sx={{ maxWidth: 350 }}>
                                <Stepper orientation="vertical">
                                    <Step>
                                        <StepLabel
                                            StepIconComponent={CustomStepIcon}
                                            StepIconProps={{ label: `Day ${index + 1}` }}
                                        >
                                        </StepLabel>
                                    </Step>
                                </Stepper>
                            </Box>


                            <Accordion
                                sx={{
                                    boxShadow: "none",
                                    border: "none",

                                    "&:before": {
                                        display: "none"
                                    }
                                }}
                                defaultExpanded={index === 0}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx-={{
                                        marginBottom: "10px",
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 500, fontSize: "20px" }}>
                                        {day?.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{
                                        fontSize: "15px",
                                        position: "relative",
                                        bottom: "15px",
                                        "& ul": {
                                            paddingLeft: "20px"
                                        },
                                        "& ol": {
                                            paddingLeft: "20px"
                                        }
                                    }}>
                                        {/* html */}
                                        <div dangerouslySetInnerHTML={{ __html: day?.description }} />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))
                )
            }
        </div >
    );
}

export default Itinerary