export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const getUser = () => ({
    type: GET_USER
});

export const GET_USER_BOOKINGS = 'GET_USER_BOOKINGS';
export const GET_USER_BOOKINGS_SUCCESS = 'GET_USER_BOOKINGS_SUCCESS';
export const GET_USER_BOOKINGS_FAILURE = 'GET_USER_BOOKINGS_FAILURE';

export const getUserBookings = () => ({
    type: GET_USER_BOOKINGS
})