import styles from './styles.module.css';
import { Typography, Box } from '@mui/material';
import { LazyImage } from "../"

const Card = ({ item }) => {
  return (
    <Box className={styles.card}>
      <Box className={styles.cardImg}>
        <LazyImage
          src={item?.img}
          alt={item?.name}
          height="100%"
          width="100%"
        />
      </Box>

      <Box className={styles.cardContent}>
        <Box className={styles.cardProfile}>
          <Box className={styles.profileTitle}>
            <Typography variant="h5">{item?.name}</Typography>
            <Typography variant="h6">{item?.parent}</Typography>
          </Box>
        </Box>
        <Typography variant="p">{item?.desc}</Typography>
      </Box>
    </Box>
  )
}

export default Card