import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from "./FilterSlider.module.css";
import { LazyImage } from '../../components';
import { filters } from "../../assets/homeFilters";

const FilterSlider = () => {
    return (
        <Box className={styles.wrapper}>
            {filters?.map((item) => (
                <Link
                    className={styles.filterItem}
                    key={item?.name}
                    to={`/experiences${item?.name === "Heritage" ? "?destination_type=Heritage" : `?experience_type=${item?.name}`} `}
                >
                    <LazyImage
                        src={item?.image}
                        alt={item?.name}
                        width="100%"
                        height="100%"
                    />
                </Link>
            ))}
        </Box>
    )
}

export default FilterSlider