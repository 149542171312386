import styles from "./Card.module.css";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { LazyImage } from "../../components";

const Card = ({ data }) => {
    return (
        <Link to={`/experience/${data?.id}`} className={styles.card}>
            <Box className={styles.cardImage}>
                <LazyImage
                    src={data?.cover_photo}
                    alt={data?.title}
                    width="100%"
                    height="100%"
                />
                <Box className={styles.cardHover}>
                    <p className={styles.cardHoverText}>
                        Duration | {data?.duration} <br />
                    </p>
                </Box>
            </Box>
            <Box className={styles.cardDetails}>
                <Box className={styles.cardDetailsBottom}>
                    <h3>
                        {data?.title.length > 40
                            ? data?.title.slice(0, 40) + "..."
                            : data?.title}
                    </h3>
                    <p className={styles.price}>
                        <span>From</span> <br />
                        {data?.fee}
                    </p>
                </Box>
            </Box>
        </Link>
    );
};

export default Card;
