import styles from "./Error.module.css"
import {
    Grid,
    Box,
    Container,
    Typography
} from "@mui/material"
import { useNavigate } from "react-router-dom"

const Error = () => {
    const navigate = useNavigate()
    return (
        <Container className={styles.errorContainer}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box className={styles.errorbox}>
                        <Typography variant="h3">
                            Opps! page not found
                        </Typography>
                        <Typography variant="body1" component="p">
                            The page you are looking for might have been removed had its name changed or is temporarily unavailable.
                        </Typography>
                        <Box className={styles.errorButtons}>
                            <button
                                type="button"
                                onClick={() => navigate("/", { replace: true })}
                            >
                                Go to Home
                            </button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className={styles.errorImage}>
                        <img src="/images/error.webp" alt="edoutdoors" />
                    </Box>
                </Grid>

            </Grid>
        </Container>
    )
}

export default Error