import styles from "./Experiences.module.css";
import { Box, Container, Skeleton } from "@mui/material";
import ExpCard, { ExpCardSkeleton } from "./ExpCard";
import { useEffect, useMemo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getExperiences } from "../../services/actions/experiences";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { FilterSlider, OtherFilters, FilterDrawer } from "../../components";
import { expApi } from "../../services/api";
import { useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Filter experiences based on search params
const getFilteredExperiences = async (search) => {
  const searchParams = new URLSearchParams(search);
  const queryParams = {};

  // Loop through all search params and add them to queryParams object
  searchParams.forEach((value, key) => {
    queryParams[key] ||= [];
    queryParams[key].push(value);
  });

  // Convert queryParams object to query string
  const queryString = Object.entries(queryParams)
    .map(([key, values]) => {
      return `${key}=${JSON.stringify(values)}`;
    })
    .join("&");

  try {
    const { data } = await expApi.getFilteredExperiences(`?${queryString}`);
    if (data.success) {
      return data.data;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};

const Experiences = () => {
  const dispatch = useDispatch();

  const { experiences, loading, error } = useSelector(
    (state) => state.experiences
  );
  const [sortedExp, setSortedExp] = useState([]);
  const [displayCount, setDisplayCount] = useState(12); // initial display count
  const { experienceTypes, destinationTypes } = useSelector(
    (state) => state.exp_destination_types
  );
  const { subjects } = useSelector((state) => state.subjects)
  const { grades } = useSelector((state) => state.grades)

  const [searchParams, setSearchParams] = useSearchParams()
  const [filtersLoading, setFiltersLoading] = useState(false);

  // Filters
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false); // open filter drawer
  const location = useLocation();
  const [appliedFilters, setAppliedFilters] = useState({
    duration: [],
    price: [],
    location: [],
    experienceTypes: [],
    destinationTypes: [],
    subjects: [],
    grades: [],
  });

  const FilterOptions = useMemo(() => {
    return [
      {
        name: "Experience Type",
        options: experienceTypes?.map((type) => ({
          name: type?.name,
          value: type?.name,
        })),
      },
      {
        name: "Destination Type",
        options: destinationTypes?.map((type) => ({
          name: type?.name,
          value: type?.name,
        })),
      },
      {
        name: "Subjects",
        options: subjects?.map((subject) => ({
          name: subject?.name,
          value: subject?.id,
        })),
      },
      {
        name: "Duration",
        options: [
          {
            name: "Single Day",
            value: "Singleday",
          },
          {
            name: "Multi Day",
            value: "Multiday",
          },
        ],
      },
      {
        name: "Price",
        options: [],
      },
      {
        name: "Location",
        options: ["Domestic", "International"],
      },
      {
        name: "Grades",
        options: grades?.map((grade) => ({
          name: grade?.grade,
          value: grade?.id,
        })),
      }
    ];
  }, [experienceTypes, destinationTypes, subjects, grades]);

  // Apply all filters
  const applyFilters = () => {
    // Update search params for generating a shareable link
    const updatedSearchParams = {
      event: appliedFilters.duration.length > 0 ? appliedFilters.duration : [],
      fee: appliedFilters.price.length === 2 ? appliedFilters.price : [],
      location: appliedFilters.location.length > 0 ? appliedFilters.location : [],
      experience_type: appliedFilters.experienceTypes.length > 0 ? appliedFilters.experienceTypes : [],
      destination_type: appliedFilters.destinationTypes.length > 0 ? appliedFilters.destinationTypes : [],
      subjects: appliedFilters.subjects.length > 0 ? appliedFilters.subjects : [],
      grades : appliedFilters?.grades?.length > 0 ? appliedFilters?.grades : [],
    };
    const formattedSearchParams = {};
    for (const [key, value] of Object.entries(updatedSearchParams)) {
      formattedSearchParams[key] = value;
    }
    setSearchParams(formattedSearchParams);
    setOpenFilterDrawer(false);
  };

  const resetFilters = () => {
    const emptySearchParams = new URLSearchParams();
    setSearchParams(emptySearchParams);

    setAppliedFilters({
      duration: [],
      price: [],
      location: [],
      experienceTypes: [],
      destinationTypes: [],
      subjects: [],
      grades: [],
    });
  };

  // Effect for fetching experiences based on search params
  useEffect(() => {
    (async () => {
      if (location.search) {
        setFiltersLoading(true)
        const data = await getFilteredExperiences(location.search);
        setSortedExp(data);
        setFiltersLoading(false);
      } else {
        dispatch(getExperiences());
        resetFilters();
      }
    })();
    // eslint-disable-next-line 
  }, [dispatch, location.search]);

  // For top filters
  useEffect(() => {
    if (appliedFilters.experienceTypes.length > 0) {
      applyFilters();
    }
    // eslint-disable-next-line 
  }, [appliedFilters.experienceTypes.length]);

  // for creating a another copy of experiences for sorting and filtering
  useEffect(() => {
    if (experiences) {
      setSortedExp([...experiences]?.filter((item) => item?.status));
    }
  }, [experiences]);

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>{error?.message}</h1>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Field Trips, Camps, Treks & Industrial Visits for Students | EdOutdoors</title>
        <meta
          name="description"
          content="Explore our unique options for your perfect school trip, that are mapped to your curriculum. Discover customised camps and outdoor activities for your students."
        />
        <meta property="og:title" content="Field Trips, Camps, Treks & Industrial Visits for Students | EdOutdoors" />
        <meta
          property="og:description"
          content="Explore our unique options for your perfect school trip, that are mapped to your curriculum. Discover customised camps and outdoor activities for your students."
        />
        <link rel="canonical" href="https://www.edoutdoors.in/experiences" />
      </Helmet>

      <Box className={styles.listings}>
        <Container>
          <Box className={styles.listingsHeader}>
            <h1>Find an experience your child will love</h1>
          </Box>

          {
            filtersLoading || loading ? (
              <Box className={styles.filterLoading}>
                <Box className={styles.boxWrapper}>
                  {
                    window.innerWidth > 768 ? (
                      Array(6).fill().map((_, index) => (
                        <Box className={styles.box1} key={index}>
                          <Skeleton variant="rectangular" width="100%" height="100%" />
                        </Box>
                      ))
                    ) : (
                      Array(3).fill().map((_, index) => (
                        <Box className={styles.box1} key={index}>
                          <Skeleton variant="rectangular" width="100%" height="100%" />
                        </Box>
                      ))
                    )
                  }
                </Box>
                <Box className={styles.boxWrapper2}>
                  {
                    Array(2).fill().map((_, index) => (
                      <Box className={styles.box2} key={index}>
                        <Skeleton variant="rounded" width="100%" height="100%" />
                      </Box>
                    ))
                  }
                </Box>
              </Box>
            ) : (
              <Box className={styles.filters}>
                <Box className={styles.filterSlider}>
                  {/* Top Filites - Experience Types */}
                  <FilterSlider
                    options={
                      FilterOptions?.find((item) => item?.name === "Experience Type")
                        .options
                    }
                    initialIndex={FilterOptions?.find((item) => item?.name === "Experience Type")
                      .options?.findIndex((item) => item?.name === searchParams.get('experience_type')) || 0}
                    setAppliedFilters={setAppliedFilters}
                    appliedFilters={appliedFilters}
                    resetFilters={resetFilters}
                  />
                </Box>
                <Box className={styles.otherFilters}>
                  {/* Filter Buttons */}
                  <OtherFilters
                    handleOpenFilterDrawer={() => {
                      setOpenFilterDrawer(true);
                    }}
                    handleCloseFilterDrawer={() => {
                      setOpenFilterDrawer(false);
                    }}
                    resetFilters={resetFilters}
                    appliedFilters={appliedFilters}
                  />
                </Box>
                {/* All Filters */}
                <FilterDrawer
                  open={openFilterDrawer}
                  handleClose={() => {
                    setOpenFilterDrawer(false);
                  }}
                  options={FilterOptions}
                  appliedFilters={appliedFilters}
                  setAppliedFilters={setAppliedFilters}
                  applyFilters={applyFilters}
                  resetFilters={resetFilters}
                />
              </Box>
            )
          }

          {loading || filtersLoading ? (
            <Box className={styles.listingWrapperSkeleton}>
              {Array(6).fill().map((_, index) => {
                return <ExpCardSkeleton key={index} />;
              })}
            </Box>
          ) : (
            <>
              <Box className={styles.listingWrapper}>
                {sortedExp.length > 0 ? (
                  sortedExp.slice(0, displayCount).map((item) => {
                    return <ExpCard item={item} key={item.id} />;
                  })
                ) : (
                  <h1>No experiences found</h1>
                )}
              </Box>

              <Box className={styles.showMoreBtn} sx={{ textAlign: "center" }}>
                {sortedExp.length > displayCount ? (
                  <Box
                    className={styles.showMoreBtn}
                    sx={{ textAlign: "center" }}
                  >
                    <button
                      onClick={() => setDisplayCount(displayCount + 12)}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={30} /> : "Show More"}
                    </button>
                  </Box>
                ) : null}
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Experiences;
