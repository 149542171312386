import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Card from './Card';
import { Typography, Box, IconButton } from '@mui/material';
import styles from './styles.module.css';
import { useState } from 'react';
import { testimonials } from '../../assets';


const Testimonials = () => {
    const [swiperRef, setSwiperRef] = useState(null);
    const [isEnd, setIsEnd] = useState(false);
    const [isBeginning, setIsBeginning] = useState(true);

    const prevHandler = () => {
        swiperRef.slidePrev();
        setIsEnd(false);
        if (swiperRef.isBeginning) {
            setIsBeginning(true);
        }
    };

    const nextHandler = () => {
        swiperRef.slideNext();
        setIsBeginning(false);
        if (swiperRef.isEnd) {
            setIsEnd(true);
        }
    };

    return (
        testimonials?.length > 0 && (
            <>
                {
                    window.innerWidth < 1000 && (
                        <Box className={styles.heading}>
                            <Typography variant="h5" gutterBottom>
                                Groundbreaking learning in the outdoors!
                            </Typography>
                        </Box>
                    )
                }
                <Box className={styles.testimonialsContainer}>
                    <Box className={styles.titleNavigation}>
                        <Box className={styles.heading}>
                            <Typography variant="h5" gutterBottom>
                                Groundbreaking learning in the outdoors!
                            </Typography>
                        </Box>
                        <Box className={styles.navigation}>
                            <IconButton
                                onClick={prevHandler}
                                className={styles.prevArrow}
                                disabled={isBeginning}
                                sx={{ opacity: isBeginning ? 0.4 : 1 }}
                            >
                                <img src="/images/backIcon.png" alt="Previous" />
                            </IconButton>
                            <IconButton
                                onClick={nextHandler}
                                className={styles.nextArrow}
                                disabled={isEnd}
                                sx={{ opacity: isEnd ? 0.4 : 1 }}
                            >
                                <img src="/images/nextIcon2.png" alt="Next" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={0}
                        slidesPerView={window.innerWidth > 1000 ? 2 : window.innerWidth < 700 ? 1 : 2}
                        style={{ padding: '5px 25px 5px 0' }}
                        onSwiper={(swiper) => setSwiperRef(swiper)}
                    >
                        {

                            testimonials?.map((item) => (
                                <SwiperSlide key={item.id}>
                                    <Card item={item} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </Box>
            </>
        )
    )
}

export default Testimonials