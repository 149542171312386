import { Container, Box, Grid, Typography } from '@mui/material'
import { LazyImage } from '../../components'
import styles from './Customers.module.css'
import { Link } from 'react-router-dom'

const Customers = () => {

    return (
        <Container>
            <Grid container className={styles.wrapper}>
                <Grid item md={3} className={styles.left}>
                    <Box className={styles.images1} style={{ marginLeft: 0 }}>
                        <LazyImage src='/images/home/1.webp' />
                        <LazyImage src='/images/home/2.webp' />
                    </Box>

                    <Box className={styles.images2}>
                        <LazyImage src='/images/home/3.webp' />
                        <LazyImage src='/images/home/4.webp' />
                        <LazyImage src='/images/home/5.webp' />
                    </Box>
                </Grid>

                <Grid item md={6} className={styles.mid}>
                    <Box className={styles.box}>
                        <Typography variant='h6'>
                            ONE-STOP PLACE
                        </Typography>
                        {
                            window.innerWidth > 500 ? (
                                <Typography variant='h1'>
                                    To Discover Hands-on Learning <br /> With School Trips, Camps & <br /> Activities for Kids
                                </Typography>
                            ) : (
                                <Typography variant='h1'>
                                    To Discover Hands-on Learning With School Trips, Camps & Activities for Kids
                                </Typography>
                            )
                        }
                        <Box className={styles.btns}>
                            <Link to="/experiences">View All</Link>
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={3} className={styles.right}>
                    <Box className={styles.images1}>
                        <LazyImage src='/images/home/6.webp' />
                        <LazyImage src='/images/home/7.webp' />
                        <LazyImage src='/images/home/8.webp' />
                    </Box>

                    <Box className={styles.images2} style={{ marginRight: 0 }}>
                        <LazyImage src='/images/home/9.webp' />
                        <LazyImage src='/images/home/10.webp' />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Customers